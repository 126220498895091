import { View, Text, StyleSheet } from "@react-pdf/renderer";
import { styles } from "./styles";
import { TableReport } from "./tableReport";

import { Fragment } from "react";
import FlowReport from "./flowReport";
const headerTableBoolean = [
	"Nome do Host",
	"Taxa de Disponibilidade",
	"Tempo de Disponibilidade",
	"Taxa de Indisponibilidade",
	"Tempo de Indisponibilidade",
];
const headerTableTraffic = [
	"Nome do Item",
	"Tráfego Total",
	"Tráfego Médio",
	"95th Percentil",
	"Valor",
];

const stylesFirstPage = StyleSheet.create({
	viewHosts: {
		display: "flex",
		flexDirection: "row",
		gap: 1,
		flexWrap: "wrap",
	},
	textPage: {
		fontSize: 12,
		textAlign: "justify",
		fontFamily: "Times-Italic",
		display: "flex",
		flexWrap: "wrap",
		lineHeight: 1.5,
	},
});
export const FirstPage = ({
	itens,
	tipo,
	conteudoHost,
	dataFinal,
	dataInicial,
	horaInicial,
	horaFinal,
	client,
	tarifacaoExcedente,
	analysis,
	relatorio,
	reportsProps,
	graphcs,
}) => {
	const headerTableFlow = [
		"Tráfego Total",
		"Tráfego Médio",
		`${relatorio?.percentil} Percentil`,
		"Valor",
	];
	const headerTableFlowExcedente = [
		"Tráfego Total",
		"Tráfego Médio",
		`${relatorio?.percentil}th Percentil`,
		"Valor",
	];
	// Flow Report
	let cont = 1;

	// FirstPage

	const sufficientDataFlow = (analysis) => {
		[analysis]?.flat(Infinity);
		return analysis?.every(
			(analysi) =>
				(!!analysi?.th95 || analysi?.th95 == 0) &&
				(!!analysi?.avgValue || analysi?.avgValue == 0) &&
				(!!analysi?.trafficFull || analysi?.trafficFull == 0)
		);
	};

	let indice = 1;
	return (
		<View>
			<View style={{ marginBottom: 5 }}>
				<Text style={styles.title}>{"PERÍODO DO RELATÓRIO"}</Text>
				<Text style={styles.text}>
					{"Data Inicial: " + dataInicial + " " + horaInicial}
				</Text>
				<Text style={styles.text}>
					{"Data Final: " + dataFinal + " " + horaFinal}
				</Text>
			</View>

			<View key={"first-page-itens-table" + indice}>
				{sufficientDataFlow(analysis) ? (
					<>
						<TableReport
							title={client}
							index={indice}
							hosts={analysis}
							headerTitles={
								tarifacaoExcedente?.status
									? headerTableFlowExcedente
									: headerTableFlow
							}
							tarifacaoExcedente={tarifacaoExcedente}
							indiceTable={indice}
							tipo={tipo}
						/>
						{(indice += 1)}
					</>
				) : null}
				<Fragment>
					<FlowReport
						percentil={relatorio?.percentil}
						client={relatorio?.client}
						interfaces={relatorio?.interfaces}
						tarifacaoExcedente={relatorio?.tarifacaoExcedente}
						analysis={relatorio?.analysis}
						index={0}
						{...reportsProps}
						graphcs={graphcs}
						indiceGraphcs={[cont, cont + 1]}
					/>
				</Fragment>
			</View>
			{/* )
        )} */}
		</View>
	);
};
