import { StyleSheet } from "@react-pdf/renderer";
export const styles = StyleSheet.create({
	tableContainer: {
		fontSize: 10,
		borderTop: 3,
		borderColor: "#212B36",
		display: "table",
		// width: "auto",
		fontFamily: "Times-Bold",
		flexDirection: "row",
	},
	row: {
		fontFamily: "Times-Roman",
		fontSize: 10,
		display: "flex",
		flexDirection: "row",
	},
	tableCell: {
		width: "50%",
		borderWidth: 0.5,
		paddingVertical: 5,
		paddingHorizontal: 2,
		borderColor: "#d4d4dc",
		//backgroundColor: "#F7F7F8",
	},
	tableContainerOrigin: {
		fontSize: 10,
		borderTop: 3,
		borderColor: "#212B36",
		display: "table",
		// width: "auto",
		fontFamily: "Times-Bold",
		flexDirection: "row",
	},
	rowOrigin: {
		fontFamily: "Times-Roman",
		fontSize: 10,
		display: "flex",
		flexDirection: "row",
	},
	tableCellOrigin: {
		//width: "10%",
		borderWidth: 0.5,
		paddingVertical: 2,
		paddingHorizontal: 1,
		borderColor: "#d4d4dc",
	},
});
