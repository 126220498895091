import { useTheme } from "@mui/system";
import useStyles from "./styles";
import "dayjs/locale/pt-br";
import { Box, Tabs, Tab } from "@mui/material";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
dayjs.extend(utc);
dayjs.extend(timezone); //

export default function RelatoriosCliente() {
  // Estilização
  const theme = useTheme();
  const styles = useStyles(theme);
  const navigate = useNavigate();
  //Change tabs handler
  const [value, setValue] = useState(0);

  useEffect(() => {
    // Checa altorização do usuário
    if (handleUsuarioId() === false) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    // Trata duplicação do pathName de rotas
    if (window.location.pathname.includes("mirror")) {
      window.history.replaceState(
        null,
        null,
        `${window.location.pathname.replace(/.*(?=\/mirror)/, "")}`
      );
    }

    // Define tab atual de acordo com a url
    if (window.location.pathname.includes("percentil")) {
      setValue(0);
    } else if (window.location.pathname.includes("blocos")) {
      setValue(2);
    } else {
      setValue(1);
    }
  }, [window.location.pathname]);

  const handleUsuarioId = () => {
    let result = JSON.parse(localStorage.getItem("userCliente"));
    // Verifica se a propriedade rlPermissoesUsuario existe e é um array
    if (Array.isArray(result?.usuarioCliente?.cliente?.percentil)) {
      // Itera sobre o array para verificar se algum objeto contém a propriedade codigoPermissao com valor "ADM"

      if (
        result?.usuarioCliente?.cliente?.percentil?.[0]?.permissao === true &&
        (result?.usuarioCliente?.RlPermissoesUsuarioCliente?.[0]?.permissao?.codigoPermissao?.toUpperCase() ===
          "ADM" ||
          result?.usuarioCliente?.RlPermissoesUsuarioCliente?.[0]?.permissao?.codigoPermissao?.toUpperCase() ===
            "FINANCEIRO")
      ) {
        return true;
      }

      return false;
    }
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.containerTabs}>
        <Tabs value={value} aria-label="basics tabs config">
          <Tab
            label="PERCENTIL"
            onClick={() => {
              navigate(`/relatorios/percentil`);
              setValue(0);
            }}
          />
          <Tab
            label="RELATÓRIO"
            onClick={() => {
              navigate(`/relatorios`);
              setValue(1);
            }}
          />
        </Tabs>

        <Outlet setSnackbar />
      </Box>
    </Box>
  );
}
