import {
  Alert,
  Autocomplete,
  Avatar,
  Box,
  Button,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
  createSvgIcon,
  useTheme,
} from "@mui/material";
import { useEffect, useId, useState } from "react";
import Resizer from "react-image-file-resizer";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { Stack } from "@mui/system";
import {
  ArticleOutlined,
  AttachMoneyOutlined,
  EditOutlined,
  PersonOutlineOutlined,
  Settings,
  WarningAmber,
} from "@mui/icons-material";
import { ReactComponent as Junniper } from "../../../assets/icons/junniper.svg";
import { ReactComponent as EasyFlow } from "../../../assets/icons/IconEasyFlow.svg";
import { ReactComponent as Wanguard } from "../../../assets/icons/wanguar.svg";
import useStyles from "./styles";
import api from "../../../services/api";

const JunniperIcon = createSvgIcon(<Junniper />);
const EasyFlowIcon = createSvgIcon(<EasyFlow />);
const WanguardIcon = createSvgIcon(<Wanguard />);

export default function Perfil() {
  const theme = useTheme();
  const styles = useStyles(theme);
  const [value, setValue] = useState(0);
  const [snackbar, setSnackbar] = useState(null);
  const id = useParams();
  const [foto, setFoto] = useState(null);
  const [readFoto, setReadFoto] = useState(null);
  const [alertSize, setAlertSize] = useState(false);
  const [clienteInfo, setClienteInfo] = useState({});
  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  //fetch cliente info
  useEffect(() => {
    // Seleciona a tab lateral de acordo com a página atual
    const pageName = window.location.pathname;
    const pages = [
      "/interno/home/perfil/financeiro",
      "/interno/home/perfil/juniper",
      "/interno/home/perfil/wanguard",
      "/interno/home/perfil/easyflow",
      "/interno/home/perfil/relatorios",
    ];
    let breakFlag = false;
    for (let index = 0; index < pages.length; index++) {
      if (pageName.includes(pages[index])) {
        setValue(index + 1);
        breakFlag = true;
        break;
      }
    }
    if (breakFlag == false) {
      setValue(0);
    }

    //Funções a serem executadas ao carregar a página
    apiGetClientes();
    handleApiGetClienteInfo();
  }, []);

  // Get Cliente Info
  async function handleApiGetClienteInfo() {
    try {
      setLoading(true);
      const response = await api.post("/interno/findUnique/clientId", {
        clienteId: selectedCliente?.id || id?.id,
      });

      setClienteInfo(response?.data);

      setFoto(response?.data?.ClientesCadastro?.foto);
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possivel se conectar ao servidor",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  }

  // Ao ter uma novo foto lida chama api para update
  useEffect(() => {
    if (readFoto) {
      handleUpdateFoto();
    }
  }, [readFoto]);

  // Atualiza foto do cliente
  async function handleUpdateFoto() {
    try {
      setLoading(true);
      const response = await api.post("/interno/update/clienteFoto", {
        clienteId: id?.id,
        foto: readFoto,
      });

      // On success
      setFoto(response?.data?.foto);

      setSnackbar({
        children: "Imagem alterada com sucesso",
        severity: "success",
      });
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possivel se conectar ao servidor",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  }

  // Uso de api para receber a lista de clientes
  async function apiGetClientes() {
    try {
      const response = await api.get("/cliente/home/getClient");
      setClientes(response.data);
    } catch (error) {
      console.error(error);
    }
  }

  // Filtragem de clientes a serem listados
  const clientesUnicos = new Map();
  clientes.forEach((cliente) => {
    if (!clientesUnicos.has(cliente.as)) {
      clientesUnicos.set(cliente.as, cliente);
    }
  });
  const clientesFiltrado = Array.from(clientesUnicos.values());

  // Mudança do perfil exibido
  useEffect(() => {
    if (selectedCliente) {
      window.history.replaceState(
        null,
        null,
        `/interno/home/perfil/${selectedCliente?.id}`
      );

      handleApiGetClienteInfo();
    }
  }, [selectedCliente]);

  return (
    <>
      <Box sx={styles.pageBox}>
        <Box sx={styles.container}>
          <Stack direction={"column"} gap={"20px"}>
            <Box sx={styles.headerRow}>
              <Box sx={styles.filterGroupItem}>
                <Autocomplete
                  fullWidth
                  value={clienteInfo}
                  label={"Cliente"}
                  onChange={(event, newValue) => {
                    setSelectedCliente(newValue);
                  }}
                  size="small"
                  options={clientesFiltrado}
                  getOptionLabel={(option) => option?.nomeFantasia || ""}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      {...params}
                      label={"Cliente"}
                      placeholder={clienteInfo?.nomeFantasia}
                    />
                  )}
                />
              </Box>
            </Box>
            <Paper
              variant={theme.palette.mode === "dark" ? "outlined" : "elevation"}
              elevation={3}
              sx={styles.paper}
            >
              <Stack direction="row" gap={"16px"}>
                <Avatar src={foto || ""} sx={styles.avatar} />
                <Tooltip title={"alterar imagem"}>
                  <Button
                    startIcon={<EditOutlined />}
                    component="label"
                    disabled={loading}
                  >
                    Mudar a Logo
                    <input
                      hidden
                      accept=".png, .jpg, .jpeg"
                      type="file"
                      onChange={(e) => {
                        const file = e?.target?.files[0];
                        if (file?.size < 1024 * 1000) {
                          // Converte para base64
                          Resizer.imageFileResizer(
                            file,
                            500,
                            500,
                            "JPEG",
                            80,
                            0,
                            (uri) => {
                              setReadFoto(uri);
                            },
                            "base64",
                            500,
                            500
                          );
                          const fileReader = new FileReader();
                          fileReader.readAsDataURL(file);
                          fileReader.onload = () => {};
                          fileReader.onerror = (error) => {
                            console.log("Error:", error);
                          };
                        } else {
                          setAlertSize(true);
                        }
                      }}
                    />
                  </Button>
                </Tooltip>
              </Stack>
              <Stack direction="column" gap={"4px"}>
                <Typography sx={{ fontWeight: 600, fontSize: "1rem" }}>
                  {clienteInfo?.nomeFantasia || ""}
                </Typography>
                <Typography
                  sx={{ fontSize: "0.875rem", opacity: 0.6, fontWeight: 500 }}
                >
                  AS: {clienteInfo?.as || ""}
                </Typography>
              </Stack>
              <Divider />
              {/* window.location.pathname === "/interno/dash" */}
              <List sx={{ width: "100%", maxWidth: 360 }} component="nav">
                <ListItemButton
                  onClick={() => {
                    navigate(`/interno/home/perfil/${clienteInfo?.id}`);

                    setValue(0);
                  }}
                  selected={value === 0}
                  sx={value === 0 ? styles.selectedList : {}}
                >
                  <ListItemIcon>
                    <PersonOutlineOutlined
                      sx={{
                        ...styles.Icon,
                        color: value === 0 ? theme.palette.secondary.main : "",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Perfil" />
                </ListItemButton>
                <ListItemButton
                  onClick={() => {
                    navigate(
                      `/interno/home/perfil/financeiro/${clienteInfo?.id}`
                    );
                    setValue(1);
                  }}
                  sx={value === 1 ? styles.selectedList : {}}
                >
                  <ListItemIcon>
                    <AttachMoneyOutlined
                      sx={{
                        color: value === 1 ? theme.palette.secondary.main : "",
                      }}
                    />
                  </ListItemIcon>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <ListItemText primary="Financeiro" />
                    {clienteInfo?.contratos?.length > 1 && (
                      <Tooltip
                        title={
                          <Box sx={styles.filterMenuTooltip}>
                            O cliente possui mais de um contrato ativo.
                          </Box>
                        }
                      >
                        <WarningAmber
                          fontSize="small"
                          sx={{
                            ...styles.infoContratos,
                            color: theme.palette.secondary.main,
                          }}
                        />
                      </Tooltip>
                    )}
                  </Box>
                </ListItemButton>
                <Divider variant="fullWidth" component="li" />
                <Typography
                  sx={{ fontWeight: 600, opacity: 0.7, mt: 2, ml: 1 }}
                >
                  Configurações
                </Typography>
                <ListItemButton
                  onClick={() => {
                    navigate(`/interno/home/perfil/juniper/${clienteInfo?.id}`);
                    setValue(2);
                  }}
                  sx={value === 2 ? styles.selectedList : {}}
                >
                  <ListItemIcon>
                    <Box position={"relative"}>
                      <JunniperIcon
                        sx={{
                          ...styles.Icon,
                          color:
                            value === 2 ? theme.palette.secondary.main : "",
                        }}
                      />
                      <Settings
                        sx={{
                          ...styles.configBadge,
                          color:
                            value === 2 ? theme.palette.secondary.main : "",
                        }}
                      />
                    </Box>
                  </ListItemIcon>
                  <ListItemText primary="Configurações" />
                </ListItemButton>
                <ListItemButton
                  onClick={() => {
                    navigate(
                      `/interno/home/perfil/wanguard/${clienteInfo?.id}`
                    );
                    setValue(3);
                  }}
                  sx={value === 3 ? styles.selectedList : {}}
                >
                  <ListItemIcon>
                    <WanguardIcon
                      sx={{
                        ...styles.listIconWanguard,
                        color: value === 3 ? theme.palette.secondary.main : "",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Wanguard" />
                </ListItemButton>
                <ListItemButton
                  onClick={() => {
                    navigate(
                      `/interno/home/perfil/easyflow/${clienteInfo?.id}`
                    );
                    setValue(4);
                  }}
                  sx={value === 4 ? styles.selectedList : {}}
                >
                  <ListItemIcon
                    sx={{
                      color: value === 4 ? theme.palette.secondary.main : "",
                    }}
                  >
                    <Box position={"relative"}>
                      <EasyFlowIcon />
                    </Box>
                  </ListItemIcon>
                  <ListItemText primary="EasyFlow" />
                </ListItemButton>
                <ListItemButton
                  onClick={() => {
                    navigate(
                      `/interno/home/perfil/relatorios/${clienteInfo?.id}`
                    );
                    setValue(5);
                  }}
                  sx={value === 5 ? styles.selectedList : {}}
                >
                  <ListItemIcon
                    sx={{
                      color: value === 5 ? theme.palette.secondary.main : "",
                    }}
                  >
                    <Box position={"relative"}>
                      <ArticleOutlined />
                    </Box>
                  </ListItemIcon>
                  <ListItemText primary="Relatórios" />
                </ListItemButton>
              </List>
            </Paper>
          </Stack>
          <Outlet context={[setSnackbar, clienteInfo, setClienteInfo]} />
        </Box>
      </Box>
      <Snackbar
        autoHideDuration={3000}
        open={alertSize}
        onClose={() => {
          setAlertSize(false);
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity="error">Tamanho máximo permitido: 1MB</Alert>
      </Snackbar>
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2500}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </>
  );
}
