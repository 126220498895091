import {
  Chip,
  Divider,
  IconButton,
  InputAdornment,
  Pagination,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import { Box, minWidth, Stack, useTheme } from "@mui/system";
import useStyles from "./styles";
import { Clear, Launch, Search } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import api from "../../../../services/api";
import dayjs from "dayjs";
function usePagination(data, itemsPerPage) {
  const [currentPage, setCurrentPage] = useState(1);
  const maxPage = Math.ceil(data.length / itemsPerPage);

  function currentData() {
    const begin = (currentPage - 1) * itemsPerPage;
    const end = begin + itemsPerPage;
    return data.slice(begin, end);
  }

  function next() {
    setCurrentPage((currentPage) => Math.min(currentPage + 1, maxPage));
  }

  function prev() {
    setCurrentPage((currentPage) => Math.max(currentPage - 1, 1));
  }

  function jump(page) {
    const pageNumber = Math.max(1, page);
    setCurrentPage((currentPage) => Math.min(pageNumber, maxPage));
  }

  return { next, prev, jump, currentData, currentPage, maxPage };
}

export default function Contratos() {
  const navigate = useNavigate();
  const theme = useTheme();
  const styles = useStyles(theme);
  const [buscarContrato, setBuscarContrato] = useState("");
  const [contratos, setContratos] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [windowWith, setWindowWith] = useState(window.innerWidth);
  const filtroStatusVencimento = {
    1: "Ativo",
    2: "Renovado",
    3: "Próximo de vencer",
  };

  const filteredRows = contratos?.filter((row, index) => {
    row.numero = index;

    return (
      row.contract_number
        ?.toString()
        ?.includes(buscarContrato?.toLowerCase()) ||
      row.client_name?.toLowerCase()?.includes(buscarContrato?.toLowerCase()) ||
      row.contract_observations
        ?.toLowerCase()
        ?.includes(buscarContrato?.toLowerCase()) ||
      dayjs(row.contract_date_activation)
        .format("DD/MM/YYYY")
        ?.includes(buscarContrato?.toLowerCase()) ||
      filtroStatusVencimento[row.statusVencimento]
        ?.toLowerCase()
        ?.includes(buscarContrato?.toLowerCase()) ||
      (row.existClient ? "Cadastrado" : "Não cadastrado")
        ?.toLowerCase()
        ?.toString()
        ?.includes(buscarContrato?.toLowerCase())
    );
  });

  const colorChip = {
    1: "chip.completed",
    2: "chip.waitingReview",
    3: "chip.inProgress",
    4: "chip.pending",
  };
  const labelChip = {
    1: "Ativo",
    2: "Renovado",
    3: "Próximo de vencer",
    4: "Vencido",
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWith(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    getAllContratos();
  }, []);

  const PER_PAGE = 10;
  const count = Math.ceil(filteredRows?.length / PER_PAGE);
  const _DATA = usePagination(filteredRows, PER_PAGE);
  const handleChangePage = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  const getAllContratos = async () => {
    setLoading(true);
    try {
      const response = await api.get("/allContratos/brbyte");

      setContratos(response?.data);
    } catch (erro) {
      console.error(erro);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      field: "contract_number",
      headerName: "Nº contrato",
      flex: 0.6,
      minWidth: 100,
    },
    {
      field: "client_name",
      headerName: "Cliente",
      flex: 1,
    },
    {
      field: "contract_observations",
      headerName: "Plano",

      flex: 1,
    },
    {
      field: "contract_amount",
      headerName: "Valor contrato",
      minWidth: 120,
      flex: 0.7,
      renderCell: (params) => {
        return `R$ ${
          parseFloat(params?.row?.contract_amount)?.toFixed(2) || ""
        } `;
      },
      valueGetter: (params) => {
        return parseFloat(params);
      },
    },
    {
      field: "contract_date_activation",
      headerName: "Data ativação",
      flex: 0.8,
      type: "dataTime",
      renderCell: (params) => {
        return dayjs(params?.row?.contract_date_activation)?.format(
          "DD/MM/YYYY"
        );
      },
    },
    {
      field: "dataVencimento",
      headerName: "Data vencimento",
      minWidth: 140,
      flex: 0.8,
      type: "dataTime",
      renderCell: (params) => {
        return dayjs(params?.row?.dataVencimento)?.format("DD/MM/YYYY");
      },
    },
    {
      field: "statusVencimento",
      headerName: "Status contrato",
      flex: 0.9,
      minWidth: 160,
      renderCell: (params) => {
        return (
          <>
            <Chip
              sx={{
                bgcolor: colorChip[params?.row?.statusVencimento],
                color: "#ffffff",
                width: "200px",
                fontWeight: "bold",
              }}
              label={labelChip[params?.row?.statusVencimento]}
              size="small"
            />
          </>
        );
      },
      valueGetter: (params) => {
        return params;
      },
    },
    {
      field: "existClient",
      headerName: "Status cliente",
      flex: 0.8,
      renderCell: (params) => {
        return (
          <>
            <Chip
              label={params?.row?.existClient ? "Cadastrado" : "Não cadastrado"}
              sx={{
                bgcolor: params?.row?.existClient
                  ? "chip.completed"
                  : "chip.pending",
                color: "#ffffff",
                width: "200px",
                fontWeight: "bold",
              }}
              size="small"
            />
          </>
        );
      },
      valueGetter: (params) => {
        return params ? "Cadastrado" : "Não cadastrado";
      },
    },
    {
      field: "link",
      headerName: "",
      flex: 0.4,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              disabled={!params?.row?.idClient ? true : false}
              onClick={() =>
                navigate(
                  `/interno/home/perfil/financeiro/${params?.row?.idClient}`
                )
              }
            >
              <Launch />
            </IconButton>
          </>
        );
      },
    },
  ];
  return (
    <Box sx={styles.container}>
      <Paper
        sx={styles.paper}
        variant={theme.palette.mode === "dark" ? "outlined" : "elevation"}
        elevation={3}
      >
        {" "}
        <Stack sx={styles.BoxStack}>
          <Box sx={styles.containerBuscar}>
            <TextField
              variant="filled"
              label="Buscar contrato"
              value={buscarContrato}
              autoComplete="off"
              size="small"
              onChange={(event) => {
                setBuscarContrato(event.target?.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
                endAdornment: buscarContrato ? (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setBuscarContrato("")}
                      edge="end"
                    >
                      <Clear />
                    </IconButton>
                  </InputAdornment>
                ) : null,
              }}
            />
          </Box>
        </Stack>
        {windowWith > 1200 ? (
          <DataGrid
            rows={filteredRows}
            columns={columns}
            rowsPerPageOptions={[25, 50, 100]}
            autoHeight={true}
            pageSizeOptions={[25, 50, 100]}
            initialState={{
              pagination: {
                paginationModel: { page: 1, pageSize: 25 },
              },
              sorting: {
                sortModel: [{ field: "statusVencimento", sort: "desc" }],
              },
            }}
            disableRowSelectionOnClick
            sx={styles.dataGrid}
            disableColumnMenu
            loading={loading}
          />
        ) : (
          <>
            {_DATA?.currentData()?.map((row, index) => (
              <Paper key={index} variant="outlined" sx={styles.paperMobile}>
                <Box sx={styles.containerHeader}>
                  <Box sx={styles.containerNumberContrato}>
                    <Typography variant="mySubtitle" sx={{ fontSize: "16px" }}>
                      Contrato: {row?.contract_number}
                    </Typography>
                    <Box sx={styles.containerChipStatusContrato}>
                      <Chip
                        sx={{
                          bgcolor: colorChip[row?.statusVencimento],
                          color: "#ffffff",
                          width: "130px",
                          fontWeight: "bold",
                        }}
                        size="small"
                        label={labelChip[row?.statusVencimento]}
                      />
                      <IconButton
                        size="small"
                        disabled={!row?.idClient ? true : false}
                        onClick={() =>
                          navigate(
                            `/interno/home/perfil/financeiro/${row?.idClient}`
                          )
                        }
                      >
                        <Launch />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
                <Divider />
                <Box sx={styles.containerDadosContratoMobile}>
                  <Typography sx={styles.titleMobile}>
                    <Typography
                      variant="mySubtitle"
                      sx={{
                        fontSize: "13px",
                      }}
                    >
                      {" "}
                      Cliente:{" "}
                    </Typography>
                    {row?.client_name}
                  </Typography>
                  <Typography sx={styles.titleMobile}>
                    <Typography
                      variant="mySubtitle"
                      sx={{
                        fontSize: "13px",
                      }}
                    >
                      {" "}
                      Plano:{" "}
                    </Typography>
                    {row?.contract_observations}
                  </Typography>
                  <Typography sx={styles.titleMobile}>
                    <Typography
                      variant="mySubtitle"
                      sx={{
                        fontSize: "13px",
                      }}
                    >
                      {" "}
                      Valor contrato:{" "}
                    </Typography>
                    R${parseFloat(row?.contract_amount).toFixed(2)}
                  </Typography>
                  <Typography sx={styles.titleMobile}>
                    <Typography
                      variant="mySubtitle"
                      sx={{
                        fontSize: "13px",
                      }}
                    >
                      {" "}
                      Ativação - Vencimento:{" "}
                    </Typography>
                    {dayjs(row?.contract_date_activation)?.format(
                      "DD/MM/YY"
                    )} - {dayjs(row?.dataVencimento)?.format("DD/MM/YY")}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="mySubtitle"
                      sx={{
                        fontSize: "13px",
                      }}
                    >
                      {" "}
                      Status cliente:{" "}
                    </Typography>
                    <Chip
                      size="small"
                      label={row?.existClient ? "Cadastrado" : "Não cadastrado"}
                      sx={{
                        bgcolor: row?.existClient
                          ? "chip.completed"
                          : "chip.pending",
                        color: "#ffffff",
                        width: "130px",
                        marginRight: "2px",
                        fontWeight: "bold",
                      }}
                    />
                  </Box>
                </Box>
              </Paper>
            ))}
            <Box sx={{ display: "flex", marginTop: "10px" }}>
              <Stack sx={{ margin: "auto" }} spacing={2}>
                <Pagination
                  count={count}
                  page={page}
                  onChange={handleChangePage}
                />
              </Stack>
            </Box>
          </>
        )}
      </Paper>
    </Box>
  );
}
