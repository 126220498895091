import { View, Text, StyleSheet, Image } from "@react-pdf/renderer";
import { styles } from "../styles";

import { TableDefault } from "../tables/tableDefault";

export const AttackTypes = ({ contTypeAttack, graphDistAttack }) => {
  const headerTable = ["Tipo de Ataque", "Número de Alertas"];

  return (
    <View>
      <Text style={styles.title}>
        {"Distribuição do tipo de ataque por contagem de alertas"}
      </Text>
      {graphDistAttack && (
        <View
          style={{
            display: "flex",
            flexDirection: "column",
          }}
          wrap={false}
        >
          <View style={{ ...styles.legend, marginBottom: 0 }}>
            <Text style={{ fontFamily: "Times-Bold" }}>{"Gráfico 5: "}</Text>
            <Text style={{ fontFamily: "Times-Roman" }}>
              {"Percentual dos tipos de ataque"}
            </Text>
          </View>
          <View
            style={{
              display: "flex",
              width: "100%",
              alignContent: "center",
            }}
          >
            <Image
              key={"image3"}
              style={{ height: "auto", width: "500px", marginTop: 0 }}
              src={`data:application/pdf;base64,${graphDistAttack}`}
            />
          </View>
          <Text
            style={{
              ...styles.legend,
              fontFamily: "Times-Roman",
              marginTop: 10,
              marginBottom: 0,
            }}
          >
            {"Fonte: Dados coletados pelo Wanguard"}
          </Text>
        </View>
      )}
      <View style={{ marginTop: "30px" }}>
        <TableDefault data={contTypeAttack} headerTitles={headerTable} />
      </View>
    </View>
  );
};
