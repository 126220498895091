import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Button,
  Chip,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import useStyles from "./styles";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import {
  Add,
  Remove,
  Clear,
  Search,
  ExpandMore,
  VisibilityOutlined,
  VisibilityOffOutlined,
} from "@mui/icons-material";
import api from "../../../../../services/api";
import { useEffect, useState } from "react";
export default function Blocos() {
  const [search, setSearch] = useState("");
  const [searchAdd, setSearchAdd] = useState("");
  const [searchOcultado, setSearchOcultado] = useState("");
  const [blocosAdicionados, setBlocosAdicionados] = useState([]);
  const [blocosOcultados, setBlocosOcultados] = useState([]);
  const [blocos, setBlocos] = useState([]);
  const [selectRows, setSelectRows] = useState([]);
  const [selectRowsAdd, setSelectRowsAdd] = useState([]);
  const [selectRowsOcultar, setSelectRowsOcultar] = useState([]);
  const [snackbar, setSnackbar] = useState(null);
  const [loading, setLoading] = useState(false);
  const [clienteFilter, setClienteFilter] = useState(2);
  const [edit, setEdit] = useState(false);
  const [excecaoOcultar, setExcecaoOcultar] = useState(false);
  const theme = useTheme();
  const styles = useStyles(theme);

  useEffect(() => {
    getBlocos();
  }, []);

  useEffect(() => {
    const hideBlocos = blocos?.filter((bloco) =>
      selectRows?.includes(bloco?.id)
    );
    if (hideBlocos?.find((bloco) => !bloco?.clienteId)) {
      setExcecaoOcultar(true);
    } else {
      setExcecaoOcultar(false);
    }
  }, [selectRows]);

  const getBlocos = async () => {
    setLoading(true);
    try {
      const response = await api.get("/interno/blocosEasyFlow");

      setBlocos(response?.data?.blocosDisponiveis);
      setBlocosAdicionados(response?.data?.blocosAdicionados);
      setBlocosOcultados(response?.data?.blocosOcultados);
    } catch (error) {
      console.log(error);
      setSnackbar({
        children: "Erro: Não foi possível receber blocos",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleAddBlocos = async () => {
    setEdit(true);
    const blocosAdd = blocos?.filter((bloco) =>
      selectRows?.includes(bloco?.id)
    );
    const blocosDisponiveis = blocos?.filter(
      (bloco) => !selectRows?.includes(bloco?.id)
    );
    try {
      const response = await api.post("/interno/createBlocos", {
        blocos: blocosAdd,
      });

      setBlocosAdicionados((prev) => [...prev, ...response?.data]);
      setBlocos(blocosDisponiveis);
      setSnackbar({
        children: `Blocos adicionados ao EasyFlow`,
        severity: "success",
      });
      setSelectRows([]);
    } catch (error) {
      console.log(error);
      setSnackbar({
        children: "Não foi possível adicionar blocos",
        severity: "error",
      });
    } finally {
      setEdit(false);
    }
  };

  const handleRemoveBlocos = async () => {
    setEdit(true);
    const blocosRemovidos = blocosAdicionados?.filter((bloco) =>
      selectRowsAdd?.includes(bloco?.id)
    );
    const blocosAdd = blocosAdicionados?.filter(
      (bloco) => !selectRowsAdd?.includes(bloco?.id)
    );
    try {
      const response = await api.post("/interno/deleteBlocos", {
        blocos: blocosRemovidos,
      });
      setBlocos((prev) => [...prev, ...blocosRemovidos]);
      setBlocosAdicionados(blocosAdd);
      setSelectRowsAdd([]);
      setSnackbar({
        children: "Blocos removidos do EasyFlow",
        severity: "info",
      });
    } catch (error) {
      console.log(error);
      setSnackbar({
        children: "Erro ao remover blocos",
        severity: "error",
      });
    } finally {
      setEdit(false);
    }
  };

  const hideBlocosFlow = async () => {
    setEdit(true);
    const hideBlocos = blocos?.filter((bloco) =>
      selectRows?.includes(bloco?.id)
    );
    const blocosDisponiveis = blocos?.filter(
      (bloco) => !selectRows?.includes(bloco?.id)
    );

    try {
      const response = await api.post("/interno/hideBlocos", {
        blocos: hideBlocos,
      });

      setBlocosOcultados((prev) => [...prev, ...response?.data]);
      setBlocos(blocosDisponiveis);
      setSnackbar({
        children: `Blocos ocultados`,
        severity: "success",
      });
      setSelectRows([]);
    } catch (error) {
      console.log(error);
    } finally {
      setEdit(false);
    }
  };

  const showBLocosFlow = async () => {
    const showBlocos = blocosOcultados?.filter((bloco) =>
      selectRowsOcultar?.includes(bloco?.id)
    );
    try {
      const response = await api.post("/interno/showBlocos", {
        blocos: showBlocos,
      });

      setBlocos((prev) => [...prev, ...response?.data]);
      const newBlocosOcultados = blocosOcultados?.filter(
        (bloco) => !selectRowsOcultar?.includes(bloco?.id)
      );
      setBlocosOcultados(newBlocosOcultados);
      setSelectRowsOcultar([]);
      setSnackbar({
        children: "Blocos atualizados",
        severity: "success",
      });
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    } finally {
    }
  };
  const filteredRows = blocos
    ?.filter((row, index) => {
      row.numero = index;

      return (
        row?.nome?.toLowerCase()?.includes(search?.toLowerCase()) ||
        row?.blocos?.toLowerCase()?.includes(search?.toLowerCase()) ||
        row?.cliente?.toLowerCase()?.includes(search?.toLowerCase())
      );
    })
    ?.filter((row) =>
      clienteFilter === 0
        ? row.clienteId
        : clienteFilter === 1
        ? !row?.clienteId
        : row
    );

  const filteredRowsAdd = blocosAdicionados?.filter((row, index) => {
    row.numero = index;

    return (
      row?.nome?.toLowerCase()?.includes(searchAdd?.toLowerCase()) ||
      row?.blocos?.toLowerCase()?.includes(searchAdd?.toLowerCase()) ||
      row?.cliente?.toLowerCase()?.includes(searchAdd?.toLowerCase())
    );
  });

  const filteredRowsOcultados = blocosOcultados?.filter((row, index) => {
    row.numero = index;

    return (
      row?.nome?.toLowerCase()?.includes(searchOcultado?.toLowerCase()) ||
      row?.blocos?.toLowerCase()?.includes(searchOcultado?.toLowerCase()) ||
      row?.cliente?.toLowerCase()?.includes(searchOcultado?.toLowerCase())
    );
  });

  const columnsDisponiveis = [
    {
      field: "blocos",
      headerName: "Bloco",
      width: 150,
      flex: 1,
    },
    {
      field: "nome",
      headerName: "Nome",
      width: 150,
      flex: 1,
    },
    {
      field: "cliente",
      headerName: "Cliente",
      width: 110,
      flex: 1,
    },
    {
      field: "clienteId",
      headerName: "Status cliente",
      width: 110,
      flex: 1,
      maxWidth: 150,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <>
            {params?.row?.clienteId ? (
              <Chip
                label={"Cadastrado"}
                sx={{
                  ...styles.chip,
                  bgcolor: "chip.completed",
                  color: "#ffffff",
                }}
              />
            ) : (
              <Chip
                label={"Não cadastrado"}
                sx={{
                  ...styles.chip,
                  bgcolor: "chip.pending",
                  color: "#ffffff",
                }}
              />
            )}
          </>
        );
      },
    },
  ];

  const columnsAdicionados = [
    {
      field: "blocos",
      headerName: "Bloco",
      width: 150,
      flex: 1,
    },
    {
      field: "nome",
      headerName: "Nome",
      width: 150,
      flex: 1,
    },
    {
      field: "cliente",
      headerName: "Cliente",
      width: 110,
      flex: 1,
    },
  ];
  const columnsOcultados = [
    {
      field: "blocos",
      headerName: "Bloco",
      width: 150,
      flex: 1,
    },
    {
      field: "nome",
      headerName: "Nome",
      width: 150,
      flex: 1,
    },
    {
      field: "cliente",
      headerName: "Cliente",
      width: 110,
      flex: 1,
    },
  ];

  const filtroBlocos = (event) => {
    setClienteFilter(event.target.value);
  };

  return (
    <Box sx={styles.boxContainer}>
      <Accordion defaultExpanded variant={"outlined"} sx={styles?.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography variant="mySubtitle">Blocos disponíveis</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={styles.containerTitulo}>
            <TextField
              autoComplete="off"
              variant="filled"
              label="Buscar blocos"
              value={search}
              size="small"
              placeholder="Bloco, Nome, Cliente, etc..."
              onChange={(event) => {
                setSearch(event.target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
                endAdornment: search ? (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setSearch("")} edge="end">
                      <Clear />
                    </IconButton>
                  </InputAdornment>
                ) : null,
              }}
            />
            <Box sx={styles.containerAcoes}>
              <FormControl
                variant="filled"
                sx={{ marginRight: "10px", width: "160px" }}
                size="small"
              >
                <InputLabel>Status cliente</InputLabel>
                <Select
                  value={clienteFilter}
                  label="Status cliente"
                  onChange={filtroBlocos}
                  defaultValue={2}
                >
                  <MenuItem value={0}>Cadastrado</MenuItem>
                  <MenuItem value={1}>Não cadastrado</MenuItem>
                  <MenuItem value={2}>Todos</MenuItem>
                </Select>
              </FormControl>
              <Button
                sx={styles.button}
                variant="contained"
                disabled={(selectRows?.length === 0 && true) || edit}
                startIcon={<VisibilityOffOutlined />}
                onClick={hideBlocosFlow}
              >
                {Array.isArray(selectRows) && selectRows?.length === 1
                  ? `Ocultar 1 bloco`
                  : selectRows?.length > 1
                  ? `Ocultar ${selectRows?.length} blocos`
                  : "Ocultar bloco"}
              </Button>
              <Button
                variant="contained"
                disabled={
                  (selectRows?.length === 0 && true) || edit || excecaoOcultar
                }
                startIcon={<Add />}
                onClick={handleAddBlocos}
              >
                {Array.isArray(selectRows) && selectRows?.length === 1
                  ? `Adicionar 1 bloco`
                  : selectRows?.length > 1
                  ? `Adicionar ${selectRows?.length} blocos`
                  : "Adicionar bloco"}
              </Button>
            </Box>
          </Box>

          <DataGrid
            rows={filteredRows}
            columns={columnsDisponiveis}
            rowsPerPageOptions={[10, 25, 50]}
            autoHeight={true}
            pageSizeOptions={[10, 25, 50]}
            keepNonExistentRowsSelected
            hideFooterSelectedRowCount={true}
            initialState={{
              pagination: {
                paginationModel: { page: 1, pageSize: 10 },
              },
              sorting: {
                sortModel: [{ field: "clienteId", sort: "desc" }],
              },
            }}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setSelectRows(newRowSelectionModel);
            }}
            rowSelectionModel={selectRows}
            disableRowSelectionOnClick
            sx={styles.datagrid}
            disableColumnMenu
            checkboxSelection
            hideFooterPagination={false}
            loading={loading}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded variant={"outlined"} sx={styles?.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography variant="mySubtitle">
            Blocos adicionados ao EasyFlow
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={styles.containerTitulo}>
            <TextField
              autoComplete="off"
              variant="filled"
              label="Buscar blocos"
              value={searchAdd}
              size="small"
              placeholder="Bloco, Nome, Cliente, etc..."
              onChange={(event) => {
                setSearchAdd(event.target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
                endAdornment: searchAdd ? (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setSearchAdd("")} edge="end">
                      <Clear />
                    </IconButton>
                  </InputAdornment>
                ) : null,
              }}
            />
            <Button
              variant="contained"
              /* disabled={loadingTable} */
              disabled={(selectRowsAdd?.length === 0 && true) || edit}
              startIcon={<Remove />}
              onClick={handleRemoveBlocos}
            >
              {Array.isArray(selectRowsAdd) && selectRowsAdd?.length === 1
                ? `Remover 1 bloco`
                : selectRowsAdd?.length > 1
                ? `Remover ${selectRowsAdd?.length} blocos`
                : "Remover bloco"}
            </Button>
          </Box>

          <DataGrid
            rows={filteredRowsAdd}
            columns={columnsAdicionados}
            rowsPerPageOptions={[10, 25, 50]}
            autoHeight={true}
            pageSizeOptions={[10, 25, 50]}
            keepNonExistentRowsSelected
            hideFooterSelectedRowCount={true}
            initialState={{
              pagination: {
                paginationModel: { page: 1, pageSize: 10 },
              },
            }}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setSelectRowsAdd(newRowSelectionModel);
            }}
            rowSelectionModel={selectRowsAdd}
            disableRowSelectionOnClick
            sx={styles.datagrid}
            disableColumnMenu
            checkboxSelection
            loading={loading}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion variant={"outlined"} sx={styles?.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography variant="mySubtitle">Blocos ocultados</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={styles.containerTitulo}>
            <TextField
              autoComplete="off"
              variant="filled"
              label="Buscar blocos"
              value={searchOcultado}
              size="small"
              placeholder="Bloco, Nome, Cliente, etc..."
              onChange={(event) => {
                setSearchOcultado(event.target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
                endAdornment: searchOcultado ? (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setSearchOcultado("")}
                      edge="end"
                    >
                      <Clear />
                    </IconButton>
                  </InputAdornment>
                ) : null,
              }}
            />
            <Button
              variant="contained"
              /* disabled={loadingTable} */
              disabled={(selectRowsOcultar?.length === 0 && true) || edit}
              startIcon={<VisibilityOutlined />}
              onClick={showBLocosFlow}
            >
              {Array.isArray(selectRowsOcultar) &&
              selectRowsOcultar?.length === 1
                ? `Mostrar 1 bloco`
                : selectRowsOcultar?.length > 1
                ? `Mostrar ${selectRowsOcultar?.length} blocos`
                : "Mostrar bloco"}
            </Button>
          </Box>

          <DataGrid
            rows={filteredRowsOcultados}
            columns={columnsOcultados}
            rowsPerPageOptions={[10, 25, 50]}
            autoHeight={true}
            pageSizeOptions={[10, 25, 50]}
            keepNonExistentRowsSelected
            hideFooterSelectedRowCount={true}
            initialState={{
              pagination: {
                paginationModel: { page: 1, pageSize: 10 },
              },
            }}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setSelectRowsOcultar(newRowSelectionModel);
            }}
            rowSelectionModel={selectRowsOcultar}
            disableRowSelectionOnClick
            sx={styles.datagrid}
            disableColumnMenu
            checkboxSelection
            loading={loading}
          />
        </AccordionDetails>
      </Accordion>

      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </Box>
  );
}
