import { Document, Font, Page, Text, View } from "@react-pdf/renderer";
import { HeaderPdf } from "../generateReport/headerPdf";
import { AttackTrafficPage } from "./pagesPdf/attackTrafficPdf";
import { DestinationIpPage } from "./pagesPdf/destinationIpPdf";
import { TopIpAlertPage } from "./pagesPdf/top10IpAlertPdf";
import { TopIpAlertTrafficPage } from "./pagesPdf/top10IpAlertTrafficPdf";
import { ProtocolAnalysis } from "./pagesPdf/protocolAnalysisPdf";
import { AttackTypes } from "./pagesPdf/attackTypesPdf";
import { AnomaliesAnalysis } from "./pagesPdf/analysisAnomaliesPdf";
import { OriginAttack } from "./pagesPdf/originAttackPdf";

import { Footer } from "./footerPdf";
import { styles } from "./styles";
import { IntroducaoRelatorioAtaque } from "./pagesPdf/introducao";

Font.register({
  family: "Noto Sans",
  src: "http://fonts.gstatic.com/s/notosans/v6/LeFlHvsZjXu2c3ZRgBq9nKCWcynf_cDxXwCLxiixG1c.ttf",
});

export const RelatorioTemplateAtaque = ({
  relatorio,
  graphC,
  graphB,
  wanguardTable,
  contTypeAttack,
  graphDistAttack,
  graphProtocol,
  graphPercentProtocol,
}) => {
  return (
    <Document>
      <Page size="A4" style={styles.body}>
        <HeaderPdf relatorio={relatorio} />
        <Footer />
        <IntroducaoRelatorioAtaque relatorio={relatorio} />
      </Page>
      <Page size="A4" style={styles.body}>
        <HeaderPdf relatorio={relatorio} />
        <Footer />
        <AttackTrafficPage
          relatorio={relatorio}
          graphC={graphC}
          graphB={graphB}
        />
      </Page>
      {/* 	<Page size="A4" style={styles.body}>
        <HeaderPdf relatorio={relatorio} />
        <Footer />
        <DestinationIpPage relatorio={relatorio} />
      </Page>
      <Page size="A4" style={styles.body}>
        <HeaderPdf relatorio={relatorio} />
        <Footer />
        <TopIpAlertPage />
      </Page>
      <Page size="A4" style={styles.body}>
        <HeaderPdf relatorio={relatorio} />
        <Footer />
        <TopIpAlertTrafficPage />
      </Page> */}
      <Page size="A4" style={styles.body}>
        <HeaderPdf relatorio={relatorio} />
        <Footer />
        <ProtocolAnalysis
          relatorio={relatorio}
          graphProtocol={graphProtocol}
          graphPercentProtocol={graphPercentProtocol}
        />
      </Page>
      <Page size="A4" style={styles.body}>
        <HeaderPdf relatorio={relatorio} />
        <Footer />
        <AttackTypes
          contTypeAttack={contTypeAttack}
          graphDistAttack={graphDistAttack}
        />
      </Page>
      <Page size="A4" style={styles.body}>
        <HeaderPdf relatorio={relatorio} />
        <Footer />
        <AnomaliesAnalysis
          relatorio={relatorio}
          wanguardTable={wanguardTable}
        />
      </Page>
      {/* <Page size="A4" style={{ ...styles.body, paddingHorizontal: 0 }}>
				<View style={{ paddingHorizontal: 45 }}>
					<HeaderPdf relatorio={relatorio} />
				</View>
				<Footer />
				<OriginAttack relatorio={relatorio} />
			</Page> */}
    </Document>
  );
};
