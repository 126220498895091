import { View, Text, StyleSheet, Image } from "@react-pdf/renderer";
import { styles } from "../styles";

export const AttackTrafficPage = ({ relatorio, graphC, graphB }) => {
  const stylesFlowReport = StyleSheet.create({
    columnView: {
      display: "flex",
      flexDirection: "column",
      width: "50%",
    },
    columnView2: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    rowView: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "center",
    },
    image: {
      height: "auto",
      width: "100%",
    },
  });

  return (
    <View>
      <View>
        <Text style={styles.title}>{"TRÁFEGO DE ATAQUE"}</Text>
      </View>
      <View style={{ marginVertical: "10px" }}>
        <Text
          style={{
            ...styles.text,
            textAlign: "justify",
            lineHeight: 1.5,
            textIndent: "20px",
            mt: 2,
          }}
        >{`Apresentação do comportamento da rede em cenários de tráfego normal e sob ataques DDoS, utilizando dados coletados pelo EasyFlow. Por meio de dois gráficos comparativos, esta seção busca destacar as diferenças entre o tráfego legítimo, que representa o fluxo regular de dados, e o tráfego malicioso, caracterizado por padrões anômalos típicos de ataques de negação de serviço. Essa visualização permite identificar com clareza o impacto dessas ameaças na infraestrutura de rede.`}</Text>
      </View>
      <View>
        {graphC && (
          <View
            key={"boolean-top2"}
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 10,
            }}
            wrap={false}
          >
            <View style={{ ...styles.legend, marginBottom: 5 }}>
              <Text style={{ fontFamily: "Times-Bold" }}>{`Gráfico 1: `}</Text>
              <Text
                style={{ fontFamily: "Times-Roman" }}
              >{`Tráfego limpo do cliente ${relatorio?.titulo?.toUpperCase()}`}</Text>
            </View>
            <Image
              key={"iamge2"}
              style={{ height: "auto", width: "500px" }}
              src={`data:application/pdf;base64,${graphC}`}
            />

            <View
              style={{
                ...stylesFlowReport.rowView,
                marginTop: -5,
                marginBottom: 0,
                fontFamily: "Times-Roman",
              }}
            >
              <Text
                style={styles.legend}
              >{`Fonte: Dados coletados pelo EasyFlow`}</Text>
            </View>
          </View>
        )}
      </View>
      <View>
        {graphB && (
          <View
            key={"boolean-top2"}
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 10,
            }}
            wrap={false}
          >
            <View style={{ ...styles.legend, marginBottom: 5 }}>
              <Text style={{ fontFamily: "Times-Bold" }}>{`Gráfico 2: `}</Text>
              <Text
                style={{ fontFamily: "Times-Roman" }}
              >{`Tráfego DDOS do cliente ${relatorio?.titulo?.toUpperCase()}`}</Text>
            </View>
            <Image
              key={"iamge2"}
              style={{ height: "auto", width: "500px" }}
              src={`data:application/pdf;base64,${graphB}`}
            />

            <View
              style={{
                ...stylesFlowReport.rowView,
                marginTop: -5,
                marginBottom: 0,
                fontFamily: "Times-Roman",
              }}
            >
              <Text
                style={styles.legend}
              >{`Fonte: Dados coletados pelo Wanguard`}</Text>
            </View>
          </View>
        )}
      </View>
    </View>
  );
};
