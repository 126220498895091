import { Area, AreaChart, CartesianGrid, Legend, XAxis, YAxis } from "recharts";
import React from "react";
import { Box } from "@mui/material";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(timezone);

const TIMEZONE = "America/Sao_Paulo";

function converteUnidades(entrada, unidade) {
  let valor = parseFloat(
    typeof entrada === "string" ? entrada.replace(" bps") : entrada
  );
  let sufixos = "";
  if (unidade === "bps" || "B") {
    sufixos = ["", "K", "M", "G", "T", "P", "E"];
  } else {
    sufixos = ["", "k", "M", "G", "T", "P", "E"];
  }
  const isNegativo = valor < 0;
  valor = Math.abs(valor);
  let index = 0;
  while (valor >= 1000 && index < sufixos.length - 1) {
    valor /= 1000;
    index++;
  }
  const valorFormatado = valor.toFixed(2);
  const numeroFormatado =
    (isNegativo ? "-" : "") + valorFormatado + " " + sufixos[index] + unidade;

  return numeroFormatado;
}

/// ALTO CONTRASTE
const COLORS = [
  "#ED0000",
  "#DEDA07",
  "#0685D4",
  "#D15E06",
  "#009400",
  "#2F07E6",
  "#E04F07",
  "#249103",
  "#0000ff",
  "#612D2D",
  "#82813C",
  "#2C4C61",
  "#61432C",
  "#2D612D",
  "#4D3F8A",
  "#8A593F",
  "#38612C",
  "#2D2D61",
];

export default function AtaqueProtocolStackedAreaChart({ data = [] }) {
  const totalTime = Math.ceil(
    dayjs(data[data.length - 1]?.time).diff(dayjs(data[0]?.time), "minute")
  );
  //console.log(totalTime);

  const customLegend = (value, entry) => {
    return (
      <span
        style={{
          color: "#000000",
        }}
      >{`${value}`}</span>
    );
  };

  const CustomizedAxisTick = ({ x, y, stroke, payload }) => {
    let time = dayjs(payload?.value).format("DD/MM HH:mm");
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={20}
          y={10}
          fontSize={12}
          textAnchor="end"
          fill="black"
          transform="rotate(-20)"
        >
          {time}
        </text>
      </g>
    );
  };

  const CustomizedYaxisTick = ({ x, y, stroke, payload }) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} fontSize={13} textAnchor="end" fill="black">
          {converteUnidades(payload.value, "bps")}
        </text>
      </g>
    );
  };

  function calcInterval() {
    // return Math.max(1, Math.floor(data?.length / 12));
    return data.length > 12 ? Math.floor(data.length / 12) : 0;
  }

  return (
    <Box>
      <AreaChart
        data={data}
        width={790}
        height={210}
        margin={{ top: 0, right: 30, left: 20, bottom: 30 }}
      >
        <XAxis
          dataKey="time"
          type="category"
          interval={calcInterval()}
          height={40}
          tick={<CustomizedAxisTick />}
          style={{ fontSize: "12px", fontWeight: 500 }}
        />
        <CartesianGrid strokeDasharray="3 3" />

        <YAxis
          domain={[
            0,
            Math.max(...data?.map((a) => a?.UDP + a?.TCP + a?.ICMP + a?.IP)),
          ]}
          tick={<CustomizedYaxisTick />}
        />
        {data?.length > 0 &&
          Object.keys(data[0])
            .filter((key) => key !== "time")
            ?.map((objKey, index) => {
              return (
                <Area
                  key={objKey}
                  stackId="1"
                  type="monotone"
                  dataKey={`${objKey}`}
                  name={`${objKey}`}
                  strokeWidth={2}
                  stroke={`${COLORS[index]}`}
                  fill={`${COLORS[index]}`}
                  isAnimationActive={false}
                />
              );
            })}

        <Legend
          iconType="square"
          formatter={customLegend}
          wrapperStyle={{
            fontSize: "16px",
            width: "100%",
            textAlign: "justify",
            bottom: 5,
            left: 0,
          }}
        />
      </AreaChart>
    </Box>
  );
}
