import { Margin } from "@mui/icons-material";
import { color, display, fontWeight, height, margin, width } from "@mui/system";

const styles = (theme) => ({
  boxContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    left: 0,
    right: 0,
    mr: "auto",
    ml: "auto",
  },
  paperContainer: {
    backgroundColor: theme.palette.mode === "dark" && "transparent",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    borderRadius: 3,
    width: "100%",
  },
  TextField: {
    width: "400px",
  },
  containerAcoes: {
    margin: "0 15px 0 0",
  },
  textAddGrupo: {
    color:
      theme.palette.mode === "dark"
        ? "rgba(255, 255, 255, 0.5)"
        : "rgba(0, 0, 0, 0.5)",
    fontWeight: 500,
  },
  buttonAddGrupo: {
    width: "100%",
    justifyContent: "space-between",
    textAlign: "left",
  },
  colorSelectChip: {
    height: "15px",
    width: "15px",
    margin: "3px 10px",
  },
  grupoName: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  containerAddGrupo: {
    width: "265px",
    wordWrap: "break-word",
    overflowWrap: "break-word",
    whiteSpace: "normal",
  },
  chip: {
    height: "auto",
    "& .MuiChip-label": {
      display: "block",
      whiteSpace: "normal",
    },
    color: "#ffffff",
  },
  chipGrupo: {
    margin: "3px",
    color: "#ffffff",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  chipStatus: {
    color: "#ffffff",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    width: "100%",
  },
  containerGrupo: {
    maxHeight: "300px",
    overflow: "auto",
  },
  ulStyle: {
    listStyleType: "none",
    padding: "0",

    width: "100%",
    margin: "0px",
    height: "auto",
  },
  textNoGrupo: {
    fontSize: "0.875rem",
    opacity: 0.6,
    fontWeight: 500,
    margin: "10px",
  },
  textBuscarGrupo: {
    margin: "15px 10px",
    minWidth: "280px",
  },
  containerAcoesButton: {
    width: "100%",
    display: "flex",
    gap: 2,
  },
  buttonGrupo: {
    width: "100%",
    display: "flex",
    justifyContent: "start",
    maxWidth: "270px",
  },
  containerDatagridGrupos: {
    display: "flex",
    justifyItems: "start",
    flexWrap: "wrap",
    width: "100%",
    maxWidth: "230px",
  },
  containerNoGrupo: {
    width: "100%",
    display: "flex",
    justifyContent: "start",
    flexWrap: "wrap",
  },
  buttonHandle: {
    margin: "5px",
  },
  containerHandleButtons: {
    margin: "10px",
    display: "flex",
    justifyContent: "end",
  },
  paperModal: {
    padding: "10px",
    borderRadius: 3,
  },

  dialogContent: { display: "flex", flexDirection: "column", gap: "20px" },
  datagrid: {
    display: "grid",
    fontSize: 15,
    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
      outline: "none !important",
    },
    ".MuiDataGrid-cell": { display: "flex", alignItems: "center" },
  },
  iconInfoOutlined: { width: "16px", mt: "-1px" },
});

export default styles;
