import { Route } from "react-router-dom";
import LayoutClient from "../../pages/client/layout";
import HomeClient from "../../pages/client/home";
import ConfiguracoesCliente from "../../pages/client/configuracoes";
import AdminPainelCliente from "../../pages/client/administracao";
import RelatoriosCliente from "../../pages/client/relatorios";
import RelatorioFlowCliente from "../../pages/client/relatorios/relatorioFlow";
import PercentilCliente from "../../pages/client/relatorios/percentil";
import FinanceiroCliente from "../../pages/client/financeiro";
import ClientViewpoint from "../../pages/internal/administracao/clientes/modoEspelho";
import { useEffect, useState } from "react";
import BlocosFlowCliete from "../../pages/client/relatorios/blocosFlow";
import RelatorioAtaqueCliente from "../../pages/client/relatorioAtaque";

export default function ClientRoute({
  pathName = "",
  colorMode = false,
  setColorMode = () => {},
}) {
  return (
    <Route element={<ClientViewpoint />}>
      <Route
        element={
          <LayoutClient colorMode={colorMode} setColorMode={setColorMode} />
        }
      >
        <Route path={`${pathName}/home`} element={<HomeClient />} />
        <Route
          path={`${pathName}/financeiro`}
          element={<FinanceiroCliente />}
        />
        <Route
          path={`${pathName}/configuracoes`}
          element={<ConfiguracoesCliente />}
        />
        <Route path={`${pathName}/blocos`} element={<BlocosFlowCliete />} />
        <Route
          path={`${pathName}/administracao`}
          element={<AdminPainelCliente />}
        />
        <Route path={`${pathName}/relatorios`} element={<RelatoriosCliente />}>
          <Route
            path={`${pathName}/relatorios`}
            element={<RelatorioFlowCliente />}
          />
          <Route
            path={`${pathName}/relatorios/percentil`}
            element={<PercentilCliente />}
          />
        </Route>
        <Route
          path={`${pathName}/relatorio/ataque`}
          element={<RelatorioAtaqueCliente />}
        />
      </Route>
    </Route>
  );
}
