import React, { useContext, useEffect, useState } from "react";
import {
  AppBar,
  Avatar,
  Box,
  Container,
  CssBaseline,
  Icon,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
  LinearProgress,
  Tab,
  Tabs,
  Breadcrumbs,
  Link,
  Snackbar,
  Alert,
  Badge,
  useTheme,
  Divider,
  createSvgIcon,
} from "@mui/material";
import { ReactComponent as EasyFlow } from "../../../assets/icons/IconEasyFlow.svg";
import { socketInterno as socket } from "../../../services/socket";
import { connectSocket as socketConnect } from "../../../services/socket";
import api from "../../../services/api";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import useStyles from "./styles";

import logoComputizeDark from "../../../assets/imgs/logos/logocomputizeDark.webp";

import {
  DarkModeOutlined,
  LightModeOutlined,
  LogoutOutlined,
  MenuOutlined,
  PersonOutline,
  SupervisorAccountOutlined,
  Settings,
  BarChart,
  PermIdentityOutlined,
  HomeOutlined,
  LockReset,
  TroubleshootOutlined,
  NotificationImportantOutlined,
  OpenInNewOutlined,
  AttachMoneyOutlined,
  ErrorOutlineOutlined,
  WarningAmber,
} from "@mui/icons-material";
import ModalPerfil from "../../../components/internoModalPerfil";
import MobileSliderBar from "./mobileDrawer";
import { RangeContext } from "../../client/range";
import ModalEditSenhaUserLogado from "../../../components/internoModalEditSenha";
const EasyFlowIcon = createSvgIcon(<EasyFlow />);
function LinearProgressWithLabel({ value = 0 }) {
  return (
    <Box>
      <LinearProgress
        variant={value <= 0 || value >= 60 ? "buffer" : "determinate"}
        value={value * (5 / 3)}
        sx={{ color: "#fff" }}
      />
    </Box>
  );
}

function Layout(params) {
  const { colorMode, setColorMode, contratoVencimento, setContratoVencimento } =
    params;
  const [anchorEl, setAnchorEl] = useState(null);
  const [baseImage, setBaseImage] = useState(
    JSON.parse(localStorage.getItem("perfil"))?.foto || ""
  );
  const [administrador, setAdministrador] = useState(false);
  const [openModalPerfil, setOpenModalPerfil] = useState(false);
  const [openModalEditSenha, setOpenModalEditSenha] = useState(false);
  const [snackbar, setSnackbar] = useState(null);
  const { Range, setRange } = useContext(RangeContext);
  const [windowWith, setWindowWith] = useState(window.innerWidth);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [clientes, setClientes] = useState([]);
  const [notificacoes, setNotificacoes] = useState([]);
  const [anchorNotificacao, setAnchorNotificacao] = useState(null);
  /*   const [contratoVencimento, setContratoVencimento] = useState(false); */
  const openNotificacao = Boolean(anchorNotificacao);
  const [value, setValue] = useState(0);
  const theme = useTheme();
  const styles = useStyles();
  const navigate = useNavigate();

  // Uso de socket
  useEffect(() => {
    function onConnect() {
      setIsConnected(true);
      socket.emit(
        "first_connection",
        `Servidor juntou-se ao grupo id conection: ${socket.id}`
      );
    }
    function onDisconnect() {
      setIsConnected(false);
    }

    function connectErrorEvent(error) {
      console.log("Socket error - erro de conexão:", error);
    }
    function reconnectAttemptEvent(attempt) {
      console.log(
        `Socket warn - o servido esta tentando ser reconectar. N de tentativas: ${attempt}`
      );
    }
    function reconnectErrorEvent(error) {
      console.log("Socket error - erro de reeconexão:", error);
    }
    function reconnectEvent(attempt) {
      console.log("Socket success: servidor reeconectado com sucesso");
    }

    //socket.connect();
    socketConnect(socket, JSON.parse(localStorage.getItem("user"))?.token);
    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("connect_error", (data) => {
      connectErrorEvent();
      //console.log("dados", data);
    });
    socket.on("update_prefix", (data) => {
      // console.log(data);
    });
    socket.io.on("reconnect_attempt", reconnectAttemptEvent);
    socket.io.on("reconnect_error", reconnectErrorEvent);
    socket.io.on("reconnect", reconnectEvent);
    return () => {
      socket.off("connect", onConnect);

      socket.off("disconnect", onDisconnect);
      socket.off("connect_error", connectErrorEvent);
      socket.io.off("reconnect_attempt", reconnectAttemptEvent);
      socket.io.off("reconnect_error", reconnectErrorEvent);
      socket.io.off("reconnect", reconnectEvent);
      socket.disconnect();
    };
  }, []);

  // Chamada de api ao carregar a página
  useEffect(() => {
    handleUsuarioId();

    const pages = [
      "/interno/home",
      "/interno/dash",
      "/interno/resumo",
      "/interno/configuracoes",
      "/interno/administracao",
    ];

    pages.map((page, index) => {
      if (window.location.pathname.includes(page)) {
        setValue(index);
      }
    });
  }, [window.location.pathname]);

  // Verifica se o usuário é um administrador
  const handleUsuarioId = () => {
    let result = localStorage.getItem("user");
    result = JSON.parse(result);
    // Verifica se a propriedade rlPermissoesUsuario existe e é um array
    if (Array.isArray(result?.usuario?.rlPermissoesUsuario)) {
      // Itera sobre o array para verificar se algum objeto contém a propriedade codigoPermissao com valor "ADM"
      for (let permissao of result?.usuario?.rlPermissoesUsuario) {
        if (permissao?.permissao?.codigoPermissao.toUpperCase() === "ADM") {
          setAdministrador(true);
        }
      }
      return false;
    }

    if (result) {
      setBaseImage(result.foto);
    } else {
      return "";
    }
  };

  // Define imagem de perfil
  useEffect(() => {
    setBaseImage(
      localStorage.getItem("perfil") &&
        JSON.parse(localStorage.getItem("perfil")) != "undefined"
        ? JSON.parse(localStorage.getItem("perfil"))?.foto
        : ""
    );
  }, [localStorage.getItem("perfil")]);

  // Controle de exibição de filtros do Mobile
  const handleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWith(window.innerWidth);
    };
    handleApiGetClientes();
    getAllContratos();
    window.addEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    const notificacoesContrato = clientes?.filter(
      (cliente) =>
        cliente?.contrato?.length > 1 || cliente?.contrato?.length === 0
    );
    const notificacoesBlocosFlowDelete = clientes?.filter(
      (cliente) => cliente?.blocoFlowDelete?.length >= 1
    );

    const notificacoesBlocosFlow = notificacoesBlocosFlowDelete?.map(
      (notificacoesBlocosFlow) => {
        return {
          nomeFantasia: notificacoesBlocosFlow?.nomeFantasia,
          message: `Bloco não localizado no Juniper`,
          link: `/interno/home/perfil/easyflow/${notificacoesBlocosFlow?.id}`,
          icon: <EasyFlowIcon fontSize="small" sx={{ marginRight: "10px" }} />,
        };
      }
    );

    const notificacoes = notificacoesContrato?.map((notificacaoContrato) => {
      if (notificacaoContrato?.contrato?.length > 1) {
        return {
          nomeFantasia: notificacaoContrato?.nomeFantasia,
          message: `Existem ${notificacaoContrato?.contrato?.length} contratos ativos`,
          link: `/interno/home/perfil/financeiro/${notificacaoContrato?.id}`,
          icon: (
            <AttachMoneyOutlined
              fontSize="small"
              sx={{ marginRight: "10px" }}
            />
          ),
        };
      } else {
        return {
          nomeFantasia: notificacaoContrato?.nomeFantasia,
          message: `Cliente não possui contrato ativo`,
          link: `/interno/home/perfil/financeiro/${notificacaoContrato?.id}`,
          icon: (
            <AttachMoneyOutlined
              fontSize="small"
              sx={{ marginRight: "10px" }}
            />
          ),
        };
      }
    });
    setNotificacoes([...notificacoes, ...notificacoesBlocosFlow]);
  }, [clientes]);

  //funcoes de controle do menu
  const open = Boolean(anchorEl);

  async function handleApiGetClientes() {
    try {
      const response = await api.get("/cliente/home/getClient");

      setClientes(response?.data?.filter((cliente) => cliente?.status === 0));
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possivel se conectar ao servidor",
        severity: "error",
      });
    } finally {
    }
  }

  async function getAllContratos() {
    try {
      const response = await api.get("/allContratos/brbyte");

      setContratoVencimento(
        response?.data?.find(
          (contrato) =>
            contrato?.statusVencimento === 3 || contrato?.statusVencimento === 4
        )
          ? true
          : false
      );
    } catch (erro) {
      console.error(erro);
    } finally {
    }
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorNotificacao(null);
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/interno/login");
  };

  const handleCloseModalPerfil = () => {
    setOpenModalPerfil(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWith(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
  }, []);

  // Uso de breadcrumbs
  const routerNow = useLocation().pathname;

  let rotaIndexData = removerUUID(window?.location?.pathname || "")
    .split("/")
    .slice(1)
    .filter((item) => item !== "interno" && item !== "");

  let routerName = routerNow?.split("/")[routerNow?.split("/")?.length - 1];

  function removerUUID(texto) {
    const uuidRegex =
      /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}/g;
    return texto.replace(uuidRegex, "").replace(/\s+/g, " ").trim();
  }
  //console.log(window.location);

  return (
    <Box display={"flex"}>
      <CssBaseline />

      <Box>
        <AppBar
          position="fixed"
          elevation={0}
          // color="primary"
        >
          <Container maxWidth="100vw">
            <Toolbar sx={styles.toolbar}>
              {windowWith <= 744 && (
                <IconButton
                  sx={{
                    padding: "0px",
                    transform: "translateX(-20px)",
                    color: "#ffffff",
                  }}
                  onClick={handleDrawer}
                >
                  <MenuOutlined fontSize="large" />
                </IconButton>
              )}
              <Box sx={styles.toolbarNavigate}>
                <IconButton href={"/interno/dash"} sx={styles.IconButtonLogo}>
                  <Icon style={styles.logo}>
                    <img
                      src={logoComputizeDark}
                      style={styles.logo}
                      alt="logo-computize-home"
                    />
                  </Icon>
                </IconButton>

                {windowWith > 744 && (
                  <Tabs
                    orientation="horizontal"
                    value={value}
                    onChange={(event, newValue) => {
                      setValue(newValue);
                    }}
                    textColor={"secondary"}
                    TabIndicatorProps={{
                      style: {
                        background: "secondary.main",
                        display: "none",
                      },
                    }}
                    sx={{ display: "flex", height: "100%" }}
                  >
                    <Tab
                      icon={<BarChart />}
                      label={
                        windowWith > (administrador ? 1238 : 1100)
                          ? "DASHBOARD"
                          : ""
                      }
                      iconPosition="start"
                      value={1}
                      sx={styles.tab}
                      onClick={() => {
                        navigate("/interno/dash");
                      }}
                      onMouseDown={(event) => {
                        if (event.button === 1) {
                          event.preventDefault();
                          window.open("/interno/dash", "_blank");
                        }
                      }}
                    />

                    <Tab
                      icon={<PermIdentityOutlined sx={{ margin: 0 }} />}
                      label={
                        windowWith > (administrador ? 1238 : 1100)
                          ? "CLIENTES"
                          : ""
                      }
                      iconPosition="start"
                      value={0}
                      sx={styles.tab}
                      onClick={(event) => {
                        navigate("/interno/home");
                      }}
                      onMouseDown={(event) => {
                        if (event.button === 1) {
                          event.preventDefault();
                          window.open("/interno/home", "_blank");
                        }
                      }}
                    />
                    <Tab
                      icon={<TroubleshootOutlined sx={{ margin: 0 }} />}
                      label={
                        windowWith > (administrador ? 1238 : 1100)
                          ? "OVERVIEW"
                          : ""
                      }
                      iconPosition="start"
                      value={2}
                      sx={styles.tab}
                      onClick={(event) => {
                        navigate("/interno/resumo");
                      }}
                      onMouseDown={(event) => {
                        if (event.button === 1) {
                          event.preventDefault();
                          window.open("/interno/resumo", "_blank");
                        }
                      }}
                    />

                    <Tab
                      icon={<Settings />}
                      label={
                        windowWith > (administrador ? 1238 : 1100)
                          ? "Configurações"
                          : ""
                      }
                      iconPosition="start"
                      value={3}
                      sx={styles.tab}
                      onClick={() => {
                        navigate("/interno/configuracoes");
                      }}
                      onMouseDown={(event) => {
                        if (event.button === 1) {
                          event.preventDefault();
                          window.open("/interno/configuracoes", "_blank");
                        }
                      }}
                    />

                    {administrador && (
                      <Tab
                        icon={
                          windowWith <= 1238 && contratoVencimento ? (
                            <Tooltip
                              title={"Existe contrato próximo do vencimento"}
                            >
                              <Badge
                                color="primary"
                                badgeContent={"!"}
                                sx={styles.badgeColor}
                              >
                                <SupervisorAccountOutlined />
                              </Badge>
                            </Tooltip>
                          ) : (
                            <SupervisorAccountOutlined />
                          )
                        }
                        label={
                          windowWith > 1238 ? (
                            contratoVencimento ? (
                              <Tooltip
                                title={"Existe contrato próximo do vencimento"}
                              >
                                <Badge
                                  color="primary"
                                  badgeContent={"!"}
                                  sx={styles.badgeColor}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    ADMINISTRAÇÃO{" "}
                                    {contratoVencimento && (
                                      <Box
                                        sx={{
                                          width: "12px",
                                        }}
                                      />
                                    )}
                                  </Box>
                                </Badge>
                              </Tooltip>
                            ) : (
                              <>ADMINISTRAÇÃO</>
                            )
                          ) : (
                            ""
                          )
                        }
                        iconPosition="start"
                        value={4}
                        sx={styles.tab}
                        onClick={() => {
                          navigate("/interno/administracao");
                        }}
                        onMouseDown={(event) => {
                          if (event.button === 1) {
                            event.preventDefault();
                            window.open("/interno/administracao", "_blank");
                          }
                        }}
                      />
                    )}
                  </Tabs>
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 3,
                  alignItems: "center",
                }}
              >
                <Tooltip title="Notificações">
                  <IconButton
                    onClick={(e) => {
                      notificacoes?.length > 0 &&
                        setAnchorNotificacao(e.currentTarget);
                    }}
                  >
                    <Badge
                      badgeContent={notificacoes?.length}
                      sx={{
                        "& .MuiBadge-badge": {
                          backgroundColor: "#ffffff",
                          color: "#000000",
                        },
                      }}
                    >
                      <NotificationImportantOutlined color="white" />
                    </Badge>
                  </IconButton>
                </Tooltip>
                <Tooltip title="Abrir Opções">
                  <IconButton id="basic-button" onClick={handleClick}>
                    <Avatar src={baseImage} />
                  </IconButton>
                </Tooltip>
                <Menu
                  open={openNotificacao}
                  anchorEl={anchorNotificacao}
                  placement={"bottom-end"}
                  onClose={handleClose}
                >
                  <Divider />
                  <Box sx={styles.containerMenuNotificacoes}>
                    {Array.isArray(notificacoes) &&
                      notificacoes?.map((pendente, index) => (
                        <Box>
                          <Link
                            sx={styles.linkNotificacao}
                            underline="hover"
                            href={pendente?.link}
                          >
                            <MenuItem
                              sx={styles.menuItem}
                              key={`${pendente?.id}-${index}`}
                            >
                              {pendente?.icon}

                              <Box sx={styles.containerNotificacoes}>
                                <Typography
                                  sx={styles.textClientNotificacoes}
                                  variant="caption"
                                >
                                  {pendente?.nomeFantasia}
                                </Typography>
                                <Typography variant="caption">
                                  {pendente?.message}
                                </Typography>
                              </Box>
                              <OpenInNewOutlined
                                sx={styles.iconLink}
                                fontSize="small"
                              />
                            </MenuItem>
                          </Link>
                          <Divider />
                        </Box>
                      ))}
                  </Box>
                </Menu>
              </Box>
            </Toolbar>
          </Container>
          {windowWith < 1050 ? (
            <Box>
              {Range?.timerStatus && Range?.cliente?.id && (
                <LinearProgressWithLabel value={parseInt(Range?.timer) || 0} />
              )}
            </Box>
          ) : (
            <></>
          )}
        </AppBar>

        <Box sx={styles.topContent} />

        <Stack direction={"row"}>
          <Box component="main" sx={styles.main}>
            {routerNow?.split("/")?.slice(3)[0] === "perfil" && (
              <Breadcrumbs sx={styles.Breadcrumbs} aria-label="breadcrumb">
                {rotaIndexData?.map((item, index) => (
                  <Link
                    key={index}
                    underline="hover"
                    sx={styles.itemBreadcrumbs}
                    href={
                      index === 0
                        ? "/interno/home"
                        : index === 1
                        ? `/interno/home/${item}/${routerName}`
                        : `/interno/home/perfil/${item}/${routerName}`
                    }
                    color={"inherit"}
                  >
                    {item === "home" ? (
                      <HomeOutlined />
                    ) : rotaIndexData?.length - 1 === index ? (
                      <Typography sx={styles.lastItemBreadcrumbs}>
                        {item}
                      </Typography>
                    ) : (
                      item
                    )}
                  </Link>
                ))}
              </Breadcrumbs>
            )}
            <MobileSliderBar
              open={drawerOpen}
              setOpen={setDrawerOpen}
              contratoVencimento={contratoVencimento}
            />
            <Box mt={1}>
              <Outlet context={[]} />
            </Box>
          </Box>
        </Stack>
      </Box>

      {/* Menu de Opções */}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        disableScrollLock={true}
      >
        <MenuItem //</Menu>onClick={handlePerfil}
          onClick={() => {
            setOpenModalPerfil(true);
            setAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <PersonOutline />
          </ListItemIcon>
          <ListItemText>Perfil</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenModalEditSenha(true);
            setAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <LockReset />
          </ListItemIcon>
          <ListItemText>Alterar Senha</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => setColorMode((prev) => !prev)}>
          {colorMode ? (
            <>
              <ListItemIcon>
                <DarkModeOutlined />
              </ListItemIcon>
              <ListItemText>Modo Noturno</ListItemText>
            </>
          ) : (
            <>
              <ListItemIcon>
                <LightModeOutlined />
              </ListItemIcon>
              <ListItemText>Modo Claro</ListItemText>
            </>
          )}
        </MenuItem>

        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutOutlined />
          </ListItemIcon>
          <ListItemText>Sair</ListItemText>
        </MenuItem>
      </Menu>
      <ModalPerfil
        open={openModalPerfil}
        handleClose={handleCloseModalPerfil}
      />
      <ModalEditSenhaUserLogado
        open={openModalEditSenha}
        handleClose={() => {
          setOpenModalEditSenha(false);
        }}
        setSnackbar={setSnackbar}
      />
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2500}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </Box>
  );
}

export default Layout;
