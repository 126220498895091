const styles = (theme) => ({
	container: {
		display: "flex",
		flexDirection: "column",
		width: "100%",
		mt: "16px",
		alignItems: "center",
		gap: 3,
		marginTop: "-12px",
	},
	loading: {
		display: "flex",
		width: "100%",
		height: "50vh",
		justifyContent: "center",
		alignItems: "center",
	},
	containerPaper: {
		backgroundColor: theme.palette.mode === "dark" && "transparent",
		padding: "20px",
		width: "100%",
		height: "100%",
	},
	papersContainer: {
		display: "flex",
		flexDirection: "column",
		width: "100%",
		alignItems: "center",
		gap: 3,
	},
	papersContainerTrafego: {
		backgroundColor: theme.palette.mode === "dark" && "transparent",
		display: "flex",
		flexDirection: "column",
		width: "100%",
		alignItems: "center",
		gap: 3,
		padding: "20px",
	},
	filterBox: {
		display: "flex",
		flexDirection: "row",
		width: "100%",
		alignItems: "center",
		gap: 1,
	},
	filterTypograph: { textWrap: "nowrap" },
	formControl: {
		display: "flex",
		width: "130px",
		minWidth: "130px",
		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: "primary",
			color: "primary",
		},
		"& .MuiInputLabel-root": {
			color: "primary",
		},
		svg: {
			color: "primary",
		},
		input: {
			color: "primary",
		},
	},
	paper: {
		padding: "16px",
		display: "flex",
		flexDirection: "column",
		gap: 1,
		justifyContent: "space-between",
		height: "310px",
		width: "100%",
		maxWidth: "100%",
		position: "relative",
	},
	paperLiner: {
		padding: "16px",
		display: "flex",
		height: "310px",
		width: "100%",
		maxWidth: "calc(100vw - 500px)",
		minWidth: "275px",
	},

	trafficInfo: {
		display: "flex",
		flexDirection: "row",
		width: "100%",
		alignItems: "center",
		justifyContent: "space-between",
		"@media (max-width: 985px)": {
			flexDirection: "column",
			gap: 1,
			alignItems: "flex-start",
			width: "187px",
		},
	},

	itemCampo: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		"@media (max-width: 985px)": {
			alignItems: "start",
		},
	},

	itemTitle: {
		fontWeight: 500,
		mr: 1,
		fontSize: "16px",
		display: "flex",
		alingItens: "center",
		flexDirection: "row",
		"@media (max-width: 985px)": {
			width: "187px",
		},
	},
	iconTitle: {
		fontSize: "18px",
		mr: "2px",
		mt: 0.2,
	},
	itemText: {
		display: "flex",
		flexDirection: "row",
		gap: 0.5,
		flexWrap: "wrap",
		"@media (max-width: 985px)": {
			width: "187px",
			justifyContent: "start",
		},
	},
	typography: {
		fontWeight: 600,
		opacity: "0.7",
		whiteSpace: "nowrap",
	},
	textLimite: { fontSize: "14px", fontWeight: 600, whiteSpace: "nowrap" },
	textHorasMinutos: {
		fontSize: "16px",
		fontWeight: 500,
		opacity: 0.7,
		whiteSpace: "nowrap",
	},
	progressHeader: {
		display: "flex",
		flexDirection: "row",
		width: "100%",
		alignItems: "baseline",
		gap: 1,
		"@media (max-width: 916px)": {
			flexDirection: "column",
			gap: 0,
		},
	},
	progressContent: {
		display: "flex",
		flexDirection: "row",
		gap: 1,
	},
	progressContainer: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
		gap: "4px",
	},
	progressCaptionContainer: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		width: "100%",
	},

	progressCaption: {
		fontWeight: "14px",
		fontWeight: 600,
		opacity: 0.7,
	},
	linearPregress: { borderRadius: "12px", height: "16px" },
	animacaoLimiteExcedido: {
		animation: `${
			theme.palette.mode === "dark" ? "myEffectDark" : "myEffectLight"
		}
  
5s linear 0s infinite normal none`,
		"@keyframes myEffectDark": {
			"0%, 100%": {
				color: "#FA541C",
				fontWeight: "600",
			},
			"50%": { color: "#ffffff", fontWeight: "600" },
		},
		"@keyframes myEffectLight": {
			"0%, 100%": {
				color: "#FA541C",
				fontWeight: "600",
			},
			"50%": { color: "#000000", fontWeight: "600" },
		},
	},
	containerLoading: {
		display: "flex",
		width: "100%",
		height: "50vh",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
	},
	loadingDot: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	dots: {
		display: "flex",
		alignItems: "baseline",
		gap: "8px",
	},
	dot: {
		width: "10px",
		height: "10px",
		margin: "0 5px",
		backgroundColor: theme.palette.secondary.main,
		borderRadius: "50%",
		opacity: 1,
		animation: `${"fadeInOut"}
		1.5s infinite`,
		"@keyframes fadeInOut": {
			"0%, 100%": { opacity: 1 },
			"50%": { opacity: 0 },
		},
	},
});
export default styles;
