import { Box, Paper, Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import useStyles from "./styles";
import LineChartComponent from "./percentil";
import LineChart from "../../../../../components/dashboard/charts/line";
import dayjs from "dayjs";
import RemoveIcon from "@mui/icons-material/Remove";

export default function GraficosResumo({
  networkSummary = {},
  loadingCharts = false,
  selectedRow = {},
  selectedMonth = "actual",
}) {
  // Estilização
  const theme = useTheme();
  const styles = useStyles(theme);

  // Escalas de exibição
  const maxValuePercentil = Math.max(
    ...(networkSummary?.history?.map((d) => d?.value || 0) || [0])
  );
  const maxIndividualClean = Math.max(
    ...(networkSummary.zabbixHistory?.map((d) => d?.value || 0) || [0])
  );

  const maxIndividualBot = Math.max(
    ...(networkSummary[`${"mitigation_bps"}`]?.bot?.map(
      (d) => d?.value || 0
    ) || [0])
  );

  const filteredHistory =
    selectedMonth === "actual"
      ? networkSummary?.history?.filter((clean) =>
          dayjs(clean.clock).isSame(dayjs(), "month")
        )
      : networkSummary?.history?.filter((clean) =>
          dayjs(clean.clock).isSame(dayjs().subtract(1, "month"), "month")
        );

  const filteredZabbixHistory =
    selectedMonth === "actual"
      ? networkSummary?.zabbixHistory?.filter((clean) =>
          dayjs(clean.clock).isSame(dayjs(), "month")
        )
      : networkSummary?.zabbixHistory?.filter((clean) =>
          dayjs(clean.clock).isSame(dayjs().subtract(1, "month"), "month")
        );

  return (
    <Box sx={styles.container}>
      <Paper sx={styles.paper}>
        <Box sx={styles.boxChart}>
          <Typography sx={{ ...styles.cardSubText }}>Percentil</Typography>
          <LineChartComponent
            type={"percentil"}
            percentile95={
              selectedMonth == "actual"
                ? selectedRow?.consumoPercentil
                : selectedRow?.consumoPercentilPeriodoAnterior
            }
            maxValuebps={maxValuePercentil}
            data={
              filteredHistory?.map((clean, index) => {
                let result = {};
                (result = {
                  date: clean?.clock,
                  cleanTraffic: clean?.value,
                }) || (result = { date: 0, cleanTraffic: 0 });

                return result;
              }) || []
            }
          />
          <Box sx={styles.boxFilter}>
            {((selectedMonth == "actual" &&
              selectedRow?.consumoPercentil > 0) ||
              (selectedMonth != "actual" &&
                selectedRow?.consumoPercentilPeriodoAnterior > 0)) &&
              selectedRow?.percentilCliente?.percentil && (
                <Typography sx={styles.percentileText}>
                  {<RemoveIcon sx={styles.removeIcon} />}
                  {selectedRow?.percentilCliente?.percentil}th percentil
                </Typography>
              )}
          </Box>
        </Box>
      </Paper>

      <Paper sx={styles.paper}>
        <Box sx={styles.boxChart}>
          <Typography sx={{ ...styles.cardSubText, color: "chip.completed" }}>
            Tráfego Limpo
          </Typography>
          <LineChartComponent
            type={"limpo"}
            maxValuebps={maxIndividualClean}
            data={
              filteredZabbixHistory?.map((clean, index) => {
                let result = {};
                (result = {
                  date: clean?.clock,
                  cleanTraffic: clean?.value,
                }) || (result = { date: 0, cleanTraffic: 0 });

                return result;
              }) || []
            }
          />
        </Box>
      </Paper>
    </Box>
  );
}
