const styles = (theme) => ({
	container: {
		display: "flex",
		flexDirection: "column",
		maxWidth: "850px",
		gap: 3,
		width: "100%",
		marginTop: "-12px",
	},
	containerPaper: {
		backgroundColor: theme.palette.mode === "dark" && "transparent",
		padding: "20px",
	},
	tituloComponent: {
		fontSize: "21px",
	},
	chip: {
		height: "auto",
		"& .MuiChip-label": {
			display: "block",
			whiteSpace: "normal",
		},

		color: "#ffffff",
	},
	paper: {
		padding: "40px",
		margin: "20px 0",
		backgroundColor: "transparent",
	},
	containerCheck: {
		display: "flex",
		flexDirection: "column",
		alignItems: "start",
		width: "100%",
	},
	valorMbps: {
		width: "100%",
	},
	diaVencimento: {
		width: "100%",
	},
	divider: {
		margin: "15px 0 30px 0",
	},
	subtitle: {
		margin: "0 0 10px 0",
	},
	radioGrupo: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
	stack: {
		minHeight: "75px",
		display: "flex",
		flexDirection: "row",
		spacing: "20px",
		gap: "10px",
		width: "100%",
		"@media (max-width: 1040px)": {
			flexDirection: "column",
			gap: "0px",
		},
	},
	opacityStyle: {
		opacity: 0.7,
	},
	containerExcedente: {
		marginBottom: "20px",
	},
	formInput: {
		margin: "0 10px",
	},
	titlePercentil: {
		width: "200px",
		marginTop: "20px",
	},
	button: {
		margin: "0 10px",
	},
	containerButton: {
		width: "100%",
		padding: "10px",
	},
	containerConfig: {
		display: "flex",
		alignItems: "center",
		"@media (max-width: 1040px)": {
			flexDirection: "column",
		},
	},
	containerDate: {
		display: "flex",
		gap: "10px",
		"@media (max-width: 1040px)": {
			flexDirection: "column",
		},
	},
	containerPermissao: {
		width: "100%",

		display: "flex",
		flexDirection: "column",
	},
	containerTitle: {
		display: "flex",
		justifyContent: "space-between",
		"@media (max-width: 1040px)": {
			flexDirection: "column",
		},
	},
	containerSelect: {
		display: "flex",
		flexDirection: "column",
		mb: "10px",
	},
	containerLoadindButton: {
		spacing: 2,
		alignItems: "end",
		"@media (max-width: 1040px)": {
			alignItems: "center",
		},
	},
	containerSaveButton: {
		display: "flex",
	},
	containerLoadingMensage: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: 400,
		backgroundColor: theme.palette.background.paper,
		borderRadius: "5px",
		boxShadow: 24,
	},
	constainerTitleModel: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		padding: "5px",
		color: "#ffffff",
	},
	containerLinearProgress: {
		mt: "15px",
		backgroundColor: theme.palette.background.paper,
		"& .MuiLinearProgress-bar": {
			backgroundColor: "#FA541C",
		},
	},
	containerConfigRefresh: {
		display: "flex",
		gap: "15px",
		justifyContent: "space-between",
		alignItems: "start",
		"@media (max-width: 1040px)": {
			flexDirection: "column",
		},
	},

	containerRefresh: {
		ml: "10px",
		alignItems: "start",
		"@media (max-width: 1040px)": {
			ml: "0",
		},
		display: "flex",
		alignItems: "center",
	},
	alertSuccess: {
		backgroundColor: theme.palette.mode === "dark" ? "#07070752" : "#e0ece0",
		color: theme.palette.mode === "dark" ? "rgb(184, 251, 223)" : "#1E4620",
	},
});
export default styles;
