import { useTheme } from "@emotion/react";
import useStyle from "./styles";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Add, Clear, Delete, Edit, Search } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import CreateUpdateAgendamento from "./createUpdateAgendamento";
import InternoModalDeleteCliente from "../../../../../components/internoModalDeleteCliente";
import api from "../../../../../services/api";
import { useOutletContext } from "react-router-dom";

export default function AgendamentoRelatorioAtaque({
  setLoading = () => {},
  setSnackbar = () => {},
}) {
  const [data, setData] = useState([
    {
      id: "uuid",
      opcao: 0,
      diaInicio: null,
      periodo: null,
      ocorrencias: 12,
      blocos: ["9da61ca0-87d4-43d5-aa46-a0b8df8aa250"],
      emails: ["edluel@hotmail.com", "edluelmachado@gmail.com"],
      status: true,
    },
    {
      id: "b",
      periodo: "01 - 10",
      blocos: "2.2.2.2, 16.16.16.16",
      emails: ["mail@mail.com"],
      ocorrencias: "12/12",
      status: false,
    },
    {
      id: "c",
      periodo: "15 - 15",
      blocos: "10.10.10.10, 0.0.0.0",
      emails: ["2@mail.com", "edluel@hotmail.com"],
      ocorrencias: "7/12",
      status: true,
    },
  ]);
  // Estilização
  const theme = useTheme();
  const styles = useStyle(theme);
  // Infomações do cliente
  const [sb, clienteInfo, si] = useOutletContext();
  // Variáveis de manipulação
  const [searchRows, setSearchRows] = useState(null);
  const [searchStatus, setSearchStatus] = useState("Todos");
  const [filteredRows, setFilteredRows] = useState([]);
  const [openModalAgendarEditar, setOpenModalAgendarEditar] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [blocos, setBlocos] = useState([]);

  // Atualização do filtered rows
  useEffect(() => {
    setFilteredRows(data);
  }, [data]);

  // Execuções ao carregar a página
  useEffect(() => {
    handleApiGetBlocos();
  }, [clienteInfo]);

  // Filtra dados a serem exibidos de acordo com o campo buscar e status
  useEffect(() => {
    if (searchRows) {
      setFilteredRows(
        data?.filter((row, index) => {
          return (
            (row?.blocos?.toLowerCase()?.includes(searchRows?.toLowerCase()) ||
              row?.periodo
                ?.toLowerCase()
                ?.includes(searchRows?.toLowerCase()) ||
              row?.emails?.toLowerCase()?.includes(searchRows?.toLowerCase()) ||
              row?.ocorrencias
                ?.toLowerCase()
                ?.includes(searchRows?.toLowerCase())) &&
            (row?.status == Boolean(searchStatus) || searchStatus === "Todos")
          );
        })
      );
    } else {
      setFilteredRows(
        data?.filter((row, index) => {
          return (
            row?.status == Boolean(searchStatus) || searchStatus === "Todos"
          );
        })
      );
    }
  }, [searchRows, searchStatus]);

  // Views do DataGrid
  const periodoCellView = (params) => {
    const opt = params?.row?.opcao;
    return opt === 0
      ? "Mês Inteiro"
      : opt === 1
      ? "15 Dias"
      : opt === 2
      ? "Semanalmente"
      : opt === 3
      ? `${params?.row?.periodo} ${params?.row?.periodo > 1 ? "Dias" : "Dia"}`
      : "";
  };

  const emailCellView = (params) => {
    const mailString = params?.row?.emails?.toString()?.replaceAll(",", ", ");
    return (
      <Tooltip title={mailString}>
        <Typography noWrap>
          {mailString > 30 ? `${mailString.substring(0, 30)}...` : mailString}
        </Typography>
      </Tooltip>
    );
  };

  const statusCellView = (params) => {
    return (
      <Tooltip
        title={`${
          params?.row?.status ? "Ativado" : "Desativado"
        }, clique para ${params?.row?.status ? "desativar" : "ativar"}`}
        id="basic-button"
      >
        <FormControlLabel
          sx={{
            display: "block",
          }}
          control={
            <Switch
              checked={params?.row?.status}
              onChange={() => {
                handleChangeStatus(params?.row);
              }}
              name="status"
              sx={styles.status}
            />
          }
        />
      </Tooltip>
    );
  };

  const optionCellView = (params) => {
    return (
      <Box key={params?.id}>
        <Tooltip title={"Editar"} id="basic-button" onClick={() => {}}>
          <IconButton size="small">
            <Edit color="primary" />
          </IconButton>
        </Tooltip>
        <Tooltip title={"Remover"} id="basic-button" onClick={() => {}}>
          <IconButton
            size="small"
            onClick={() => {
              setSelectedRow(params?.row);
              setOpenDeleteConfirm(true);
            }}
          >
            <Delete color="secondary" />
          </IconButton>
        </Tooltip>
      </Box>
    );
  };

  // Columns do DataGrid
  const columns = [
    {
      editable: false,
      field: "id",
      headerName: "ID",
      hide: true,
    },
    {
      field: "periodo",
      headerName: "Periodo",
      flex: 1,
      renderCell: periodoCellView,
    },
    {
      field: "blocos",
      headerName: "Blocos",
      flex: 1,
    },
    {
      field: "email",
      headerName: "E-mail",
      flex: 1,
      renderCell: emailCellView,
    },
    { field: "ocorrencias", headerName: "Ocorrências", flex: 1 },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: statusCellView,
    },
    {
      field: "opcoes",
      headerName: "Opções",
      sortable: false,
      renderCell: optionCellView,
    },
  ];

  // Requisição dos blocos
  async function handleApiGetBlocos() {
    try {
      const response = await api.post("/interno/findMany/blocos", {
        clienteId: clienteInfo?.id,
      });

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar os blocos",
          severity: "error",
        });
      } else {
        if (response.data) {
          setBlocos(response.data);
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    } finally {
    }
  }

  // Atualização dos agendamentos
  const handleUpdateAgendamento = async (inputData) => {
    try {
      console.log("input", inputData);

      const parseBlocosId = inputData.blocosSelecionados.map(
        (bloco) => bloco?.id
      );

      // Envio do agendamento
      // const response = await api.post("", {
      // });
      const objToSend = {
        clienteId: clienteInfo?.id,
        blocosId: parseBlocosId,
        allBlocos:
          inputData?.allBlocos || inputData.blocosSelecionados.length === 0,
        ocorrencias: inputData?.config,
        emails: inputData?.emailss,
      };

      console.log("output", objToSend);

      setSnackbar({
        children: `Agendamento salvo com sucesso`,
        severity: "success",
      });
      setOpenModalAgendarEditar(false);
    } catch (error) {
      setSnackbar({
        children: `Error:${
          error?.response?.data?.error || "Falha ao salvar agendamento"
        } `,
        severity: "error",
      });
    }
  };

  const handleChangeStatus = async (row) => {
    try {
      setLoading(true);
      setData(
        data?.map((item) => {
          if (item?.id === row?.id) {
            return { ...item, status: !item?.status };
          } else {
            return item;
          }
        })
      );

      setSnackbar({
        children: `Status alterado com sucesso`,
        severity: "success",
      });
    } catch (error) {
      setSnackbar({
        children: `Error:${
          error?.response?.data?.error ||
          "Falha ao editar status do agendamento"
        } `,
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveAgendamento = async () => {
    try {
      console.log(selectedRow);

      setSelectedRow(null);
      setOpenDeleteConfirm(false);
      setSnackbar({
        children: `Agendamento removido com sucesso`,
        severity: "success",
      });
    } catch (error) {
      setSnackbar({
        children: `Error:${
          error?.response?.data?.error || "Falha ao deletar agendamento"
        } `,
        severity: "error",
      });
    }
  };

  return (
    <Box sx={styles.boxContainer}>
      <Paper
        sx={styles.paperContainer}
        elevation={3}
        variant={theme.palette.mode === "dark" ? "outlined" : "elevation"}
      >
        {/* Título */}
        <Typography variant="mySubtitle">
          Agendamentos de Envio de Relatórios de Ataques
        </Typography>
        {/* Barra Superior */}
        <Box sx={styles.searchBar}>
          <Box sx={styles.searchFields}>
            <TextField
              size="small"
              label="Buscar"
              placeholder="Período, Email, etc..."
              value={searchRows}
              onChange={(e) => {
                setSearchRows(e.target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
                endAdornment: false ? ( //value
                  <InputAdornment>
                    <IconButton onClick={() => {}} edge="end">
                      <Clear />
                    </IconButton>
                  </InputAdornment>
                ) : null,
              }}
              autoComplete="off"
            />
            <FormControl variant="filled" sx={styles.searchStatus}>
              <InputLabel id="label-filter">Status</InputLabel>
              <Select
                size={"small"}
                labelId="label-filter"
                name="filter"
                label="Status"
                variant="filled"
                sx={styles.searchStatusSelect}
                defaultValue={"Todos"}
                value={searchStatus}
                onChange={(e) => {
                  setSearchStatus(e.target.value);
                }}
              >
                <MenuItem value={true}>Ativado</MenuItem>
                <MenuItem value={false}>Desativado</MenuItem>
                <MenuItem value={"Todos"}>Todos</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Button
            variant="contained"
            startIcon={<Add />}
            onClick={() => {
              setOpenModalAgendarEditar(true);
            }}
          >
            Novo Agendamento
          </Button>
        </Box>
        {/* Tabela */}
        <DataGrid
          rows={filteredRows || []}
          columns={columns}
          pageSizeOptions={[25, 50, 100]}
          initialState={{
            pagination: {
              paginationModel: { page: 1, pageSize: 25 },
            },
            columns: {
              columnVisibilityModel: {
                id: false,
              },
            },
          }}
          columnHeaderHeight={80}
          disableColumnMenu
          density="compact"
          getRowHeight={() => 40}
          disableRowSelectionOnClick
          sx={styles.dataGrid}
        />
      </Paper>
      <CreateUpdateAgendamento
        open={openModalAgendarEditar}
        onClose={() => {
          setOpenModalAgendarEditar(false);
        }}
        execute={(obj) => {
          handleUpdateAgendamento(obj);
        }}
        blocos={blocos}
      />
      <InternoModalDeleteCliente
        openDeleteModal={openDeleteConfirm}
        deleteLoad={false}
        setOpenDeleteModal={setOpenDeleteConfirm}
        execute={handleRemoveAgendamento}
        severity={"warning"}
        contentText={
          "Se você prosseguir com a operação, o agendamento será deletado permanentemente!"
        }
        //confirmText={""}
      />
    </Box>
  );
}
