const styles = (theme) => ({
  container: {
    display: "flex",
    width: "100%",
    maxWidth: "100%",
    flexDirection: "column",
    gap: 2,
    height: "100%",
  },
  boxHeader: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    maxWidth: "100%",
    gap: 1,
    alignItems: "center",
    flexWrap: "wrap",
  },
  paperHeader: {
    display: "flex",
    flexGrow: 1,
    gap: 1,
    padding: "8px",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    height: "40px",
  },
  loading: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    height: "500px",
  },
});

export default styles;
