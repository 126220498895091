const styles = (theme) => ({
	divider: {
		margin: "15px 0 30px 0",
	},
	container: {
		display: "flex",
		flexDirection: "column",
		maxWidth: "850px",
		gap: 3,
		width: "100%",
		marginTop: "-12px",
	},
	containerPaper: {
		backgroundColor: theme.palette.mode === "dark" && "transparent",
		padding: "20px",
	},
	containerTitle: {
		width: "100%",
	},
	containerDate: {
		display: "flex",
		gap: "10px",
		mt: "15px",
		"@media (max-width: 1040px)": {
			flexDirection: "column",
		},
	},
	containerLoadindButton: {
		spacing: 2,
		alignItems: "end",
		"@media (max-width: 1040px)": {
			alignItems: "center",
		},
	},
	datePicker: {
		width: "34%",
		"@media (max-width: 1200px)": {
			width: "100%",
		},
	},
	containerBlocos: {
		mt: "25px",
		width: "100%",
	},
	//model
	containerLoadingMensage: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: 400,
		backgroundColor: theme.palette.background.paper,
		borderRadius: "5px",
		boxShadow: 24,
	},
	constainerTitleModel: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		padding: "5px",
		color: "#ffffff",
	},
	containerLinearProgress: {
		mt: "15px",
		backgroundColor: theme.palette.background.paper,
		"& .MuiLinearProgress-bar": {
			backgroundColor: "#FA541C",
		},
	},
});
export default styles;
