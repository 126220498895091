import { useTheme } from "@emotion/react";
import useStyle from "./styles.js";
import {
  Modal,
  Paper,
  Box,
  TextField,
  Button,
  Autocomplete,
  Grid,
  DialogTitle,
  Checkbox,
  createFilterOptions,
  IconButton,
  Stack,
} from "@mui/material";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { useEffect, useState } from "react";
import { Add, Delete } from "@mui/icons-material";

export default function CreateUpdateAgendamento({
  open = false,
  onClose = () => {},
  execute = () => {},
  blocos = [],
}) {
  // Config exibida ao abrir o modal
  const defaultConfig = {
    opcao: 0,
    diaInicio: 1,
    periodo: 1,
    numOcorrencia: 1,
  };
  // Estilização
  const theme = useTheme();
  const styles = useStyle(theme);
  //salvar os blocos
  const [blocosSelecionados, setBlocosSelecionados] = useState([]);
  const [allBlocos, setAllBlocos] = useState(false);
  // Emails
  const [listaMail, setListaMail] = useState([""]);
  // Data e frequencia
  const [ocorrencia, setOcorrencia] = useState(defaultConfig);

  // Redefine opções
  useEffect(() => {
    setBlocosSelecionados([]);
    setOcorrencia(defaultConfig);
    setListaMail([""]);
  }, [open]);

  // Manipulação de emails
  const handleChangeListaMail = (e, index) => {
    let auxMail = [...listaMail];
    auxMail[index] = e.target.value.replace(/\s/g, "");
    setListaMail(auxMail);
  };
  const addBlock = () => {
    setListaMail([...listaMail, ""]);
  };
  const removeBlock = (index) => {
    const newBlock = [...listaMail];
    newBlock.splice(index, 1);
    setListaMail(newBlock);
  };
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Manipulação da ocorrencia
  function handleOcorrenciaChange(e) {
    const { name, value } = e.target;
    const namesToLimitValue = ["diaInicio", "periodo"];

    if (namesToLimitValue.includes(name)) {
      setOcorrencia((prev) => ({
        ...prev,
        [name]: Math.min(31, value),
      }));
    } else {
      setOcorrencia((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  }

  return (
    <Modal open={open} onClose={onClose} sx={styles.modal}>
      <Box display="flex">
        <Paper elevation={3} sx={styles.paper}>
          {/* Título */}
          <DialogTitle color="primary" sx={{ padding: 0 }}>
            Configurar Agendamento
          </DialogTitle>
          <Grid container spacing={2}>
            {/* Período e repetições */}
            <Grid item xs={4}>
              <TextField
                label="Dia inicial"
                type="number"
                name="diaInicio"
                size="small"
                fullWidth
                InputProps={{
                  inputProps: { min: 1, max: 31 },
                  onInput: (e) => {
                    if (e.target.value?.length > 2) {
                      e.target.value = e.target.value.slice(1);
                    }
                    e.target.value = Math.max(
                      1,
                      e.target.value.padStart(2, "0")
                    );
                  },
                }}
                value={ocorrencia?.diaInicio?.toString()?.padStart(2, "0")}
                onChange={handleOcorrenciaChange}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Período (dias)"
                type="number"
                name="periodo"
                size="small"
                fullWidth
                InputProps={{
                  inputProps: { min: 1, max: 31 },
                  onInput: (e) => {
                    if (e.target.value?.length > 2) {
                      e.target.value = e.target.value.slice(1);
                    }
                    e.target.value = Math.max(
                      1,
                      e.target.value.padStart(2, "0")
                    );
                  },
                }}
                value={ocorrencia?.periodo?.toString()?.padStart(2, "0")}
                onChange={handleOcorrenciaChange}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Ocorrências"
                type="number"
                name="numOcorrencia"
                size="small"
                fullWidth
                InputProps={{
                  inputProps: { min: 1, max: 999 },
                  onInput: (e) => {
                    if (e.target.value?.length > 3) {
                      e.target.value = e.target.value.slice(1);
                    }
                    e.target.value = Math.max(
                      1,
                      e.target.value.padStart(3, "0")
                    );
                  },
                }}
                value={ocorrencia?.numOcorrencia?.toString()?.padStart(3, "0")}
                onChange={handleOcorrenciaChange}
                autoComplete="off"
              />
            </Grid>

            {/* Seletor de Blocos */}
            <Grid item xs={12}>
              <Autocomplete
                multiple
                id="blocos-ip"
                options={blocos}
                disableCloseOnSelect
                size="small"
                getOptionLabel={(option) => option?.blocos}
                filterOptions={(options, params) => {
                  const filter = createFilterOptions();
                  const filtered = filter(options, params);
                  return [
                    { blocos: "Selecionar todos os blocos", all: true },
                    ...filtered,
                  ];
                }}
                isOptionEqualToValue={(option, value) =>
                  option.blocos === value.blocos
                }
                value={blocosSelecionados}
                onChange={(event, newValue) => {
                  if (newValue.find((option) => option.all)) {
                    setAllBlocos(true);
                    return setBlocosSelecionados(
                      blocosSelecionados.length === blocos.length ? [] : blocos
                    );
                  }
                  setAllBlocos(false);
                  setBlocosSelecionados(newValue);
                }}
                renderOption={(props, option, { selected }) => {
                  const { key, ...optionProps } = props;
                  return (
                    <li key={key} {...optionProps}>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon />}
                        checkedIcon={<CheckBoxIcon />}
                        style={{ marginRight: 8 }}
                        checked={
                          option.all
                            ? !!(blocosSelecionados.length === blocos.length)
                            : selected
                        }
                      />
                      {option.blocos}
                    </li>
                  );
                }}
                renderTags={(selected) =>
                  selected.length === 1
                    ? selected.map((option) => option.blocos)
                    : [`${selected.length} blocos selecionados`]
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    helperText={
                      "Caso não seja selecionado nenhum bloco, será considerado todos os blocos"
                    }
                    label="Blocos"
                    fullWidth
                  />
                )}
              />
            </Grid>

            {/* Email */}
            {listaMail.map((email, index) => (
              <Grid item xs={12}>
                <Stack direction="row" key={index}>
                  <TextField
                    size="small"
                    fullWidth
                    id="textfield-emails"
                    label={"Email " + (index + 1)}
                    value={email}
                    error={!!email && !validateEmail(email)}
                    helperText={
                      !!email && !validateEmail(email) ? "Email inválido" : ""
                    }
                    onChange={(event) => handleChangeListaMail(event, index)}
                    autoComplete="off"
                  />
                  {listaMail.length > 1 && (
                    <IconButton onClick={() => removeBlock(index)}>
                      <Delete color="secondary" />
                    </IconButton>
                  )}
                  <IconButton size="small" onClick={addBlock}>
                    <Add color="primary" />
                  </IconButton>
                </Stack>
              </Grid>
            ))}
          </Grid>

          {/* Handler */}
          <Box display="flex" justifyContent="flex-end" gap={2}>
            <Button variant="outlined" size="small" onClick={onClose}>
              Cancelar
            </Button>
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={() => {
                execute({
                  blocosSelecionados: blocosSelecionados,
                  allBlocos: allBlocos,
                  config: ocorrencia,
                  emails: listaMail,
                });
              }}
            >
              Salvar
            </Button>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
}
