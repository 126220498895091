import { Svg, Text, View, Line } from "@react-pdf/renderer";
import { Fragment } from "react";
import { styles } from "./styles";

export function TableDefault({ data, headerTitles }) {
  const TableHeader = ({ headerTitles }) => {
    const title = headerTitles.map((value, index) => (
      <View
        key={"table-cell" + index}
        style={{ ...styles.tableCell, backgroundColor: "#f5f5f5" }}
      >
        <Text>{value}</Text>
      </View>
    ));
    return <Fragment>{title}</Fragment>;
  };
  const ItemsTable = ({ data }) => (
    <View style={{ flexDirection: "column", marginTop: "15px" }}>
      <View style={styles.tableContainer}>
        <TableHeader headerTitles={headerTitles} />
      </View>
      <View style={{ flexDirection: "row" }}>
        <TableRow items={data} />
      </View>
    </View>
  );

  const TableRow = ({ items }) => {
    return (
      <View style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        {Array.isArray(Object.keys(items)) &&
          Object.keys(items).map((item) => {
            return (
              <View style={styles.row}>
                <Text style={{ ...styles.tableCell, backgroundColor: "#fff" }}>
                  {item}
                </Text>
                <Text style={{ ...styles.tableCell, backgroundColor: "#fff" }}>
                  {items?.[item]}
                </Text>
              </View>
            );
          })}
      </View>
    );
  };

  return <ItemsTable data={data} />;
}
