import { View, Text, StyleSheet } from "@react-pdf/renderer";
import { TableDefault } from "../tables/tableDefault";
import { styles } from "../styles";

export const DestinationIpPage = (relatorio) => {
	let ipTeste = "45.170.200.0/22";
	const dataInicial = relatorio?.relatorio?.dataInicial;
	const dataFinal = relatorio?.relatorio?.dataFinal;

	const headerTable = [
		"GEO",
		"IP de Destino",
		"Total(Dropped bits)",
		"Max",
		"Avg",
	];

	const data = {
		id: "5df3180a09ea16dc4b95f910",
		items: [
			{
				geo: "Brazil",
				ipDest: "179.240.22.27",
				totalBits: "1.3G",
				max: "122.8K",
				avg: "5.1K",
			},
			{
				geo: "United States",
				ipDest: "192.5.6.30",
				totalBits: "1.3G",
				max: "1.3G",
				avg: "5.1K",
			},
			{
				geo: "Australia",
				ipDest: "203.133.248.6",
				totalBits: "1.2G",
				max: "1.2G",
				avg: "4.7K",
			},
			{
				geo: "Australia",
				ipDest: "203.133.248.110",
				totalBits: "1.1G",
				max: "1.1G",
				avg: "4.3K",
			},
			{
				geo: "Brazil",
				ipDest: "179.240.22.27",
				totalBits: "1.3G",
				max: "122.8K",
				avg: "5.1K",
			},
		],
	};
	return (
		<>
			<View>
				<Text style={styles.title}>{"TOP IPs DE DESTINO"}</Text>
				<Text style={styles.text}>{`Padrão ${ipTeste} (pps)`}</Text>
				<Text style={styles.text}>{`${dataInicial} ~ ${dataFinal}`}</Text>
				<TableDefault data={data} headerTitles={headerTable} />
			</View>
			<View style={{ marginTop: "50px" }}>
				<Text style={styles.title}>{"TOP 10 SOURCE IP"}</Text>
				<Text style={styles.text}>{`Padrão ${ipTeste} (pps)`}</Text>
				<Text style={styles.text}>{`${dataInicial} ~ ${dataFinal}`}</Text>
				<TableDefault data={data} headerTitles={headerTable} />
			</View>
		</>
	);
};
