import {
  Typography,
  useTheme,
  CircularProgress,
  Accordion,
  AccordionSummary,
  Grid,
  AccordionDetails,
  IconButton,
  Button,
  Snackbar,
  Alert,
  Paper,
} from "@mui/material";
import { saveAs } from "file-saver";
import useStyles from "./styles";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import apiCliente from "../../../services/apiCliente";
import dayjs from "dayjs";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import {
  AttachMoneyOutlined,
  CalendarTodayOutlined,
  DescriptionOutlined,
  ExpandMore,
  MoneyOffOutlined,
  TaskOutlined,
  RequestPageOutlined,
} from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import customParseFormat from "dayjs/plugin/customParseFormat";
export default function Financeiro() {
  const theme = useTheme();
  const [snackbar, setSnackbar] = useState(null);
  const [alertSize, setAlertSize] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingCobranca, setLoadingCobranca] = useState(false);
  const [windowWith, setWindowWith] = useState(window.innerWidth);
  const [contratos, setContratos] = useState([]);
  const [cobrancas, setCobrancas] = useState([]);
  const styles = useStyles(theme);
  const navigate = useNavigate();
  const customParseFormat = require("dayjs/plugin/customParseFormat");
  dayjs.extend(customParseFormat);
  useEffect(() => {
    handleUsuarioId();
    if (handleUsuarioId() === false) {
      navigate("/login");
    }
  }, []);

  const handleUsuarioId = () => {
    let result = JSON.parse(localStorage.getItem("userCliente"));
    // Verifica se a propriedade rlPermissoesUsuario existe e é um array
    if (Array.isArray(result?.usuarioCliente?.RlPermissoesUsuarioCliente)) {
      // Itera sobre o array para verificar se algum objeto contém a propriedade codigoPermissao com valor "ADM"
      for (let permissao of result?.usuarioCliente
        ?.RlPermissoesUsuarioCliente) {
        if (
          permissao?.permissao?.codigoPermissao.toUpperCase() === "ADM" ||
          "FINANCEIRO"
        ) {
          return true;
        }
      }
      return false;
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWith(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    getContratos();
  }, []);

  async function getContratos() {
    setLoading(true);

    try {
      const response = await apiCliente.post("/brbyte/cliente/contrato", {
        cnpj: JSON.parse(localStorage.getItem("userCliente"))?.usuarioCliente
          ?.cliente?.cnpj,
      });

      setContratos(response?.data?.contratosDetails);
      let dados = [];
      response?.data?.contratosDetails?.map(async (contrato) => {
        const response = await getCobrancas(contrato?.contract_number);

        if (Array.isArray(response)) dados = [...dados, ...response];
        setCobrancas(dados);
      });
    } catch (error) {
      if (error?.response?.status == 404) {
        setSnackbar({
          children: `${
            error?.response?.data?.error ||
            "Error: Não foi possivel encontrar contratos"
          }`,
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Error: Não foi possivel se conectar ao servidor",
          severity: "error",
        });
      }
    } finally {
      setLoading(false);
    }
  }
  async function downloadContrato(contrato) {
    try {
      window.open(
        (window?.config?.REACT_APP_BASE_URL || process.env.REACT_APP_BASE_URL) +
          `/contratos/anotacoes/brbyte?client_pk=${contrato?.client_pk}`,
        "_blank"
      );
    } catch {
    } finally {
    }
  }

  async function getCobrancas(contrato_number) {
    setLoadingCobranca(true);

    try {
      const response = await apiCliente.post(
        "/brbyte/cliente/contrato/cobranca",
        {
          contrato_number: contrato_number,
        }
      );

      return response?.data?.results;
    } catch (error) {
      if (error?.response?.status == 404) {
        setSnackbar({
          children: `${
            error?.response?.data?.error ||
            "Error: Não foi possivel encontrar contratos"
          }`,
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Error: Não foi possivel se conectar ao servidor",
          severity: "error",
        });
      }
    } finally {
      setLoadingCobranca(false);
    }
  }

  const converteTipo = {
    0: "Contratual",
  };

  const getRows = () => {
    let data =
      cobrancas?.map((cobranca) => {
        const dadosRows = {
          id: cobranca?.invoice_pk || "",
          valor: "R$" + cobranca?.invoice_amount_document || "-",
          contrato: cobranca?.contract_number || "",
          vencimento:
            dayjs(cobranca?.invoice_date_due).format("DD-MM-YYYY") || "-",
          tipo: converteTipo[cobranca?.invoice_type] || "-",
          pagamento: "R$" + cobranca?.invoice_amount_nf || "-",
          pagamentoData: cobranca?.invoice_date_credit
            ? dayjs(cobranca?.invoice_date_credit).format("DD-MM-YYYY")
            : "Não efetuado",

          boleto: cobranca?.invoice_print_link || "-",
          recibo: cobranca?.invoice_amount_paid,
        };

        return dadosRows;
      }) || [];
    if (data.length > 0) {
      data.sort((a, b) => a.id - b.id);
    }
    return data;
  };

  const columns = [
    {
      field: "id",
      headerName: "Cobrança",
      minWidth: 30,
      flex: 1,
      cellClassName: (params) => {
        const today = new Date();
        const vencimento = new Date(
          dayjs(params?.row?.vencimento, "DD-MM-YYYY").format("YYYY-MM-DD")
        );

        return clsx("super-app", {
          pago: params?.row?.pagamentoData !== "Não efetuado",
          vencido:
            today > vencimento && params?.row?.pagamentoData === "Não efetuado",
        });
      },
    },
    {
      field: "valor",
      headerName: "Valor",
      minWidth: 120,
      flex: 1,
      cellClassName: (params) => {
        const today = new Date();
        const vencimento = new Date(
          dayjs(params?.row?.vencimento, "DD-MM-YYYY").format("YYYY-MM-DD")
        );

        return clsx("super-app", {
          pago: params?.row?.pagamentoData !== "Não efetuado",
          vencido:
            today > vencimento && params?.row?.pagamentoData === "Não efetuado",
        });
      },
    },
    {
      field: "contrato",
      headerName: "Nº Contrato",
      minWidth: 120,
      flex: 1,
      cellClassName: (params) => {
        const today = new Date();
        const vencimento = new Date(
          dayjs(params?.row?.vencimento, "DD-MM-YYYY").format("YYYY-MM-DD")
        );

        return clsx("super-app", {
          pago: params?.row?.pagamentoData !== "Não efetuado",
          vencido:
            today > vencimento && params?.row?.pagamentoData === "Não efetuado",
        });
      },
    },
    {
      field: "vencimento",
      headerName: "Data de vencimento",
      minWidth: 120,
      flex: 1,
      type: "dataTime",
      valueGetter: (params) => {
        return new Date(dayjs(params, "DD-MM-YYYY").format("YYYY-MM-DD"));
      },
      renderCell: (params) => {
        return params?.row?.vencimento;
      },
      cellClassName: (params) => {
        const today = new Date();
        const vencimento = new Date(
          dayjs(params?.row?.vencimento, "DD-MM-YYYY").format("YYYY-MM-DD")
        );

        return clsx("super-app", {
          pago: params?.row?.pagamentoData !== "Não efetuado",
          vencido:
            today > vencimento && params?.row?.pagamentoData === "Não efetuado",
        });
      },
    },
    {
      field: "tipo",
      headerName: "Tipo",
      minWidth: 120,
      flex: 1,
      cellClassName: (params) => {
        const today = new Date();
        const vencimento = new Date(
          dayjs(params?.row?.vencimento, "DD-MM-YYYY").format("YYYY-MM-DD")
        );

        return clsx("super-app", {
          pago: params?.row?.pagamentoData !== "Não efetuado",
          vencido:
            today > vencimento && params?.row?.pagamentoData === "Não efetuado",
        });
      },
    },

    {
      field: "pagamentoData",
      headerName: "Data do pagamento",
      minWidth: 120,
      flex: 1,
      valueGetter: (params) => {
        return new Date(dayjs(params, "DD-MM-YYYY").format("YYYY-MM-DD"));
      },
      renderCell: (params) => {
        return params?.row?.pagamentoData;
      },
      cellClassName: (params) => {
        const today = new Date();
        const vencimento = new Date(
          dayjs(params?.row?.vencimento, "DD-MM-YYYY").format("YYYY-MM-DD")
        );

        return clsx("super-app", {
          pago: params?.row?.pagamentoData !== "Não efetuado",
          vencido:
            today > vencimento && params?.row?.pagamentoData === "Não efetuado",
        });
      },
    },
    {
      field: "boleto",
      headerName: "Boleto",
      maxWidth: 100,
      flex: 1,
      renderCell: (params) => {
        return (
          <IconButton
            onClick={() => {
              window.open(params?.row?.boleto, "_blank");
            }}
          >
            <RequestPageOutlined />{" "}
          </IconButton>
        );
      },
      cellClassName: (params) => {
        const today = new Date();
        const vencimento = new Date(
          dayjs(params?.row?.vencimento, "DD-MM-YYYY").format("YYYY-MM-DD")
        );

        return clsx("super-app", {
          pago: params?.row?.pagamentoData !== "Não efetuado",
          vencido:
            today > vencimento && params?.row?.pagamentoData === "Não efetuado",
        });
      },
    },
    {
      field: "recibo",
      headerName: "Recibo",
      maxWidth: 100,
      flex: 1,
      renderCell: (params) => {
        return (
          <IconButton
            disabled={!params?.row?.recibo}
            onClick={() =>
              window.open(`/financeiro/recibo/${params?.row?.id}`, "_blank")
            }
          >
            <DescriptionOutlined />{" "}
          </IconButton>
        );
      },
      cellClassName: (params) => {
        const today = new Date();
        const vencimento = new Date(
          dayjs(params?.row?.vencimento, "DD-MM-YYYY").format("YYYY-MM-DD")
        );

        return clsx("super-app", {
          pago: params?.row?.pagamentoData !== "Não efetuado",
          vencido:
            today > vencimento && params?.row?.pagamentoData === "Não efetuado",
        });
      },
    },
  ];
  /* data de pagamento invoice_date_credit
data de vencimento invoice_date_due */
  return (
    <Box sx={styles.containerComponent}>
      {loading ? (
        <Box sx={styles.containerLoading}>
          <CircularProgress size={60} />
        </Box>
      ) : (
        <Box sx={styles.container}>
          <Paper
            defaultExpanded
            variant={theme.palette.mode === "dark" ? "outlined" : "elevation"}
            sx={styles.paperBoletos}
            elevation={2}
            disableGutters
          >
            <Typography variant="mySubtitle" sx={styles.title}>
              Contratos
            </Typography>
            {Array.isArray(contratos) &&
              contratos?.map((contrato, index) => (
                <>
                  <Paper
                    defaultExpanded
                    key={index}
                    variant={"outlined"}
                    sx={styles.paperContrato}
                    elevation={2}
                    disableGutters
                  >
                    {/* <Box sx={styles.containerContrato}>
                      {" "}
                      <Typography sx={styles.itemTitleContrato}>
                        <DescriptionOutlined sx={styles.iconTitleContrato} />
                        Contrato
                      </Typography>
                      <Typography sx={styles.itemTextContrato}>
                        {contrato?.contract_number}
                      </Typography>
                    </Box> */}
                    <Grid
                      container
                      direction={"row"}
                      sx={styles.accordionSummaryBox}
                      spacing={2}
                      columns={
                        windowWith > 1400 ? 16 : windowWith > 900 ? 10 : 8
                      }
                      display={"flex"}
                      justifyContent={"center"}
                    >
                      <Grid item xs={3} sx={styles.accordionSummaryItem}>
                        <Typography sx={styles.itemTitle}>
                          <DescriptionOutlined
                            color="secondary"
                            sx={styles.iconTitle}
                          />
                          Número do contrato
                        </Typography>
                        <Typography sx={styles.itemText}>
                          <Typography sx={styles.typography}> Nº </Typography>
                          {contrato?.contract_number}
                        </Typography>
                      </Grid>

                      <Grid item xs={3} sx={styles.accordionSummaryItem}>
                        <Typography sx={styles.itemTitle}>
                          <AttachMoneyOutlined
                            color="secondary"
                            sx={styles.iconTitle}
                          />
                          Valor
                        </Typography>
                        <Typography sx={styles.itemText}>
                          <Typography sx={styles.typography}> R$: </Typography>
                          {parseFloat(contrato?.contract_amount)?.toFixed(2)}
                        </Typography>
                      </Grid>

                      <Grid item xs={3} sx={styles.accordionSummaryItem}>
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <Typography sx={styles.itemTitle}>
                            <CalendarTodayOutlined
                              color="secondary"
                              sx={styles.iconTitle}
                            />
                            Dia
                          </Typography>
                          <Typography sx={styles.itemText}>
                            {contrato?.contract_pay_day}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={3} sx={styles.accordionSummaryItem}>
                        <Typography sx={styles.itemTitle}>
                          <MoneyOffOutlined
                            color="secondary"
                            sx={styles.iconTitle}
                          />
                          Desconto
                        </Typography>
                        <Typography sx={styles.itemText}>
                          <Typography sx={styles.typography}> R$: </Typography>
                          {contrato?.contract_pay_discount}
                        </Typography>
                      </Grid>

                      <Grid item xs={3} sx={styles.accordionSummaryItem}>
                        <Typography sx={styles.itemTitle}>
                          <TaskOutlined
                            color="secondary"
                            sx={styles.iconTitle}
                          />
                          Ativação
                        </Typography>
                        <Typography sx={styles.itemText}>
                          {dayjs(contrato?.contract_date_activation).format(
                            "DD-MM-YYYY"
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Button
                      onClick={(event) => {
                        event.stopPropagation();
                        contrato?.contract_sign_info?.[1]
                          ? window.open(
                              contrato?.contract_sign_info?.[1]?.replace(
                                "http://cliente.computizenetwork.com/",
                                "http://cliente.computize.network/"
                              )
                            )
                          : contrato?.contratoAnotacoes
                          ? downloadContrato(contrato)
                          : setSnackbar({
                              children: `${"Não foi possível encontrar arquivo. Entre em contato com o suporte."}`,
                              severity: "info",
                            });
                      }}
                      sx={{ height: "40px" }}
                      variant="outlined"
                    >
                      Contrato
                    </Button>
                  </Paper>
                </>
              ))}
          </Paper>

          <Paper
            defaultExpanded
            variant={theme.palette.mode === "dark" ? "outlined" : "elevation"}
            sx={styles.paperBoletos}
            elevation={2}
            disableGutters
          >
            <Typography variant="mySubtitle" sx={styles.title}>
              Boletos
            </Typography>
            <Paper
              variant="outlined"
              sx={{
                backgroundColor:
                  theme.palette.mode === "dark" ? "transparent" : "none",
              }}
            >
              {loadingCobranca ? (
                <Box sx={styles.containerLoading}>
                  <CircularProgress size={60} />
                </Box>
              ) : (
                <DataGrid
                  rows={cobrancas && getRows()}
                  columns={columns}
                  disableColumnMenu
                  disableRowSelectionOnClick
                  hideFooter={false}
                  autoHeight={true}
                  density="compact"
                  pageSizeOptions={[5, 10]}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 5 },
                    },
                    sorting: {
                      sortModel: [{ field: "vencimento", sort: "desc" }],
                    },
                  }}
                  sx={styles.datagrid}
                />
              )}
            </Paper>
          </Paper>

          <Snackbar
            autoHideDuration={3000}
            open={alertSize}
            onClose={() => {
              setAlertSize(false);
            }}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert severity="error">Tamanho máximo permitido: 1MB</Alert>
          </Snackbar>
          {!!snackbar && (
            <Snackbar
              open
              onClose={() => setSnackbar(null)}
              autoHideDuration={3000}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <Alert {...snackbar} onClose={() => setSnackbar(null)} />
            </Snackbar>
          )}
        </Box>
      )}
    </Box>
  );
}
