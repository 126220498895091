import { Area, AreaChart, CartesianGrid, XAxis, YAxis } from "recharts";
import React, { useState } from "react";
import { Box } from "@mui/material";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(timezone);

const TIMEZONE = "America/Sao_Paulo";
function converteUnidades(entrada, unidade) {
  let valor = parseFloat(
    typeof entrada === "string" ? entrada.replace(" bps") : entrada
  );
  let sufixos = "";
  if (unidade === "bps" || "B") {
    sufixos = ["", "K", "M", "G", "T", "P", "E"];
  } else {
    sufixos = ["", "k", "M", "G", "T", "P", "E"];
  }
  const isNegativo = valor < 0;
  valor = Math.abs(valor);
  let index = 0;
  while (valor >= 1000 && index < sufixos.length - 1) {
    valor /= 1000;
    index++;
  }
  const valorFormatado = valor.toFixed(2);
  const numeroFormatado =
    (isNegativo ? "-" : "") + valorFormatado + " " + sufixos[index] + unidade;

  return numeroFormatado;
}

export default function AtaqueLineChartBotClean({ data = [], type = "" }) {
  const timeRangeDays = Math.abs(
    dayjs(data?.[0]?.date).diff(dayjs(data?.[data?.length - 1]?.date), "day")
  );
  const breakPointXAxisDays = type === "limpo" ? 12 : 13;
  const pointChageTickInterval = type === "limpo" ? 19 : 20;

  const CustomizedAxisTick = ({ x, y, stroke, payload }) => {
    let time = dayjs(payload.value).format("DD/MM HH:mm");
    if (timeRangeDays > breakPointXAxisDays) {
      time = dayjs(payload.value).format("DD/MM");
    }
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={20}
          y={10}
          fontSize={12}
          textAnchor="end"
          fill="black"
          transform="rotate(-20)"
        >
          {time}
        </text>
      </g>
    );
  };

  const CustomizedYaxisTick = ({ x, y, stroke, payload }) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} fontSize={13} textAnchor="end" fill="black">
          {converteUnidades(payload.value, "bps")}
        </text>
      </g>
    );
  };

  function calcInterval() {
    // console.log(type);
    // console.log(timeRangeDays);
    // console.log(timeRangeDays > breakPointXAxisDays);
    // console.log("começo:", data?.[0]);
    // console.log("fim:", data?.[data?.length - 1]);
    // console.log(dayjs(data?.[0]?.date).format("DD/MM"));
    // console.log(
    //   1440 /
    //     (Math.abs(dayjs(data?.[0]?.date).diff(dayjs(data?.[1]?.date)) / 60) /
    //       1000)
    // );

    if (timeRangeDays > breakPointXAxisDays) {
      const multiplyer = timeRangeDays > pointChageTickInterval ? 2 : 1;
      return Math.floor(
        (1440 /
          (Math.abs(dayjs(data?.[0]?.date).diff(dayjs(data?.[1]?.date)) / 60) /
            1000)) *
          multiplyer
      );
      return Math.max(1, Math.floor(data?.length / 20) - 2);
    } else {
      return Math.max(1, Math.floor(data?.length / 11) - 2);
    }
  }

  return (
    <Box>
      <AreaChart
        data={data}
        width={790}
        height={210}
        margin={{ top: 23, right: 30, left: 20, bottom: 10 }}
      >
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#00a76f" stopOpacity={1} />
            <stop offset="95%" stopColor="#00a76f" stopOpacity={1} />
          </linearGradient>
        </defs>
        <XAxis
          dataKey="date"
          type="category"
          interval={calcInterval()}
          height={40}
          tick={<CustomizedAxisTick />}
          style={{ fontSize: "12px", fontWeight: 500 }}
        />
        <CartesianGrid strokeDasharray="3 3" />

        <YAxis
          domain={[0, Math.max(...data.map((a) => a.value))]}
          tick={<CustomizedYaxisTick />}
        />
        {type === "limpo" ? (
          <Area
            type="monotone"
            dataKey="value"
            name="Tráfego Limpo"
            stroke="#00a76f"
            fill="url(#colorUv)"
            isAnimationActive={false}
          />
        ) : (
          <Area
            type="monotone"
            dataKey="value"
            name="Tráfego Sujo"
            stroke="#fa8a64"
            fill="#FA541C"
            isAnimationActive={false}
          />
        )}
      </AreaChart>
    </Box>
  );
}
