const styles = (theme) => ({
  container: {
    width: "100%",
    alignItems: "center",
    flexDirection: "column",
    display: "flex",
  },
  paper: {
    alignItems: "center",
    width: "100%",
    padding: "20px",
    justifyContent: "center",
    backgroundColor: theme.palette.mode === "dark" && "transparent",
  },
  BoxStack: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    mb: "16px",
    height: "68px",
  },
  containerBuscar: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paperMobile: {
    margin: "10px 0",
    display: "flex",
    flexDirection: "column",
    bgcolor: "transparent",
  },
  containerHeader: {
    width: "100%",
    padding: "10px",
    display: "flex",
    alignItems: "center",
  },
  containerNumberContrato: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  containerChipStatusContrato: {
    display: "flex",
    alignItems: "center",
  },
  containerDadosContratoMobile: {
    width: "100%",
    padding: "10px",
    display: "flex",
    flexDirection: "column",
  },
  titleMobile: {
    fontSize: "13px",
    margin: "5px 0",
  },

  dataGrid: {
    display: "grid",
    "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
      py: "5px",
    },
    "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {},
    "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
      py: "22px",
    },
    fontSize: 15,
    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
      outline: "none !important",
    },
  },
});

export default styles;
