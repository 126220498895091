import { color, display, margin, padding } from "@mui/system";

const styles = (theme) => ({
  paperContrato: {
    backgroundColor: theme.palette.mode === "dark" ? "transparent" : "none",
    padding: "15px",
    display: "flex",
    marginTop: "10px",
  },
  containerContrato: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "130px",
    height: "50px",
    marginLeft: "-15.2px",
    marginTop: "-15.2px",
    borderRadius: "4px 0 20px 0",
    backgroundColor: "#FA541C",
    color: "white",
    padding: "5px",
  },
  accordionSummaryBox: { display: "flex", width: "100%", flexDirection: "row" },
  accordionSummaryItemContrato: {
    display: "flex",
    width: "200px",
    flexDirection: "column",
  },
  accordionSummaryItem: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  itemTitle: {
    fontWeight: 600,
    mr: 1,
    fontSize: "14px",
    display: "flex",
    alingItens: "center",
    flexDirection: "row",
  },

  itemTitleContrato: {
    fontWeight: 600,
    mr: 1,
    fontSize: "14px",
    display: "flex",
    alingItens: "center",
    flexDirection: "row",
    fontSize: "16px",
    color: "#ffffff",
  },

  iconTitle: {
    fontSize: "16px",
    mr: "2px",
    mb: -0.3,
  },
  iconTitleContrato: {
    fontSize: "20px",
    mr: "2px",
    mb: -0.3,
    color: "#ffffff",
  },

  itemText: {
    display: "flex",
    flexDirection: "row",
    ml: "1px",
  },
  itemTextContrato: {
    display: "flex",
    flexDirection: "row",
    fontSize: "16px",
    color: "#ffffff",
  },
  title: {
    margin: "0 0 20px 0",
    fontSize: "21px",
  },
  containerContratoNumber: {
    display: "flex",
    flexDirection: "row",
    width: "125px",
    height: "40px",
    borderRight: "1px solid #9e9e9e",
    borderBottom: "1px solid #9e9e9e",
    marginTop: "-12px",
    marginLeft: "-16px",
    borderRadius: "0 0 20px 0",
  },
  containerLoading: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100%",
  },
  containerComponent: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  container: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    marginTop: "10px",
  },
  typography: {
    fontWeight: 600,
    opacity: "0.7",
    mr: "2px",
    ml: "2px",
  },
  paperBoletos: {
    marginTop: "20px",
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.mode === "dark" ? "transparent" : "none",
    padding: "30px",
  },
  paperContainer: {
    backgroundColor: theme.palette.mode === "dark" ? "transparent" : "none",
  },
  datagrid: {
    display: "grid",
    "&.MuiDataGrid-root": { borderStyle: "none" },
    "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
      outline: "none !important",
    },
    "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
      /* py: "15px", */
    },
    "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell teste===1": {
      /* py: "15px", */
      backgroundColor: "red",
    },
    "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
      py: "15px",
    },
    "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
      py: "22px",
    },
    '&.MuiDataGrid-root .MuiDataGrid-container--top [role="row"], & .MuiDataGrid-root .MuiDataGrid-container--bottom [role="row"]':
      {
        backgroundColor: "background.paper",
      },
    "& .super-app.pago": {
      backgroundColor: theme.palette.mode === "dark" ? "#00a76f77" : "#c8e6c9",
    },
    "& .super-app.vencido": {
      backgroundColor: theme.palette.mode === "dark" ? "#a7000077" : "#e6c8c8",
    },
  },
});

export default styles;
