const styles = (theme) => ({
  modal: { display: "flex", alignItems: "center", justifyContent: "center" },
  paper: {
    display: "flex",
    flexDirection: "column",
    padding: "20px",
    borderRadius: 3,
    width: "520px",
    margin: "10px",
    gap: 3,
  },
});
export default styles;
