import {
  Area,
  AreaChart,
  CartesianGrid,
  Cell,
  Legend,
  Pie,
  PieChart,
  Sector,
  XAxis,
  YAxis,
} from "recharts";
import React, { useState } from "react";
import { Box } from "@mui/material";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(timezone);

const TIMEZONE = "America/Sao_Paulo";

export default function AtaquePieChart({ data = [] }) {
  /// ALTO CONTRASTE
  const COLORS = [
    "#ED0000",
    "#DEDA07",
    "#0685D4",
    "#D15E06",
    "#009400",
    "#2F07E6",
    "#E04F07",
    "#249103",
    "#0000ff",
    "#612D2D",
    "#82813C",
    "#2C4C61",
    "#61432C",
    "#2D612D",
    "#4D3F8A",
    "#8A593F",
    "#38612C",
    "#2D2D61",
  ];

  const customLabel = (props) => {
    if (data?.length > 0) {
      const RADIAN = Math.PI / 180;
      const {
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        startAngle,
        endAngle,
        fill,
        payload,
        percent,
        value,
      } = props;
      const sin = Math.sin(-RADIAN * midAngle);
      const cos = Math.cos(-RADIAN * midAngle);
      const sx = cx + (outerRadius + 10) * cos;
      const sy = cy + (outerRadius + 10) * sin;
      const mx = cx + (outerRadius + 20) * cos;
      const my = cy + (outerRadius + 20) * sin;
      const ex = mx; // + (cos >= 0 ? 1 : -1) * 22;
      const ey = my;
      const tx = cx + (outerRadius + 35) * cos;
      const ty = cy + (outerRadius + 25) * sin;
      const textAnchor = "middle"; //cos >= 0 ? 'start' : 'end';

      return (
        <g>
          <path
            d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
            stroke={fill}
            fill="none"
          />
          <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
          <text
            x={tx + (cos >= 0 ? 10 : -8)}
            y={ty + (sin >= 0 ? 10 : 2)}
            textAnchor={textAnchor}
            fontSize={16}
            fontWeight={"bold"}
            fill="#333"
          >{`${(percent * 100).toFixed(1)}%`}</text>
        </g>
      );
    }
  };

  const customLegend = (value, entry) => {
    return (
      <span
        style={{
          color: "#000000",
        }}
      >{`${value} ${
        data?.lengh > 0 && entry?.payload?.percent
          ? "(" +
            (entry?.payload?.percent * 100)?.toFixed(1)?.toString() +
            " %)"
          : ""
      }`}</span>
    );
  };

  return (
    <Box>
      <PieChart
        data={data}
        width={790}
        height={490}
        margin={{ top: -100, bottom: 20 }}
      >
        <Pie
          label={customLabel}
          data={data?.length > 0 ? data : [{ name: "Sem Dados", value: 1 }]}
          cx="50%"
          cy="50%"
          outerRadius={150}
          fill="#eeeeee"
          isAnimationActive={false}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS?.length]} />
          ))}
        </Pie>
        <Legend
          formatter={customLegend}
          wrapperStyle={{
            fontSize: "16px",
            width: "100%",
            textAlign: "justify",
          }}
        />
      </PieChart>
    </Box>
  );
}
