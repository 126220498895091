import { Document, Font, Page, Text } from "@react-pdf/renderer";
import React, { Fragment } from "react";

import { HeaderPdf } from "./headerPdf";
import { styles } from "./styles";
import { Footer } from "./footerPdf";
import { FirstPage } from "./firstPage";

import { convertDateStr, convertTimeStr } from "../../utils/flowReport";
import { Teoric } from "./teoricPage";
import FlowReport from "./flowReport";

import dayjs from "dayjs";
import "dayjs/locale/pt-br";

Font.register({
	family: "Noto Sans",
	src: "http://fonts.gstatic.com/s/notosans/v6/LeFlHvsZjXu2c3ZRgBq9nKCWcynf_cDxXwCLxiixG1c.ttf",
});
function agrupaItensDeConteudo(conteudoHost) {
	const itens = conteudoHost?.reduce(
		(
			accumulator,
			{ tag, hostName, history, itemId, itemName, unit, ...rest }
		) => {
			const existingItem = accumulator?.find(
				(item) => item?.itemName === itemName
			);
			const hosts = [{ hostName, history, itemId, ...rest }];
			if (existingItem) {
				existingItem.hosts = existingItem?.hosts?.concat(hosts);
			} else {
				const newItem = { tag, unit, itemId, itemName, hosts };
				accumulator?.push(newItem);
			}
			return accumulator;
		},
		[]
	);
	return itens;
}

export const PageTemplateEasyReport = ({ relatorio, graphcs }) => {
	//const conteudoHost = relatorio?.analysis;
	const itens =
		relatorio?.tipo === 0
			? agrupaItensDeConteudo()
			: //conteudoHost
			  null;
	let ultimaData = new Date(relatorio?.dataFinal);
	ultimaData.setDate(ultimaData.getDate() - 1);
	const reportsProps = {
		horaInicial: dayjs(relatorio?.dataInicial).format("HH:mm:ss"),
		horaFinal: dayjs(relatorio?.dataFinal).format("HH:mm:ss"),
		dataInicial: dayjs(relatorio?.dataInicial).format("DD/MM/YYYY"),
		dataFinal: dayjs(relatorio?.dataFinal).format("DD/MM/YYYY"),
		tipo: relatorio?.tipo,
		etapa: relatorio?.HistoricoEasyReport?.etapa,
		etapas: relatorio?.etapas,
		ultimaData: dayjs(ultimaData),
	};

	// let cont = 1;
	// const contTable = () => {
	//   let soma = 0;
	//   itens?.map(({ hosts, tag }) => {
	//     if (
	//       tag === "traffic"
	//         ? !!(hosts[0]?.c95th && hosts[0]?.trafficFull)
	//         : tag === "boolean"
	//         ? !!hosts[0]?.availability
	//         : false
	//     ) {
	//       soma += 1;
	//     }
	//   });
	//   return soma;
	// };

	return (
		<Document>
			<Page
				size="A4"
				style={styles.body}
				pageTitle="Nome do relatorio"
				key={relatorio?.id}
			>
				<HeaderPdf relatorio={relatorio} />

				<FirstPage
					//itens={itens}
					// Conteudos host agrupava dados de analysis com a identificação
					//do cliente e da tarifacao
					//conteudoHost={relatorio}
					{...reportsProps}
					client={relatorio?.client}
					tarifacaoExcedente={relatorio?.tarifacaoExcedente}
					analysis={relatorio?.analysis}
					relatorio={relatorio}
					reportsProps={reportsProps}
					graphcs={graphcs}
				/>
				{/* <Fragment>
          <FlowReport
            percentil={relatorio?.percentil}
            client={relatorio?.client}
            interfaces={relatorio?.interfaces}
            tarifacaoExcedente={relatorio?.tarifacaoExcedente}
            analysis={relatorio?.analysis}
            index={0}
            {...reportsProps}
            //graphcs={graphcs?.filter((a) => a?.client === relatorio?.client)}
            //indiceGraphcs={[cont, cont + 1]}
          />
          {!relatorio?.tarifacaoExecedente?.status
            ? (cont += relatorio?.analysis?.length + 1)
            : (cont = cont + 1)}
        </Fragment> */}

				<Teoric
					hideCharts={relatorio?.hideCharts}
					percentil={relatorio?.percentil}
				/>
				<Footer />
			</Page>
		</Document>
	);
};
