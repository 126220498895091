import { display, margin, width } from "@mui/system";

const styles = (theme) => ({
  boxContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    left: 0,
    right: 0,
    mr: "auto",
    ml: "auto",
  },
  paperContainer: {
    backgroundColor: theme.palette.mode === "dark" && "transparent",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    borderRadius: 3,
    width: "100%",
    margin: "20px 0",
  },
  containerTitulo: {
    width: "100%",
    margin: "10px 0",
    display: "flex",
    justifyContent: "space-between",
  },
  containerFooter: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    position: "relative",
    margin: "10px 0",
  },
  chip: {
    color: "white",
    fontWeight: 600,
  },
  datagrid: {
    display: "grid",
  },
  containerAcoes: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  accordion: {
    backgroundColor: theme.palette.mode === "dark" ? "transparent" : "none",
    marginTop: "14px",
    width: "100%",
  },
  button: {
    margin: "0 7px 0 0",
  },
});

export default styles;
