import { View, Text, StyleSheet, Image } from "@react-pdf/renderer";
import { styles } from "../styles";
import dayjs from "dayjs";

export const IntroducaoRelatorioAtaque = ({ relatorio }) => {
  const dataInicial = dayjs(relatorio?.dataInicial).format(
    "DD/MM/YYYY HH:mm:ss"
  );
  const dataFinal = dayjs(relatorio?.dataFinal).format("DD/MM/YYYY HH:mm:ss");

  return (
    <View>
      <View>
        <Text style={styles.title}>{"RELATÓRIO DE ATAQUE"}</Text>
      </View>

      <View
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* <Text
					style={{ ...styles.text, fontFamily: "Times-Bold" }}
				>{`Data Inicial :`}</Text> */}
        <Text
          style={{ ...styles.text, lineHeight: 1.5 }}
        >{`Data Inicial: ${dataInicial} `}</Text>
        {/* <Text
					style={{ ...styles.text, fontFamily: "Times-Bold" }}
				>{`Data Final :`}</Text> */}
        <Text style={styles.text}>{`Data Final: ${dataFinal}`}</Text>
      </View>
      <View style={{ marginVertical: "10px" }}>
        <Text
          style={{
            ...styles.text,
            textAlign: "justify",
            lineHeight: 1.5,
            textIndent: "20px",
            mt: 2,
          }}
        >{`Resultados de ataque relacionados ao cliente ${
          relatorio?.titulo
        }. As métricas utilizadas para os cálculos dos valores apresentados são: tráfego de ataque, tipo de ataque, contagem por número de alertas, grupos de IP e blocos. As coletas dos valores apresentados neste relatório correspondem ao período entre ${dayjs(
          relatorio?.dataInicial
        ).format("DD/MM/YYYY HH:mm:ss")} a ${dayjs(relatorio?.dataFinal).format(
          "DD/MM/YYYY HH:mm:ss"
        )}.`}</Text>
      </View>
      <View style={{ display: "flex", flexDirection: "column" }}>
        <Text
          style={{ ...styles.text, fontFamily: "Times-Bold", lineHeight: 1.5 }}
        >{`Bloco(s): `}</Text>
        <Text style={styles.text}>{`${relatorio?.blocos?.join(", ")}`}</Text>
      </View>
    </View>
  );
};
