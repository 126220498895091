import { useEffect, useState } from "react";
import apiCliente from "../../../../services/apiCliente";

import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  createFilterOptions,
  Divider,
  IconButton,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Snackbar,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import useStyles from "./styles";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
  ArrowDropDownOutlined,
  Clear,
  Search,
  CheckBoxOutlineBlank,
  MoreHoriz,
  CheckCircleOutlineOutlined,
  DoNotDisturbAltOutlined,
  Delete,
  Check,
  MoreVertOutlined,
  Add,
} from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import { ptBR } from "@mui/x-data-grid/locales";
import dayjs from "dayjs";
import { socketCliente as socket } from "../../../../services/socket";
import InternoModalDeleteCliente from "../../../../components/internoModalDeleteCliente";
import { minWidth, Stack, width } from "@mui/system";
const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export default function BlocosFlowCliete() {
  const theme = useTheme();
  const styles = useStyles(theme);
  const [blocos, setBlocos] = useState([]);
  const [buscar, setBuscar] = useState("");
  const [openRemoveManyModalGrupo, setOpenRemoveManyModalGrupo] =
    useState(false);
  const [anchorElGrupos, setAnchorElGrupos] = useState(null);
  const [grupos, setGrupos] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const [openDeleteModalGrupo, setOpenDeleteModalGrupo] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [idGrupoDelete, setIdGrupoDelete] = useState("");
  const [handleGrupo, setHandleGrupo] = useState({
    grupoName: "",
    colorGrupo: "",
  });
  const [buscarGrupo, setBuscarGrupo] = useState("");
  const [loadingHandleGrupo, setLoadingHandleGrupo] = useState(false);
  const [loadingRemoveGrupo, setLoadingRemoveGrupo] = useState(false);
  const [openRemoveModalGrupo, setOpenRemoveModalGrupo] = useState(false);
  const [thisRowGrupo, setThisRowGrupo] = useState({});
  const [gruposRow, setGruposRow] = useState([]);
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [loadingManyStatus, setLoadingManyStataus] = useState(false);
  const [anchorElOptionsGrupo, setAnchorElOptionsGrupo] = useState(null);
  const [anchorElStatus, setAnchorElStatus] = useState(null);
  const [snackbar, setSnackbar] = useState(null);
  const [selectBloco, setSelectBloco] = useState([]);
  const [grupoId, setGrupoId] = useState("");
  const [blocoId, setBlocoId] = useState("");
  const [grupoExist, setGrupoExist] = useState(false);
  const openMenuOptionsGrupo = Boolean(anchorElOptionsGrupo);
  const open = Boolean(anchorEl);
  const openMenuStatus = Boolean(anchorElStatus);
  const openMenuGrupos = Boolean(anchorElGrupos);
  const confirmDelete = "Excluir";
  const deleteBody =
    "O item será deletado permanentemente. Deseja deletar o item?";
  const deleteBodyManyGrupos =
    "Deseja remover todos os grupos dos itens selecionados?";
  const deleteBodyRemoveGrupo =
    "O bloco será removido desse grupo. Deseja remover o bloco do grupo?";
  const confirmRemoveGrupo = "Remover";
  const colorChip = [
    "chip.pending",
    "chip.completed",
    "chip.inProgress",
    "chip.waitingReview",
    "chip.waitingReturn",
    "chip.arquive",
  ];

  const filteredRows = Array.isArray(grupos)
    ? grupos?.filter((row, index) => {
        row.numero = index;
        return row.grupoName
          ?.toLowerCase()
          ?.includes(buscarGrupo?.toLowerCase());
      })
    : [];
  //CONEXÃO SOCKET.IO
  useEffect(() => {
    function onConnect() {
      setIsConnected(true);
      socket.emit(
        "first_connection",
        `Servidor juntou-se ao grupo id conection: ${socket.id}`
      );
    }
    function onDisconnect() {
      setIsConnected(false);
    }

    function connectErrorEvent(error) {
      console.log("Socket error - erro de conexão:", error);
    }
    function reconnectAttemptEvent(attempt) {
      console.log(
        `Socket warn - o servido esta tentando ser reconectar. N de tentativas: ${attempt}`
      );
    }
    function reconnectErrorEvent(error) {
      console.log("Socket error - erro de reeconexão:", error);
    }
    function reconnectEvent(attempt) {
      console.log("Socket success: servidor reeconectado com sucesso");
    }

    socket.connect();
    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("connect_error", connectErrorEvent);
    socket.io.on("reconnect_attempt", reconnectAttemptEvent);
    socket.io.on("reconnect_error", reconnectErrorEvent);
    socket.io.on("reconnect", reconnectEvent);
    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off("connect_error", connectErrorEvent);
      socket.io.off("reconnect_attempt", reconnectAttemptEvent);
      socket.io.off("reconnect_error", reconnectErrorEvent);
      socket.io.off("reconnect", reconnectEvent);
      socket.disconnect();
    };
  }, []);

  //RECEBER CLIENTID DO LOCALSTORAGE
  const cliente =
    JSON.parse(localStorage.getItem("userCliente"))?.usuarioCliente || "";
  useEffect(() => {
    if (cliente) {
      findManyBlocos(cliente?.clienteId);
      findManyGrupos(cliente?.clienteId);
    }
  }, []);
  const findManyBlocos = async (clienteId) => {
    setLoadingTable(true);
    try {
      const response = await apiCliente.post("/findMany/blocosClient", {
        clienteId,
      });

      setBlocos(response?.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTable(false);
    }
  };

  ////////////////////////////////////////////////
  ///////////////// FUNÇÕES DA API////////////////
  ////////////////////////////////////////////////
  const findManyGrupos = async (clienteId) => {
    try {
      const response = await apiCliente.post(
        "/findMany/gruposBlocosFlowClient",
        {
          clienteId,
        }
      );
      setGrupos(response?.data);
    } catch (erro) {
      console.error(erro);
    } finally {
    }
  };
  const criarGrupo = async (grupoName) => {
    let min = Math.ceil(0);
    let max = Math.floor(6);
    let indexColorChip = Math.floor(Math.random() * (max - min)) + min;

    if (grupoName.trim() === "") {
      setSnackbar({
        children: "Necessário informar o nome do grupo",
        severity: "info",
      });
    } else {
      try {
        const response = await apiCliente.post(
          "/create/gruposBlocosFlowClient",
          {
            clienteId: cliente?.clienteId,
            blocoName: grupoName?.trim(),
            colorGrupo: colorChip[indexColorChip],
          }
        );

        setSnackbar({
          children: "Grupo criado com sucesso",
          severity: "success",
        });
        setGrupos((grupos) => [...grupos, response?.data]);
      } catch (error) {
        console.error(error);
        setSnackbar({
          children: "Erro ao criar grupo",
          severity: "error",
        });
      } finally {
      }
    }
  };
  const editarGrupo = async () => {
    if (handleGrupo?.grupoName?.trim()) {
      if (
        grupos?.some(
          (grupo) =>
            grupo.grupoName.trim() === handleGrupo?.grupoName.trim() &&
            grupo?.id !== handleGrupo?.id
        )
      ) {
        setGrupoExist(true);
      } else {
        setGrupoExist(false);
        try {
          const response = await apiCliente.post(
            "/update/gruposBlocosFlowClient",
            {
              grupoId: handleGrupo?.id,
              grupoName: handleGrupo?.grupoName?.trim(),
              colorGrupo: handleGrupo?.colorGrupo,
            }
          );
          const newGrupos = grupos?.map((grupo) =>
            grupo?.id === response?.data?.id ? response?.data : grupo
          );
          setGrupos(newGrupos);
          setGruposRow((gruposRow) =>
            gruposRow?.map((grupoRow) =>
              grupoRow?.id === response?.data?.id ? response?.data : grupoRow
            )
          );

          setSnackbar({
            children: "Grupo editado com sucesso",
            severity: "success",
          });
        } catch (error) {
          console.error(error);
          setSnackbar({
            children: "Erro ao editar grupo",
            severity: "error",
          });
        } finally {
          handleClose();
        }
      }
    }
  };
  const addGrupoBloco = async (blocoId, grupoId) => {
    setLoadingHandleGrupo(true);
    try {
      socket.emit(
        "add_RlGrupo",
        {
          blocoId: blocoId,
          grupoId: grupoId?.id,
        },
        (response) => {
          if (response?.data) {
            setGruposRow((grupoRow) => [...grupoRow, grupoId]);
            setBlocos((blocos) =>
              blocos?.map((bloco) =>
                bloco?.id === response?.data?.blocoId
                  ? {
                      ...bloco,
                      RlGruposBlocosFlowClient: [
                        ...bloco?.RlGruposBlocosFlowClient,
                        response?.data,
                      ],
                    }
                  : bloco
              )
            );

            setSnackbar({
              children: "Status do bloco atualizado",
              severity: "success",
            });
          } else {
            setSnackbar({
              children: "Error: Não foi atualizar status do bloco",
              severity: "error",
            });
          }
        }
      );

      setSelectBloco([]);

      setSnackbar({
        children: "Adicionado blocos ao grupo",
        severity: "success",
      });
      setBuscarGrupo("");
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possível atualizar o grupo",
        severity: "error",
      });
      console.error(error);
    } finally {
      setLoadingHandleGrupo(false);
      handleCloseMenuGrupos();
    }
  };
  const deleteBlocosGrupos = async (blocoId, grupoId) => {
    setLoadingHandleGrupo(true);
    setLoadingRemoveGrupo(true);
    try {
      socket.emit(
        "remove_RlGrupo",
        {
          blocoId: blocoId,
          grupoId: grupoId?.id,
        },
        (response) => {
          if (response?.data) {
            setGruposRow((grupoRow) =>
              grupoRow?.filter((grupo) => grupo?.id !== grupoId?.id)
            );

            setBlocos((blocos) =>
              blocos?.map((bloco) =>
                bloco?.id === blocoId
                  ? {
                      ...bloco,
                      RlGruposBlocosFlowClient:
                        bloco?.RlGruposBlocosFlowClient?.filter(
                          (grupo) => grupo?.grupoId !== grupoId?.id
                        ),
                    }
                  : bloco
              )
            );

            setSnackbar({
              children: "Blocos retirados do grupo",
              severity: "success",
            });
          } else {
            setSnackbar({
              children: "Error: Não foi atualizar status do bloco",
              severity: "error",
            });
          }
        }
      );
      setBuscarGrupo("");
      setSelectBloco([]);
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Erro: Não foi possível retirar blocos do grupo",
        severity: "error",
      });
    } finally {
      setLoadingHandleGrupo(false);
      setOpenRemoveModalGrupo(false);
      setBlocoId("");
      setGrupoId("");
      setLoadingRemoveGrupo(false);
    }
  };
  const deleteGrupo = async () => {
    setLoadingHandleGrupo(true);

    try {
      const response = await apiCliente.post("/delete/grupoBlocosFlowClient", {
        grupoId: idGrupoDelete,
      });
      const newGrupos = grupos?.filter((grupo) => grupo?.id !== idGrupoDelete);
      setGrupos(newGrupos);
      setGruposRow((gruposRow) =>
        gruposRow?.filter((grupoRow) => grupoRow?.id !== idGrupoDelete)
      );
      setIdGrupoDelete("");
      setSnackbar({
        children: "Grupo deletado",
        severity: "success",
      });
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possível deletar o grupo",
        severity: "error",
      });
    } finally {
      setLoadingHandleGrupo(false);
      setOpenDeleteModalGrupo(false);
      handleClose();
    }
  };
  const deleteManyBlocosGrupos = async (blocosId) => {
    setLoadingHandleGrupo(true);
    setLoadingRemoveGrupo(true);
    try {
      const response = await apiCliente.post(
        "/deleteMany/rlGrupoBlocosFlowClient",
        {
          blocosId: blocosId,
        }
      );

      setBlocos((blocos) =>
        blocos?.map((bloco) =>
          bloco?.id === blocoId
            ? {
                ...bloco,
                RlGruposBlocosFlowClient:
                  bloco?.RlGruposBlocosFlowClient?.filter(
                    (grupo) => grupo?.grupoId !== grupoId
                  ),
              }
            : bloco
        )
      );

      setBlocos((blocos) =>
        blocos?.map((bloco) => {
          const blocoAtualizado = blocosId?.find((id) => id === bloco?.id);

          if (blocoAtualizado) {
            return {
              ...bloco,
              RlGruposBlocosFlowClient: [],
            };
          }

          return bloco;
        })
      );

      setSelectBloco([]);
      setSnackbar({
        children: "Blocos retirados do grupo",
        severity: "success",
      });
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Erro: Não foi possível retirar blocos do grupo",
        severity: "error",
      });
    } finally {
      setLoadingHandleGrupo(false);
      setOpenRemoveModalGrupo(false);
      setBlocoId("");
      setGrupoId("");
      handleCloseMenuGrupos();
      setOpenRemoveManyModalGrupo(false);
      setLoadingRemoveGrupo(false);
    }
  };
  const updateStatusManyBlocos = async (status) => {
    setLoadingManyStataus(true);
    try {
      const response = await apiCliente.post(
        "/updateMany/statusBlocosFlowClient",
        {
          blocosId: selectBloco,
          status: status,
        }
      );
      Array.isArray(response?.data) &&
        response?.data?.map((blocoResponse) => {
          setBlocos((blocos) =>
            blocos?.map((bloco) =>
              bloco?.id === blocoResponse?.blocoId
                ? { ...bloco, statusBlocoClient: blocoResponse.status }
                : bloco
            )
          );
        });
      setSelectBloco([]);

      setSnackbar({
        children: "Status do bloco atualizado",
        severity: "success",
      });
    } catch {
      setSnackbar({
        children: "Erro ao atualizar status do bloco",
        severity: "error",
      });
    } finally {
      handleCloseMenuStatus();
      setLoadingManyStataus(false);
    }
  };

  const addManyBlocosGrupos = async (blocosId, grupoId) => {
    setLoadingHandleGrupo(true);
    try {
      const response = await apiCliente.post(
        "/createMany/rlGrupoBlocosFlowClient",
        {
          blocosId: blocosId,
          grupoId: grupoId,
        }
      );
      setBlocos((blocos) =>
        blocos?.map((bloco) => {
          const blocoAtualizado = response?.data?.find(
            (responseBloco) => responseBloco?.blocoId === bloco?.id
          );

          if (blocoAtualizado) {
            return {
              ...bloco,
              RlGruposBlocosFlowClient: [
                ...bloco?.RlGruposBlocosFlowClient,
                blocoAtualizado,
              ],
            };
          }

          return bloco;
        })
      );

      setSnackbar({
        children: "Adicionado blocos ao grupo",
        severity: "success",
      });
      setSelectBloco([]);
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possível atualizar o grupo",
        severity: "error",
      });
    } finally {
      setLoadingHandleGrupo(false);
      handleCloseMenuGrupos();
    }
  };
  ////////////////////////////////////////////////////////

  ////////////////////////////////////////////////
  ///////////////// FUNÇÕES PARA ABRIR MENU///////
  ////////////////////////////////////////////////
  const handleClickOptionsGrupo = (event) => {
    setAnchorElOptionsGrupo(event?.currentTarget);
  };
  const handleCloseMenuGrupos = () => {
    setAnchorElGrupos(null);
  };
  const handleCloseOptionsGrupo = () => {
    setAnchorElOptionsGrupo(null);
  };

  const handleClickMenuGrupos = (event) => {
    setAnchorElGrupos(event.currentTarget);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenuStatus = () => {
    setAnchorElStatus(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setGrupoExist(false);
  };

  //FILTRO DO AUTOCOMPLETE
  const filter = createFilterOptions();

  //FILTRO DE BUSCAR BLOCOS
  const filteredBlocos = blocos?.filter((row, index) => {
    row.numero = index;
    const gruposFilter = grupos
      ?.filter((grupo) =>
        row?.RlGruposBlocosFlowClient?.some(
          (rlGrupo) => rlGrupo?.grupoId === grupo?.id
        )
      )
      ?.map((grupoRow) => grupoRow?.grupoName);

    return (
      row?.blocos?.toLowerCase()?.includes(buscar?.toLowerCase()) ||
      row?.as?.toString()?.toLowerCase()?.includes(buscar?.toLowerCase()) ||
      gruposFilter.join()?.toLowerCase()?.includes(buscar?.toLowerCase())
    );
  });

  const columns = [
    {
      field: "as",
      headerName: "AS",
      flex: 1,
      valueGetter: (params) => {
        return !params ? "Não identificado" : params;
      },
    },

    {
      field: "blocos",
      headerName: "Blocos",
      flex: 1,
    },

    {
      field: "grupo",
      headerName: "Grupo",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        let idGrupo = params?.row?.RlGruposBlocosFlowClient?.map(
          (idGrup) => idGrup?.grupoId
        );
        let grupo = grupos?.filter((grupo) =>
          idGrupo?.some((id) => grupo?.id === id)
        );

        let thisRow = params?.row;

        return (
          <>
            <Button
              onClick={(event) => {
                handleClickOptionsGrupo(event);
                setGruposRow(grupo);
                setThisRowGrupo(thisRow);
              }}
              sx={styles.buttonGrupo}
              endIcon={<ArrowDropDownOutlined />}
            >
              <Box sx={styles?.containerDatagridGrupos}>
                {grupo?.length > 0 ? (
                  grupo?.map((grupo) => (
                    <>
                      <Chip
                        size="small"
                        sx={{
                          ...styles.chipGrupo,
                          bgcolor: grupo?.colorGrupo,
                        }}
                        label={grupo?.grupoName}
                      />
                    </>
                  ))
                ) : (
                  <Box sx={styles.containerNoGrupo}>
                    <Typography sx={styles.textNoGrupo}>
                      Adicione ou crie um grupo
                    </Typography>
                  </Box>
                )}
              </Box>
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <Box sx={{ marginTop: "25px" }}>
      <Box sx={styles.boxContainer}>
        <Paper
          sx={styles.paperContainer}
          elevation={3}
          variant={theme.palette.mode === "dark" ? "outlined" : "elevation"}
        >
          <Box sx={styles.containerTitulo}>
            <Typography sx={{ fontSize: "21px" }}>Blocos</Typography>
          </Box>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <TextField
              variant="filled"
              label="Buscar blocos"
              placeholder="Nome, Blocos, etc ..."
              value={buscar}
              size="small"
              sx={{ width: "100%", maxWidth: "250px" }}
              onChange={(event) => {
                setBuscar(event.target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
                endAdornment: buscar ? (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setBuscar("")} edge="end">
                      <Clear />
                    </IconButton>
                  </InputAdornment>
                ) : null,
              }}
            />
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "end",
              }}
            >
              <Box sx={styles.containerAcoes}>
                {selectBloco?.length > 0 && (
                  <Box sx={styles.containerAcoesButton}>
                    <Button
                      variant="filled"
                      startIcon={<MoreVertOutlined />}
                      onClick={handleClickMenuGrupos}
                    >
                      Grupos
                    </Button>

                    <Menu
                      anchorEl={anchorElGrupos}
                      open={openMenuGrupos}
                      onClose={handleCloseMenuGrupos}
                    >
                      <Box sx={{ maxHeight: "300px" }}>
                        {grupos?.map((grupo) => (
                          <MenuItem
                            onClick={() =>
                              addManyBlocosGrupos(selectBloco, grupo?.id)
                            }
                          >
                            <ListItemIcon>
                              <Add />
                            </ListItemIcon>
                            <ListItemText>
                              {" "}
                              Adicionar à {grupo?.grupoName}
                            </ListItemText>
                          </MenuItem>
                        ))}
                        <MenuItem
                          onClick={() => setOpenRemoveManyModalGrupo(true)}
                        >
                          <ListItemIcon>
                            <DoNotDisturbAltOutlined />
                          </ListItemIcon>
                          <Typography sx={{ fontWeight: 500 }}>
                            {" "}
                            Remover de todos os grupos
                          </Typography>
                        </MenuItem>
                      </Box>
                    </Menu>
                  </Box>
                )}
              </Box>

              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                transformOrigin={{ horizontal: "center", vertical: "top" }}
                anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
              >
                <Box>
                  <TextField
                    value={handleGrupo?.grupoName}
                    size="small"
                    sx={{ margin: "10px" }}
                    onChange={(e) => {
                      e.stopPropagation();
                      setHandleGrupo((prev) => ({
                        ...prev,
                        grupoName: e.target.value,
                      }));
                    }}
                    error={grupoExist || !handleGrupo?.grupoName?.trim()}
                    helperText={
                      (grupoExist && "Grupo já existe") ||
                      (!handleGrupo?.grupoName?.trim() && "Nome obrigatório")
                    }
                  />
                </Box>
                <Box>
                  <Divider />
                  <Button
                    startIcon={<Delete sx={{ marginLeft: "25px" }} />}
                    sx={{
                      ...styles.buttonAddGrupo,
                      justifyContent: "start",
                      padding: "10px 0",
                    }}
                    onClick={() => setOpenDeleteModalGrupo(true)}
                  >
                    Deletar grupo
                  </Button>
                  <Divider />
                </Box>
                <Typography sx={styles.textNoGrupo}>Cor</Typography>
                <Box>
                  {colorChip?.map((color, index) => (
                    <MenuItem
                      onClick={() =>
                        setHandleGrupo((prev) => ({
                          ...prev,
                          colorGrupo: colorChip[index],
                        }))
                      }
                    >
                      <Box
                        sx={{
                          ...styles.colorSelectChip,
                          backgroundColor: color,
                        }}
                      ></Box>
                      <Typography>
                        {index === 0
                          ? "Vermelho"
                          : index === 1
                          ? "Verde"
                          : index === 2
                          ? "Laranja"
                          : index === 3
                          ? "Azul"
                          : index === 4
                          ? "Roxo"
                          : index === 5
                          ? "Cinza"
                          : "Cinza"}
                      </Typography>
                      {handleGrupo.colorGrupo === colorChip[index] && (
                        <ListItemIcon sx={{ marginLeft: "auto" }}>
                          <Check />
                        </ListItemIcon>
                      )}
                    </MenuItem>
                  ))}
                </Box>
                <Box sx={styles.containerHandleButtons}>
                  <Button
                    onClick={handleClose}
                    size={"small"}
                    sx={styles.buttonHandle}
                  >
                    Cancelar
                  </Button>
                  <Button
                    size={"small"}
                    variant="contained"
                    sx={styles.buttonHandle}
                    onClick={editarGrupo}
                  >
                    Salvar
                  </Button>
                </Box>
              </Menu>

              <Menu
                id="basic-menu"
                anchorEl={anchorElOptionsGrupo}
                open={openMenuOptionsGrupo}
                onClose={() => {
                  handleCloseOptionsGrupo();
                  setBuscarGrupo("");
                }}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                sx={{ width: "450px" }}
              >
                <Autocomplete
                  freeSolo
                  size="small"
                  inputValue={buscarGrupo}
                  onInputChange={(event, value) => setBuscarGrupo(value)}
                  clearIcon={null}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    const { inputValue } = params;
                    setBuscarGrupo(inputValue);
                    return [];
                  }}
                  disableCloseOnSelect
                  disabled={loadingRemoveGrupo}
                  multiple
                  onKeyDown={(event) => {
                    event.stopPropagation();
                  }}
                  options={[]}
                  value={gruposRow ? gruposRow : []}
                  getOptionLabel={(option) => option?.grupoName}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => {
                      const { key, onDelete, ...tagProps } = getTagProps({
                        index,
                      });

                      return (
                        <Chip
                          sx={{ ...styles.chip, bgcolor: option?.colorGrupo }}
                          key={key}
                          label={option.grupoName}
                          {...tagProps}
                          onDelete={() => {
                            setBlocoId(thisRowGrupo?.id);
                            setGrupoId(option);
                            setOpenRemoveModalGrupo(true);
                          }}
                        />
                      );
                    })
                  }
                  style={{ width: 280, margin: "0 auto" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={
                        gruposRow?.length !== 0
                          ? ""
                          : "Adicione ou crie um grupo"
                      }
                    />
                  )}
                />

                <Box sx={styles.containerGrupo}>
                  {filteredRows?.map((grupo) => (
                    <Box style={{ width: 300 }}>
                      <MenuItem sx={{ cursor: "default" }}>
                        <Checkbox
                          disabled={loadingHandleGrupo}
                          icon={icon}
                          checkedIcon={checkedIcon}
                          checked={gruposRow?.some(
                            (grupoRow) => grupoRow?.id === grupo?.id
                          )}
                          style={{ marginRight: 8 }}
                          onChange={(e) => {
                            if (e.target.checked === true) {
                              addGrupoBloco(thisRowGrupo?.id, grupo);
                            } else {
                              deleteBlocosGrupos(thisRowGrupo?.id, grupo);
                            }
                          }}
                        />
                        <Chip
                          size="small"
                          label={grupo?.grupoName}
                          sx={{
                            ...styles.chipGrupo,
                            bgcolor: grupo?.colorGrupo,
                          }}
                        />

                        <IconButton
                          onKeyDown={(event) => {
                            event.stopPropagation();
                          }}
                          onClick={(event) => {
                            handleClick(event);
                            setIdGrupoDelete(grupo?.id);
                            setHandleGrupo(grupo);

                            event.preventDefault();
                          }}
                          sx={{ marginLeft: "auto" }}
                        >
                          <MoreHoriz fontSize="small" />
                        </IconButton>
                      </MenuItem>
                    </Box>
                  ))}
                </Box>
                {buscarGrupo &&
                  (!filteredRows?.some(
                    (filter) => filter?.grupoName === buscarGrupo
                  ) ||
                    filteredRows?.length === 0) && (
                    <MenuItem
                      style={{ width: 300 }}
                      onClick={(e) => {
                        criarGrupo(buscarGrupo);
                        e.stopPropagation();
                      }}
                    >
                      <Typography
                        style={{ ...styles.grupoName, marginLeft: "20px" }}
                      >
                        Criar "{buscarGrupo}"
                      </Typography>
                    </MenuItem>
                  )}
              </Menu>
            </Box>
          </Stack>

          <DataGrid
            getRowHeight={() => "auto"}
            onCellKeyDown={(params, event) => event.stopPropagation()}
            checkboxSelection
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setSelectBloco(newRowSelectionModel);
            }}
            isRowSelectable={() => !loadingManyStatus}
            rowSelectionModel={selectBloco}
            sx={styles.datagrid}
            autoHeight={true}
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            rows={filteredBlocos}
            getRowId={(row) => row.id}
            columns={columns}
            loading={loadingTable}
            disableColumnMenu
            disableRowSelectionOnClick={true}
            rowsPerPageOptions={[10, 25, 50]}
            pageSizeOptions={[10, 25, 50]}
            initialState={{
              pagination: {
                paginationModel: { page: 1, pageSize: 10 },
              },
              sorting: {
                sortModel: [{ field: "checkDeleteDate", sort: "desc" }],
              },
            }}
          />
        </Paper>
      </Box>

      <Menu
        anchorEl={anchorElStatus}
        open={openMenuStatus}
        onClose={handleCloseMenuStatus}
      >
        <MenuItem onClick={() => updateStatusManyBlocos(true)}>
          <ListItemIcon>
            <CheckCircleOutlineOutlined />
          </ListItemIcon>
          <ListItemText>
            {" "}
            Ativar bloco
            {selectBloco?.length > 1 ? "s" : ""}
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={() => updateStatusManyBlocos(false)}>
          <ListItemIcon>
            <DoNotDisturbAltOutlined />
          </ListItemIcon>
          <ListItemText>
            {" "}
            Desativar bloco
            {selectBloco?.length > 1 ? "s" : ""}
          </ListItemText>
        </MenuItem>
      </Menu>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: "center", vertical: "top" }}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      >
        <Box>
          <TextField
            value={handleGrupo?.grupoName}
            size="small"
            sx={{ margin: "10px" }}
            onChange={(e) => {
              e.stopPropagation();
              setHandleGrupo((prev) => ({
                ...prev,
                grupoName: e.target.value,
              }));
            }}
            error={grupoExist || !handleGrupo?.grupoName?.trim()}
            helperText={
              (grupoExist && "Grupo já existe") ||
              (!handleGrupo?.grupoName?.trim() && "Nome obrigatório")
            }
          />
        </Box>
        <Box>
          <Divider />
          <Button
            startIcon={<Delete sx={{ marginLeft: "25px" }} />}
            sx={{
              ...styles.buttonAddGrupo,
              justifyContent: "start",
              padding: "10px 0",
            }}
            onClick={() => setOpenDeleteModalGrupo(true)}
          >
            Deletar grupo
          </Button>
          <Divider />
        </Box>
        <Typography sx={styles.textNoGrupo}>Cor</Typography>
        <Box>
          {colorChip?.map((color, index) => (
            <MenuItem
              onClick={() =>
                setHandleGrupo((prev) => ({
                  ...prev,
                  colorGrupo: colorChip[index],
                }))
              }
            >
              <Box
                sx={{
                  ...styles.colorSelectChip,
                  backgroundColor: color,
                }}
              ></Box>
              <Typography>
                {index === 0
                  ? "Vermelho"
                  : index === 1
                  ? "Verde"
                  : index === 2
                  ? "Laranja"
                  : index === 3
                  ? "Azul"
                  : index === 4
                  ? "Roxo"
                  : index === 5
                  ? "Cinza"
                  : "Cinza"}
              </Typography>
              {handleGrupo.colorGrupo === colorChip[index] && (
                <ListItemIcon sx={{ marginLeft: "auto" }}>
                  <Check />
                </ListItemIcon>
              )}
            </MenuItem>
          ))}
        </Box>
        <Box sx={styles.containerHandleButtons}>
          <Button onClick={handleClose} size={"small"} sx={styles.buttonHandle}>
            Cancelar
          </Button>
          <Button
            size={"small"}
            variant="contained"
            sx={styles.buttonHandle}
            onClick={editarGrupo}
          >
            Salvar
          </Button>
        </Box>
      </Menu>
      <Menu
        id="basic-menu"
        anchorEl={anchorElOptionsGrupo}
        open={openMenuOptionsGrupo}
        onClose={() => {
          handleCloseOptionsGrupo();
          setBuscarGrupo("");
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{ width: "450px" }}
      >
        <Autocomplete
          freeSolo
          size="small"
          inputValue={buscarGrupo}
          onInputChange={(event, value) => setBuscarGrupo(value)}
          clearIcon={null}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            const { inputValue } = params;
            setBuscarGrupo(inputValue);
            return [];
          }}
          disableCloseOnSelect
          disabled={loadingRemoveGrupo}
          multiple
          onKeyDown={(event) => {
            event.stopPropagation();
            if (
              event.key === "Enter" &&
              buscarGrupo &&
              (!filteredRows?.some(
                (filter) => filter?.grupoName === buscarGrupo
              ) ||
                filteredRows?.length === 0)
            ) {
              criarGrupo(buscarGrupo);
            }
          }}
          options={[]}
          value={gruposRow ? gruposRow : []}
          getOptionLabel={(option) => option?.grupoName}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => {
              const { key, onDelete, ...tagProps } = getTagProps({
                index,
              });

              return (
                <Chip
                  sx={{ ...styles.chip, bgcolor: option?.colorGrupo }}
                  key={key}
                  label={option.grupoName}
                  {...tagProps}
                  onDelete={() => {
                    setBlocoId(thisRowGrupo?.id);
                    setGrupoId(option);
                    setOpenRemoveModalGrupo(true);
                  }}
                />
              );
            })
          }
          style={{ width: 280, margin: "0 auto" }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={
                gruposRow?.length !== 0 ? "" : "Adicione ou crie um grupo"
              }
            />
          )}
        />

        <Box>
          <Divider sx={{ margin: "10px 0" }} />

          <Typography sx={styles.textNoGrupo}>
            Selecione uma opção ou crie uma{" "}
          </Typography>
        </Box>
        <Box sx={styles.containerGrupo}>
          {filteredRows?.map((grupo) => (
            <Box style={{ width: 300 }}>
              <MenuItem
                onClick={(e) => {
                  if (
                    !gruposRow?.some((grupoRow) => grupoRow?.id === grupo?.id)
                  ) {
                    addGrupoBloco(thisRowGrupo?.id, grupo);
                  } else {
                    deleteBlocosGrupos(thisRowGrupo?.id, grupo);
                  }

                  e.stopPropagation();
                }}
              >
                <Checkbox
                  disabled={loadingHandleGrupo}
                  icon={icon}
                  checkedIcon={checkedIcon}
                  checked={gruposRow?.some(
                    (grupoRow) => grupoRow?.id === grupo?.id
                  )}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  style={{ marginRight: 8 }}
                  onChange={(e) => {
                    e.stopPropagation();
                    if (e.target.checked === true) {
                      addGrupoBloco(thisRowGrupo?.id, grupo);
                    } else {
                      deleteBlocosGrupos(thisRowGrupo?.id, grupo);
                    }
                  }}
                />
                <Chip
                  size="small"
                  label={grupo?.grupoName}
                  sx={{
                    ...styles.chipGrupo,
                    bgcolor: grupo?.colorGrupo,
                  }}
                />

                <IconButton
                  onKeyDown={(event) => {
                    event.stopPropagation();
                  }}
                  onClick={(event) => {
                    handleClick(event);
                    setIdGrupoDelete(grupo?.id);
                    setHandleGrupo(grupo);
                    event.preventDefault();
                    event.stopPropagation();
                  }}
                  sx={{ marginLeft: "auto" }}
                >
                  <MoreHoriz fontSize="small" />
                </IconButton>
              </MenuItem>
            </Box>
          ))}
        </Box>
        {buscarGrupo &&
          (!filteredRows?.some((filter) => filter?.grupoName === buscarGrupo) ||
            filteredRows?.length === 0) && (
            <MenuItem
              style={{ width: 300 }}
              onClick={(e) => {
                criarGrupo(buscarGrupo);
                e.stopPropagation();
              }}
            >
              <Typography style={{ ...styles.grupoName, marginLeft: "20px" }}>
                Criar "{buscarGrupo}"
              </Typography>
            </MenuItem>
          )}
      </Menu>
      <InternoModalDeleteCliente
        openDeleteModal={openDeleteModalGrupo}
        load={loadingHandleGrupo}
        setOpenDeleteModal={setOpenDeleteModalGrupo}
        execute={deleteGrupo}
        severity={"error"}
        contentText={deleteBody}
        confirmText={confirmDelete}
      />
      <InternoModalDeleteCliente
        openDeleteModal={openRemoveManyModalGrupo}
        load={loadingRemoveGrupo}
        setOpenDeleteModal={setOpenRemoveManyModalGrupo}
        execute={() => deleteManyBlocosGrupos(selectBloco)}
        severity={"error"}
        contentText={deleteBodyManyGrupos}
        confirmText={confirmRemoveGrupo}
      />
      <InternoModalDeleteCliente
        openDeleteModal={openRemoveModalGrupo}
        load={loadingRemoveGrupo}
        setOpenDeleteModal={setOpenRemoveModalGrupo}
        execute={() => deleteBlocosGrupos(blocoId, grupoId)}
        severity={"error"}
        contentText={deleteBodyRemoveGrupo}
        confirmText={confirmRemoveGrupo}
      />

      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </Box>
  );
}
