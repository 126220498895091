import { hover } from "@testing-library/user-event/dist/hover";

const styles = (props) => ({
  appBar: { top: 0, display: "flex" },
  toolbar: {
    width: "100%",
    height: 80,
    display: "flex",
    justifyContent: "space-between",
  },
  toolbarNavigate: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 3,
  },
  logo: {
    width: "200px",
    height: "auto",
    marginTop: " 7px",
  },
  imgLogo: { width: "200px", height: "auto" },
  IconButtonLogo: {
    padding: 0,
    borderRadius: 0,
    width: "220px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  topContent: { height: "80px", width: "99vw" },
  lastItemBreadcrumbs: {
    color: "text.primary",
    textTransform: "capitalize",
  },
  itemBreadcrumbs: {
    textTransform: "capitalize",
  },
  Breadcrumbs: {
    marginBottom: "30px",
    marginTop: "-10px",
  },
  topContent: { height: "80px", width: "98vw" },
  main: {
    flexGrow: 1,
    p: 6,
    paddingX: 8,
    borderTopLeftRadius: 8,
  },
  tab: {
    minWidth: "60px",
    height: "100%",
    maxWidth: "200px",
    justifyContent: "start",
    color: "#FFFFFF",
    opacity: 0.9,
    fontSize: "0.875 rem",
    "&:hover": {
      backgroundColor: "#00000021",
    },
  },
  menuItem: {
    minWidth: "220px",
    display: "flex",
    alignItems: "center",
  },
  containerNotificacoes: {
    width: "89%",
    display: "flex",
    flexDirection: "column",
  },
  iconLink: { marginLeft: "10px" },

  textClientNotificacoes: {
    fontWeight: "bold",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  linkNotificacao: {
    "&:hover": {
      textDecoration: "none",
    },
  },
  containerMenuNotificacoes: {
    maxHeight: "300px",
    maxWidth: "300px",
    overflow: "auto",
  },
  badgeColor: {
    "& .MuiBadge-badge": {
      fontSize: 9,
      height: 15,
      minWidth: 15,
      backgroundColor: "#ffffff",
      color: "#000000",
    },
  },
});

export default styles;
