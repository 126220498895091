import {
  Box,
  Button,
  Chip,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
  ListItem,
  InputAdornment,
  Divider,
  List,
  Pagination,
  Snackbar,
  Alert,
  useTheme,
  Badge,
  createSvgIcon,
  Fab,
} from "@mui/material";

import { useEffect, useState } from "react";
import useStyles from "./styles";
import { ReactComponent as EasyFlow } from "../../../assets/icons/IconEasyFlow.svg";
import {
  Add,
  Search,
  Clear,
  SettingsOutlined,
  MoreVert,
  PlayArrow,
  Edit,
  Delete,
  Person,
  OpenInNewOutlined,
  NotificationImportantOutlined,
  AttachMoneyOutlined,
  NotInterestedOutlined,
  FilterAltOutlined,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import ModalEditCadastro from "../../../components/modalEditCadastro";
import InternoModalDeleteCliente from "../../../components/internoModalDeleteCliente/index";
import { DataGrid } from "@mui/x-data-grid";
import CadastroCliente from "../forms/cadastro";
import api from "../../../services/api";
const EasyFlowIcon = createSvgIcon(<EasyFlow />);
function usePagination(data, itemsPerPage) {
  const [currentPage, setCurrentPage] = useState(1);
  const maxPage = Math.ceil(data.length / itemsPerPage);

  function currentData() {
    const begin = (currentPage - 1) * itemsPerPage;
    const end = begin + itemsPerPage;
    return data.slice(begin, end);
  }

  function next() {
    setCurrentPage((currentPage) => Math.min(currentPage + 1, maxPage));
  }

  function prev() {
    setCurrentPage((currentPage) => Math.max(currentPage - 1, 1));
  }

  function jump(page) {
    const pageNumber = Math.max(1, page);
    setCurrentPage((currentPage) => Math.min(pageNumber, maxPage));
  }

  return { next, prev, jump, currentData, currentPage, maxPage };
}

export default function Home() {
  const [snackbar, setSnackbar] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [rows, setRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [openEditModal, setOpenEditModal] = useState(false);
  const [windowWith, setWindowWith] = useState(window.innerWidth);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [anchorNotificacao, setAnchorNotificacao] = useState(null);
  const openNotificacao = Boolean(anchorNotificacao);
  const [pendentes, setPendentes] = useState([]);
  const [page, setPage] = useState(1);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openDesativacao, setOPenDesativacao] = useState(false);
  const [statusCliente, setStatusCLiente] = useState(null);
  const [deleteLoad, setDeleteLoad] = useState(false);
  const logs = JSON.parse(localStorage.getItem("logs"));

  // conteudo a ser passado para o modal delete
  // tipos de modal detele 1: error, 2: warn, 3: info, 4: success
  const deleteBody =
    "Se você prosseguir com a operação, o item e todas as informações associadas serão excluídas permanentemente!";
  const confirmDelete = "EXCLUIR";
  const confirmDesativar = "Desativar";
  const desativarBody =
    "Se você prosseguir com a operação, o cliente e seus usuários serão desativados.";
  const [buscarUsuario, setBuscarUsuario] = useState("");

  const theme = useTheme();

  const status = {
    0: "Ativo",
    1: "Desativado",
  };
  const filteredRows = rows?.filter((row, index) => {
    row.numero = index;

    const statusNome = status[row.status]?.toLowerCase();

    return (
      row.nomeFantasia
        ?.toLowerCase()
        ?.includes(buscarUsuario?.toLowerCase()?.trim()) ||
      row.as?.toLowerCase()?.includes(buscarUsuario?.toLowerCase()?.trim()) ||
      statusNome
        ?.toLowerCase()
        ?.includes(buscarUsuario?.toLowerCase()?.trim()) ||
      row.ClientesCadastro?.email
        ?.toLowerCase()
        ?.includes(buscarUsuario?.toLowerCase()?.trim())
    );
  });

  useEffect(() => {
    handleApiGetClientes();
  }, []);

  async function handleApiGetClientes() {
    try {
      const response = await api.get("/cliente/home/getClient");

      setRows(response.data);
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possivel se conectar ao servidor",
        severity: "error",
      });
    } finally {
    }
  }

  const navigate = useNavigate();

  function removeRow(id) {
    let newArray = [...rows];
    newArray = newArray.filter((row) => row.id !== id);
    setRows(newArray);
  }
  async function handleApiDeleteClientes(id) {
    setDeleteLoad(true);

    try {
      await api.delete("/cliente/dashHome/deleteClient", {
        data: {
          id: id,
          usuarioId: logs?.usuarioId,
          logsLoginId: logs?.id,
        },
      });

      setSnackbar({
        children: "Cliente deletado com sucesso!",
        severity: "success",
      });

      setAnchorEl(null);
      removeRow(id);
      setOpenDeleteModal(false);
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possivel se conectar ao servidor",
        severity: "error",
      });
    } finally {
      setDeleteLoad(false);
    }
  }

  async function updateStatusCliente(clienteId, status) {
    try {
      const response = await api.post("/update/status/cliente", {
        clienteId,
        status,
      });

      let newArray = rows.map((cliente) => {
        if (cliente?.id === response?.data?.id) {
          return { ...cliente, status: response?.data?.status };
        }
        return cliente;
      });
      setRows(newArray);
    } catch (error) {
      console.error(error);
    } finally {
      setStatusCLiente(null);
      setOPenDesativacao(false);
    }
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (selectedRow) => {
    setAnchorEl(null);
    setAnchorNotificacao(null);
    setPendentes([]);
  };

  const PER_PAGE = 10;

  const count = Math.ceil(filteredRows?.length / PER_PAGE);
  const _DATA = usePagination(filteredRows, PER_PAGE);

  const handleChangePage = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  //console.log("paginas", rows.length / 10);
  /*   const filteredRows = rows.filter(
    (row) =>
      row.cliente?.toLowerCase().includes(filterValue.toLowerCase()) ||
      row.asn?.toLowerCase().includes(filterValue.toLowerCase()) ||
      row.email?.toLowerCase().includes(filterValue.toLowerCase())
  );
 */
  // TODO: Update the filtered rows in the data grid

  /*<TextField
    label={"Pesquisar"}
    variant="filled"
    placeholder="Nome, Email, ASN, etc..."
    autoComplete="off"
    focused
    sx={{ bgcolor: "red" }}
    onChange={(event) => {
      setFilterValue(event.target.value);
    }}
  />;*/

  useEffect(() => {
    const handleResize = () => {
      setWindowWith(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
  }, []);

  const styles = useStyles(theme);

  /*const getClients = async () => {
    try {
      const response = await axios.get(
        "http://localhost:3333/cliente/dashHome/getClient"
      );
    } catch {}
  };

  useEffect(() => {
    getClients();
  });*/

  function handleNewClient(newClient) {
    let newArray = [...rows];
    newArray.push(newClient);
    setRows(newArray);
  }

  function handleUpdatedClient(upDatedClient) {
    let newArray = rows.map((objeto) => {
      if (objeto.id === upDatedClient.id) {
        return upDatedClient;
      }
      return objeto;
    });
    setRows(newArray);
  }

  const columns = [
    { field: "id", headerName: "ID", width: 100, hide: true },
    {
      field: "nomeFantasia",
      headerName: "Clientes",
      flex: 1,
    },
    {
      field: "as",
      headerName: "ASN",
      width: 150,
    },
    {
      field: "ClientesCadastro",
      headerName: "Email",
      width: 250,
      valueGetter: (ClientesCadastro) => ClientesCadastro?.email,
    },

    {
      field: "status",
      headerName: "Status cliente",
      width: 220,
      renderCell: (params) => {
        const thisRow = params.row;
        return (
          <Chip
            label={thisRow.status === 0 ? "Ativo" : "Desativado"}
            sx={{
              bgcolor: thisRow.status === 0 ? "chip.completed" : "chip.pending",
              width: "100px",
              color: "#ffffff",
              fontWeight: 600,
            }}
            size="small"
          />
        );
      },
      valueGetter: (params) => {
        return params * -1;
      },
    },
    {
      field: "notificacoes",
      headerName: "Notificação",
      width: 130,

      renderCell: (params) => {
        let pendencias = 0;
        const onClickNotificacoes = (e) => {
          e.stopPropagation();
          const thisRow = params.row;
          setSelectedRow(thisRow);
          setAnchorNotificacao(e.currentTarget);

          if (params?.row?.contrato?.length > 1) {
            setPendentes((prev) => [
              ...prev,
              {
                message: `Existem ${params?.row?.contrato?.length} contratos ativos`,
                link: `/interno/home/perfil/financeiro/${params?.row?.id}`,
                icon: (
                  <AttachMoneyOutlined
                    fontSize="small"
                    sx={{ marginRight: "10px" }}
                  />
                ),
              },
            ]);
          } else if (params?.row?.contrato?.length === 0) {
            setPendentes((prev) => [
              ...prev,
              {
                message: `Cliente não possui contrato ativo`,
                link: `/interno/home/perfil/financeiro/${params?.row?.id}`,
                icon: (
                  <AttachMoneyOutlined
                    fontSize="small"
                    sx={{ marginRight: "10px" }}
                  />
                ),
              },
            ]);
          }
          if (params?.row?.blocoFlowDelete?.length > 0) {
            setPendentes((prev) => [
              ...prev,
              {
                message: `Bloco não localizado no Juniper`,
                link: `/interno/home/perfil/easyflow/${params?.row?.id}`,
                icon: (
                  <EasyFlowIcon fontSize="small" sx={{ marginRight: "10px" }} />
                ),
              },
            ]);
          }
        };
        if (params?.row?.status === 0) {
          if (params?.row?.contrato?.length > 1) {
            pendencias = pendencias + 1;
          } else if (params?.row?.contrato?.length === 0) {
            pendencias = pendencias + 1;
          }
          if (params?.row?.blocoFlowDelete?.length > 0) {
            pendencias = pendencias + 1;
          }
        }
        params.row.notification = pendencias || "";
        return (
          <Tooltip
            placement="right-end"
            title={`Notificações ${pendencias || ""} `}
          >
            <IconButton onClick={(e) => pendencias && onClickNotificacoes(e)}>
              <Badge badgeContent={pendencias} color="primary">
                <NotificationImportantOutlined />
              </Badge>
            </IconButton>
          </Tooltip>
        );
      },
      valueGetter: (params, row) => {
        let pendencias = 0;
        if (row?.status === 0) {
          if (row?.contrato?.length > 1 || row?.contrato?.length === 0) {
            pendencias = pendencias + 1;
          }
          if (row?.blocoFlowDelete?.length >= 1) {
            pendencias = pendencias + 1;
          }
        }

        return pendencias * -1;
      },
    },

    {
      field: "actions",
      headerName: "Opções",
      sortable: false,
      width: 80,

      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation();
          // don't select this row after clicking
          // console.log(params);
          const thisRow = params.row;
          setSelectedRow(thisRow);
          setAnchorEl(e.currentTarget);
        };

        //
        return (
          <Box key={params.id}>
            <Tooltip title={"Ações"} id="basic-button" onClick={onClick}>
              <IconButton size="small">
                <SettingsOutlined color="primary" />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  const notificacoesMobile = (cliente) => {
    let pendencias = 0;
    if (cliente.status === 0) {
      if (cliente?.contrato?.length > 1) {
        pendencias = pendencias + 1;
      } else if (cliente?.contrato?.length === 0) {
        pendencias = pendencias + 1;
      }
      if (cliente?.blocoFlowDelete?.length > 0) {
        pendencias = pendencias + 1;
      }
    }

    return pendencias;
  };
  const handleClickNotificacoesMobile = (e, cliente) => {
    setAnchorNotificacao(e.currentTarget);

    if (cliente?.contrato?.length > 1) {
      setPendentes((prev) => [
        ...prev,
        {
          message: `Existem ${cliente?.contrato?.length} contratos ativos`,
          link: `/interno/home/perfil/financeiro/${cliente?.id}`,
          icon: (
            <AttachMoneyOutlined
              fontSize="small"
              sx={{ marginRight: "10px" }}
            />
          ),
        },
      ]);
    } else if (cliente?.contrato?.length === 0) {
      setPendentes((prev) => [
        ...prev,
        {
          message: `Cliente não possui contrato ativo`,
          link: `/interno/home/perfil/financeiro/${cliente?.id}`,
          icon: (
            <AttachMoneyOutlined
              fontSize="small"
              sx={{ marginRight: "10px" }}
            />
          ),
        },
      ]);
    }
    if (cliente?.blocoFlowDelete?.length > 0) {
      setPendentes((prev) => [
        ...prev,
        {
          message: `Bloco não localizado no Juniper`,
          link: `/interno/home/perfil/easyflow/${cliente?.id}`,
          icon: <EasyFlowIcon fontSize="small" sx={{ marginRight: "10px" }} />,
        },
      ]);
    }
  };

  return (
    <Container sx={styles.container}>
      {/* <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/">
          Home
        </Link>
        <Link
          underline="hover"
          color="inherit"
          href="/material-ui/getting-started/installation/"
        >
          Perfil
        </Link>
      </Breadcrumbs> */}
      {/*<Stack justifyContent={"center"} direction={"row"}>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="basic tabs example"
          allowScrollButtonsMobile
        >
          <Tab
            label="Clientes"
            value={0}
            sx={{
              fontSize: "14px",
              "@media(max-width:640px)": { fontSize: "12px" },
            }}
          />
        </Tabs>
      </Stack>*/}
      <Paper
        sx={styles.paperContent}
        variant={theme.palette.mode === "dark" ? "outlined" : "elevation"}
        elevation={3}
      >
        <Box sx={styles.boxHeader}>
          <Typography variant="mySubtitle">Clientes</Typography>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            flexWrap={"wrap"}
            gap={2}
            width={"100%"}
          >
            <TextField
              label="Buscar Cliente"
              variant="filled"
              autoComplete="off"
              placeholder="Nome, Email, Asn, etc..."
              value={buscarUsuario}
              size="small"
              onChange={(event) => {
                setBuscarUsuario(event?.target?.value?.trimStart());
              }}
              sx={{ width: "280px" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
                endAdornment: buscarUsuario ? (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setBuscarUsuario("")} edge="end">
                      <Clear size={"small"} />
                    </IconButton>
                  </InputAdornment>
                ) : null,
              }}
            />
            {windowWith >= 640 && (
              <Button
                variant="contained"
                startIcon={<Add />}
                onClick={() => {
                  setOpenModal(true);
                }}
                size="large"
              >
                NOVO CLIENTE
              </Button>
            )}
          </Stack>
        </Box>
        <Box sx={styles.boxDataGrid}>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={handleClose} disabled>
              <PlayArrow sx={{ marginRight: "16px" }}></PlayArrow> Adicionar ao
              Juniper
            </MenuItem>
            <MenuItem
              onClick={() => {
                setOpenEditModal(true);
                setAnchorEl(null);
              }}
            >
              <Edit sx={{ marginRight: "16px" }}></Edit> Editar
            </MenuItem>
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                navigate(
                  `/interno/home/perfil/${selectedRow.id}`
                  // , {state: { selectedRow },}
                );
              }}
            >
              <Person sx={{ marginRight: "16px" }}></Person> Perfil
            </MenuItem>

            {selectedRow?.status === 0 ? (
              <>
                <MenuItem
                  onClick={() => {
                    setOPenDesativacao(true);
                    setStatusCLiente(1);
                    setAnchorEl(null);
                  }}
                >
                  <NotInterestedOutlined sx={{ marginRight: "16px" }} />
                  Desativar
                </MenuItem>
              </>
            ) : (
              <>
                <MenuItem
                  onClick={() => {
                    setAnchorEl(null);
                    updateStatusCliente(selectedRow?.id, 0);
                  }}
                >
                  <NotInterestedOutlined sx={{ marginRight: "16px" }} />
                  Ativar
                </MenuItem>
              </>
            )}

            <MenuItem
              onClick={() => {
                setOpenDeleteModal(true);
                setAnchorEl(null);
              }}
            >
              <Delete sx={{ marginRight: "16px" }}></Delete> Deletar
            </MenuItem>
          </Menu>

          <Menu
            open={openNotificacao}
            anchorEl={anchorNotificacao}
            placement={"bottom-end"}
            onClose={handleClose}
          >
            {Array.isArray(pendentes) &&
              pendentes?.map((pendente, index) => (
                <MenuItem
                  sx={styles.menuItensMobile}
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(pendente?.link);
                  }}
                  key={`${pendente?.id}-${index}`}
                >
                  <Box sx={styles.containerMenuMobile}>
                    {pendente?.icon}
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{ marginRight: "10px" }}
                    />
                    <Typography variant="caption" sx={{ width: "100%" }}>
                      {pendente?.message}
                    </Typography>
                  </Box>
                  <OpenInNewOutlined
                    fontSize="small"
                    sx={{ marginLeft: "10px" }}
                  />
                </MenuItem>
              ))}
          </Menu>
          {windowWith < 640 && (
            <Tooltip title={"Novo cliente"} placement="top-start">
              <Fab
                onClick={() => {
                  setOpenModal(true);
                }}
                size="small"
                fontSize="large"
                color="primary"
                sx={{
                  position: "fixed",
                  bottom: "30px",
                  right: "30px",
                }}
              >
                <Add />
              </Fab>
            </Tooltip>
          )}

          {windowWith < 640 ? (
            <Box>
              <List sx={styles.listContentMobile}>
                <Divider component="li" />
                {Array.isArray(filteredRows) &&
                  _DATA.currentData().map((row, index) => (
                    <Box key={index}>
                      <ListItem sx={styles.listItens}>
                        <Box sx={styles.containerListItens}>
                          <Box sx={styles.containerNameRazaoSocial}>
                            <Typography
                              sx={styles.nomeFantasia}
                              variant="mySubtitle"
                            >
                              {row?.nomeFantasia}
                            </Typography>
                          </Box>

                          <Box>
                            <Typography
                              sx={{ opacity: "0.8" }}
                              variant="caption"
                            >
                              ASN:{" "}
                            </Typography>
                            <Typography variant="caption">{row?.as}</Typography>
                          </Box>
                          <Box>
                            <Typography
                              sx={{ opacity: "0.8" }}
                              variant="caption"
                            >
                              E-mail:{" "}
                            </Typography>
                            <Typography variant="caption">
                              {row?.email
                                ? row?.email
                                : row?.ClientesCadastro?.email || ""}
                            </Typography>
                          </Box>
                          <Chip
                            label={row?.status === 0 ? "Ativo" : "Desativado"}
                            size="medium"
                            sx={{
                              ...styles.chip,
                              bgcolor:
                                row?.status === 0
                                  ? "chip.completed"
                                  : "chip.pending",
                              fontWeight: 600,
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            marginTop: "10px",
                          }}
                        >
                          <Tooltip title={"Solicitações Pendentes"}>
                            <IconButton
                              sx={{ marginRight: "14px" }}
                              onClick={(e) => {
                                notificacoesMobile(row) > 0 &&
                                  handleClickNotificacoesMobile(e, row);
                              }}
                            >
                              <Badge
                                badgeContent={notificacoesMobile(row)}
                                color="primary"
                                sx={styles.notificacoesMobileBadge}
                              >
                                <NotificationImportantOutlined />
                              </Badge>
                            </IconButton>
                          </Tooltip>
                        </Box>

                        <IconButton
                          sx={styles.iconButton}
                          onClick={(e) => {
                            setSelectedRow(row);
                            handleClick(e);
                          }}
                        >
                          <MoreVert />
                        </IconButton>
                      </ListItem>
                      <Divider component="li" />
                    </Box>
                  ))}
              </List>
              <Box sx={{ display: "flex", marginTop: "10px" }}>
                <Stack sx={{ margin: "auto" }} spacing={2}>
                  <Pagination
                    count={count}
                    page={page}
                    onChange={handleChangePage}
                  />
                </Stack>
              </Box>
            </Box>
          ) : (
            <DataGrid
              rows={filteredRows}
              columns={columns}
              autoHeight
              getRowHeight={() => "auto"}
              autosizeOptions={{ includeOutliers: true }}
              //apiRef={apiRef}
              pageSizeOptions={[25, 50, 100]}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 25,
                  },
                },
                columns: {
                  columnVisibilityModel: {
                    id: false,
                  },
                },
                sorting: {
                  // sortModel: [{ field: "contrato", sort: "desc" }],
                },
              }}
              sx={styles.dataGrid}
              density="compact"
              disableColumnMenu
              disableRowSelectionOnClick
            />
          )}
        </Box>
      </Paper>

      <CadastroCliente
        openModal={openModal}
        setOpenModal={setOpenModal}
        handleNewClient={handleNewClient}
        setSnackbar={setSnackbar}
      />
      <ModalEditCadastro
        openEditModal={openEditModal}
        setOpenEditModal={setOpenEditModal}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        handleUpdatedClient={handleUpdatedClient}
        setSnackbar={setSnackbar}
      />
      <InternoModalDeleteCliente
        openDeleteModal={openDeleteModal}
        load={deleteLoad}
        setOpenDeleteModal={setOpenDeleteModal}
        execute={() => {
          handleApiDeleteClientes(selectedRow?.id);
        }}
        severity={"error"}
        contentText={deleteBody}
        confirmText={confirmDelete}
      />
      <InternoModalDeleteCliente
        openDeleteModal={openDesativacao}
        load={deleteLoad}
        setOpenDeleteModal={setOPenDesativacao}
        execute={() => {
          updateStatusCliente(selectedRow?.id, statusCliente);
        }}
        severity={"warning"}
        contentText={desativarBody}
        confirmText={confirmDesativar}
      />
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2500}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </Container>
  );
}
