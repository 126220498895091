const styles = (theme) => ({
  boxContainer: {
    width: "83%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    left: 0,
    right: 0,
    mr: "auto",
    ml: "auto",
  },
  containerTitulo: {
    alignItems: "center",
    width: "100%",
    justifyContent: "center",
  },
  paperContainer: {
    backgroundColor: theme.palette.mode === "dark" && "transparent",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    width: "100%",
  },
  containerDatagridGrupos: {
    display: "flex",
    justifyItems: "start",
    flexWrap: "wrap",
    width: "100%",
    maxWidth: "230px",
  },
  buttonGrupo: {
    width: "100%",
    display: "flex",
    justifyContent: "start",
    maxWidth: "270px",
  },
  chipGrupo: {
    margin: "3px",
    color: "#ffffff",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  containerNoGrupo: {
    width: "100%",
    display: "flex",
    justifyContent: "start",
    flexWrap: "wrap",
  },
  textNoGrupo: {
    fontSize: "0.875rem",
    opacity: 0.6,
    fontWeight: 500,
    margin: "10px",
  },
  buttonAddGrupo: {
    width: "100%",
    justifyContent: "space-between",
    textAlign: "left",
  },
  colorSelectChip: {
    height: "15px",
    width: "15px",
    margin: "3px 10px",
  },
  containerHandleButtons: {
    margin: "10px",
    display: "flex",
    justifyContent: "end",
  },
  buttonHandle: {
    margin: "5px",
  },
  containerGrupo: {
    maxHeight: "300px",
    overflow: "auto",
  },
  grupoName: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  chip: {
    height: "auto",
    "& .MuiChip-label": {
      display: "block",
      whiteSpace: "normal",
    },
    color: "#ffffff",
  },
  datagrid: {
    display: "grid",
    fontSize: 15,
    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
      outline: "none !important",
    },
    ".MuiDataGrid-cell": { display: "flex", alignItems: "center" },
  },
});

export default styles;
