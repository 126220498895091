import { Svg, View, Line, Text } from "@react-pdf/renderer";
import { styles } from "./styles";
import dayjs from "dayjs";

export const Footer = () => {
	let dataAtual = new Date();
	dataAtual = dayjs(dataAtual).format("YYYY-MM-DD HH:mm:ss");

	return (
		<View fixed style={styles.footer}>
			<Text>{dataAtual}</Text>
			<Text
				render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
			/>
		</View>
	);
};
