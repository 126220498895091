import { StyleSheet } from "@react-pdf/renderer";
export const styles = StyleSheet.create({
	body: {
		paddingTop: 30,
		paddingBottom: 65,
		paddingHorizontal: 45,
	},
	title: {
		fontSize: 14,
		textAlign: "left",
		fontFamily: "Times-Bold",
		color: "#212B36",
		marginVertical: 10,
	},
	text: {
		fontSize: 12,
		textAlign: "left",
		fontFamily: "Times-Roman",
	},
	legend: {
		display: "flex",
		flexDirection: "row",
		fontSize: 10,
		alignItems: "center",
		justifyContent: "center",
		marginVertical: 20,
		textAlign: "center",
	},
	footer: {
		fontSize: 10,
		position: "absolute",
		bottom: 22,
		left: 40,
		right: 40,
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		fontFamily: "Times-Roman",
	},

	///////////////////////////////////////
	tableContainer: {
		fontSize: 10,
		borderTop: 3,
		borderColor: "#212B36",
		display: "table",
		// width: "auto",
		fontFamily: "Times-Bold",
		flexDirection: "row",
	},
	row: {
		fontFamily: "Times-Roman",
		fontSize: 8,
		display: "flex",
		flexDirection: "row",
	},
	tableCell: {
		width: "50%",
		borderWidth: 0.5,
		paddingVertical: 5,
		paddingHorizontal: 5,
		borderColor: "#d4d4dc",
		backgroundColor: "#fff",

		//backgroundColor: "#F7F7F8",
	},
	tableContainerOrigin: {
		fontSize: 10,
		borderTop: 3,
		borderColor: "#212B36",
		display: "table",
		// width: "auto",
		fontFamily: "Times-Bold",
		flexDirection: "row",
	},
	rowOrigin: {
		fontFamily: "Times-Roman",
		fontSize: 10,
		display: "flex",
		flexDirection: "row",
	},
	tableCellOrigin: {
		//width: "10%",
		borderWidth: 0.5,
		paddingVertical: 2,
		paddingHorizontal: 1,
		borderColor: "#d4d4dc",
	},
});
