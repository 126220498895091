import {
  ArticleOutlined,
  AttachMoneyOutlined,
  BarChart,
  LanOutlined,
  QueryStatsOutlined,
  SupervisorAccountOutlined,
} from "@mui/icons-material";
import { Box, Drawer, Tab, Tabs, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const styles = {
  tab: {
    minWidth: "60px",
    minHeight: "48px",
    maxWidth: "200px",
    justifyContent: "start",
    color: "drawer.text",
    fontSize: "0.875 rem",
    "&:hover": {
      backgroundColor: "#00000021",
    },
  },
};

export default function MobileDrawer(params) {
  const { open = false, setOpen = () => {} } = params;
  const [value, setValue] = useState(0);
  const [administrador, setAdministrador] = useState(false);
  const [financeiro, setFinanceiro] = useState(false);
  const [percentil, setPercentil] = useState(false);
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  useEffect(() => {
    let result = localStorage.getItem("userCliente");
    result = JSON.parse(result);

    const pages = [
      "/home",
      "/financeiro",
      "/relatorios",
      "/blocos",
      "/relatorio/ataque",
      "/administracao",
    ];

    const pageName = window.location.pathname;
    pages.map((page, index) => {
      if (pageName.includes(page)) {
        setValue(index);
      }
    });

    const handleUsuarioId = () => {
      let result = JSON.parse(localStorage.getItem("userCliente"));
      if (
        result?.usuarioCliente?.cliente?.percentil?.[0]?.permissao === true ||
        result?.usuarioCliente?.cliente?.percentil?.permissao === true
      ) {
        if (
          result?.usuarioCliente?.cliente?.percentil?.[0]?.permissao === true ||
          result?.usuarioCliente?.cliente?.percentil?.permissao === true
        ) {
          setPercentil(true);
        } else {
          setPercentil(false);
        }
      }
      // Verifica se a propriedade rlPermissoesUsuario existe e é um array
      if (
        Array.isArray(result?.usuarioCliente?.RlPermissoesUsuarioCliente) ||
        result?.usuarioCliente?.cliente?.percentil?.permissao === true
      ) {
        // Itera sobre o array para verificar se algum objeto contém a propriedade codigoPermissao com valor "ADM"
        for (let permissao of result?.usuarioCliente
          ?.RlPermissoesUsuarioCliente) {
          if (permissao?.permissao?.codigoPermissao.toUpperCase() === "ADM") {
            setAdministrador(true);
          }
          if (
            permissao?.permissao?.codigoPermissao.toUpperCase() === "FINANCEIRO"
          ) {
            setFinanceiro(true);
          }
        }
        return false;
      }
    };

    handleUsuarioId();
  }, []);
  const theme = useTheme();

  const navigate = useNavigate();

  const DrawerList = (
    <Box sx={{ width: 200 }} role="presentation" onClick={toggleDrawer(false)}>
      <Tabs
        orientation="vertical"
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        textColor={"secondary"}
        TabIndicatorProps={{
          style: {
            background: theme.palette.secondary.main,
          },
        }}
      >
        <Tab
          icon={<BarChart />}
          label={open ? "DASHBOARD" : ""}
          iconPosition="start"
          value={0}
          sx={styles.tab}
          onClick={(event) => {
            navigate("/home");
          }}
        />

        {(administrador || financeiro) && (
          <Tab
            icon={<AttachMoneyOutlined />}
            label={open ? "FINANCEIRO" : ""}
            iconPosition="start"
            value={1}
            sx={styles.tab}
            onClick={(event) => {
              navigate("/financeiro");
            }}
          />
        )}
        {(administrador || financeiro) && percentil && (
          <Tab
            icon={<QueryStatsOutlined />}
            label={
              <Typography sx={{ textTransform: "capitalize" }}>90Th</Typography>
            }
            iconPosition="start"
            value={2}
            sx={styles.tab}
            onClick={() => {
              navigate("/relatorios/percentil");
            }}
          />
        )}
        <Tab
          icon={<LanOutlined />}
          label={open ? "BLOCOS" : ""}
          iconPosition="start"
          value={3}
          sx={styles.tab}
          onClick={(event) => {
            navigate("/blocos");
          }}
        />

        <Tab
          icon={<ArticleOutlined />}
          label={
            <Typography sx={{ textTransform: "capitalize" }}>
              RELATORIO DE ATAQUE
            </Typography>
          }
          value={4}
          iconPosition="start"
          sx={styles?.tab}
          onClick={() => {
            navigate("/relatorio/ataque");
          }}
        />

        {administrador && (
          <Tab
            icon={<SupervisorAccountOutlined />}
            label={"ADMINISTRAÇÃO"}
            iconPosition="start"
            value={5}
            sx={styles.tab}
            onClick={() => {
              navigate("/administracao");
            }}
          />
        )}
      </Tabs>
    </Box>
  );

  return (
    <Box>
      <Drawer
        open={open}
        onClose={toggleDrawer(false)}
        PaperProps={{
          elevation: 0,
          sx: {
            backgroundColor: "background.paper",
          },
        }}
      >
        {DrawerList}
      </Drawer>
    </Box>
  );
}
