import { fontSize, height, style, useTheme, width } from "@mui/system";
import useStyles from "./styles";
import "dayjs/locale/pt-br";
import {
  Alert,
  Box,
  CircularProgress,
  Divider,
  FormControl,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Tooltip,
  Typography,
  Button,
  AlertTitle,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useState } from "react";
import LineChart from "../../../../../components/relatorioPercentil/line/index";
import { useEffect } from "react";
import {
  DataSaverOnOutlined,
  MoneyOffOutlined,
  WarningAmber,
} from "@mui/icons-material";
import api from "../../../../../services/api";

import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import "dayjs/locale/pt-br"; // Importa a localidade para português
import { useOutletContext, useNavigate } from "react-router-dom";
import TrafegoTotalPercentil from "../../../../../components/relatorioPercentil/trafegoTotal";

dayjs.extend(localizedFormat);
dayjs.locale("pt-br"); // Define a localidade como português

export default function TrafegoTotal({
  setValue = () => {},
  setSnackbar = () => {},
}) {
  const [sb, clienteInfo, si] = useOutletContext();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState("actual");
  const [errMessage, setErrMessage] = useState(null);
  const [decimal, setDecimal] = useState({
    fixo: 2,
    excedente: 2,
  });

  // Estilização
  const theme = useTheme();
  const styles = useStyles(theme);

  // Solicita novos dados ao alterar o período
  useEffect(() => {
    getPercentil();
  }, [selectedMonth, clienteInfo]);

  // Recebe dados
  const getPercentil = async () => {
    try {
      setLoading(true);
      const response = await api.post("/interno/analiseTraficPercentil", {
        clienteId: clienteInfo?.id,
        previousMonth: Boolean(selectedMonth !== "actual"),
      });

      setData({ ...response?.data, ...clienteInfo });

      if (response?.data?.tarifacaoExcedente && response?.data?.tarifacaoFixa) {
        let tarifacaoFixaDecimal = response?.data?.tarifacaoFixa
          ?.toFixed(3)
          ?.toString()
          ?.split(".")[1]
          ?.slice(2, 3);
        let tarifacaoExcedenteDecimal = response?.data?.tarifacaoExcedente
          ?.toFixed(3)
          ?.toString()
          ?.split(".")[1]
          ?.slice(2, 3);
        setDecimal((prev) => ({
          ...prev,
          fixo: tarifacaoFixaDecimal === "0" ? 2 : 3,
          excedente: tarifacaoExcedenteDecimal === "0" ? 2 : 3,
        }));
      }
      setErrMessage(false);
      setSnackbar({
        children: "Valores de tráfego recebidos com sucesso!",
        severity: "success",
      });
    } catch (error) {
      if (error?.response?.status === 404) {
        setErrMessage(`Não foi possível exibir o tráfego`);
      } else if (error?.response?.status === 500) {
        setErrMessage(`Falha na comunicação com o servidor.`);
      } else {
        setErrMessage(`Algo inesperado aconteceu.`);
      }
      setData({});
      setSnackbar({
        children: `Error:${
          error?.response?.data?.error ||
          "Não foi possível se buscar valores de tráfego"
        } `,
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <TrafegoTotalPercentil
      receivedData={data}
      decimal={decimal}
      setValue={setValue}
      loading={loading}
      errMessage={errMessage}
      selectedMonth={selectedMonth}
      setSelectedMonth={setSelectedMonth}
    />
  );
}
