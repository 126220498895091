import { useTheme } from "@emotion/react";
import {
  Box,
  Paper,
  TextField,
  Typography,
  Divider,
  Autocomplete,
  Checkbox,
  Modal,
  LinearProgress,
  Snackbar,
  Alert,
  createFilterOptions,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { RelatorioTemplateAtaque } from "../../../../../components/relatorioAtaque";
import { LoadingButton } from "@mui/lab";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import api from "../../../../../services/api";
import { useOutletContext } from "react-router-dom";
import "dayjs/locale/pt-br";
import useStyle from "./styles";
import { Stack, width } from "@mui/system";
import { saveAs } from "file-saver";
import { pdf } from "@react-pdf/renderer";
import { useEffect, useState, useMemo } from "react";
import { renderToStaticMarkup } from "react-dom/server";

import AtaqueLineChartBotClean from "../../../../../components/relatorioAtaque/charts/ataqueLineChartBotClean";
import AtaquePieChart from "../../../../../components/relatorioAtaque/charts/ataquePieChart";
import AtaqueProtocolStackedAreaChart from "../../../../../components/relatorioAtaque/charts/ataqueProtocolStackedAreaChart";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);
dayjs.locale("pt-br");

export default function RelatorioAtaque() {
  //configuração da data dayjs
  const today = new Date();
  today?.toLocaleString("en-US", {
    timeZoneName: "longOffset",
    timeZone: "America/Sao_paulo",
  });
  today?.setSeconds(0, 0);
  //limitando para selecionar somente o range de 1 semana
  const oneWeek = new Date();
  oneWeek?.toLocaleDateString("en-US", {
    timeZoneName: "longOffset",
    timeZone: "America/Sao_paulo",
  });
  oneWeek?.setDate(today.getDate() - 1 * 7);
  oneWeek?.setHours(0, 0, 0, 0);

  //limite para o range de 2 meses que pode ser selecionado
  const twoMonths = new Date();
  twoMonths?.toLocaleString("en-US", {
    timeZoneName: "longOffset",
    timeZone: "America/Sao_paulo",
  });

  twoMonths?.setMonth(today.getMonth() - 1);
  twoMonths?.setHours(0, 0, 0, 0);
  const defaultDateInicial = new Date();
  defaultDateInicial.setDate(today.getDate() - 1 * 7);
  defaultDateInicial?.setHours(0, 0, 0, 0);
  const defaultDateFinal = new Date();
  defaultDateFinal?.setHours(0, 0, 0, 0);
  //
  const [snackbar, setSnackbar] = useState(null);
  const [Null, setNull] = useState(false);
  const [sb, clienteInfo, si] = useOutletContext();
  //abrir o modal
  const [open, setOpen] = useState(false);
  //manipular o intervalo da coleta
  const [invalidDateInicial, setInvalidDateInicial] = useState(false);
  const [invalidDateFinal, setInvalidDateFinal] = useState(false);
  const [errorDateInicial, setErrorDateInicial] = useState(null);
  const [errorDateFinal, setErrorDateFinal] = useState(null);
  const [loading, setLoading] = useState(false);

  //salvar os blocos
  const [blocos, setBlocos] = useState([]);
  const [blocosSelecionados, setBlocosSelecionados] = useState([]);
  const [allBlocos, setAllBlocos] = useState(false);

  //icones para o autocomplete
  const icon = <CheckBoxOutlineBlankIcon />;
  const checkedIcon = <CheckBoxIcon />;
  const clienteId = clienteInfo?.id;
  /// Estilização
  const theme = useTheme();
  const styles = useStyle(theme);
  // Form manipulação
  const [formData, setFormData] = useState({
    titulo: "",
    dataInicial: defaultDateInicial,
    dataFinal: defaultDateFinal,
    clienteId: clienteId,
  });

  const diffDate = dayjs(formData.dataFinal).diff(
    dayjs(formData?.dataInicial),
    "minutes"
  );
  //semana em minutos 10080
  const semana = 60 * 24 * 31; // 43800; // 1 Mês

  useEffect(() => {
    if (clienteId) {
      setFormData({
        titulo: clienteInfo?.nomeFantasia || "",
        dataInicial: defaultDateInicial,
        dataFinal: defaultDateFinal,
        clienteId: clienteId,
      });

      handleApiGetBlocos();
    }
  }, [clienteInfo]);

  // Validação do datapicker
  const errorMensegeDateInicial = useMemo(() => {
    switch (errorDateInicial) {
      case "maxDate": {
        setInvalidDateInicial(true);
        return "Data inicial ultrapassou a data final";
      }
      case "maxTime": {
        setInvalidDateInicial(true);
        return "Data inicial ultrapassou a data final";
      }
      case "minDate": {
        setInvalidDateInicial(true);
        return "Data ultrapassou limite de 1 mês atrás";
      }
      case "minTime": {
        setInvalidDateInicial(true);
        return "Data ultrapassou limite de 1 mês atrás";
      }

      case "invalidDate": {
        setInvalidDateInicial(true);
        return "Necessário informar a data completa";
      }

      default: {
        setInvalidDateInicial(false);
        return "";
      }
    }
  }, [errorDateInicial]);

  const errorMensegeDateFinal = useMemo(() => {
    switch (errorDateFinal) {
      case "maxDate": {
        setInvalidDateFinal(true);
        return "Data final inválida!";
      }
      case "maxTime": {
        setInvalidDateFinal(true);
        return "Data final inválida!";
      }
      case "minDate": {
        setInvalidDateFinal(true);
        return "Data ultrapassou limite de 1 mês atrás";
      }
      case "minTime": {
        setInvalidDateFinal(true);
        return "Data ultrapassou limite de 1 mês atrás";
      }

      case "invalidDate": {
        setInvalidDateFinal(true);
        return "Necessário informar a data completa";
      }

      default: {
        setInvalidDateFinal(false);
        return "";
      }
    }
  }, [errorDateFinal]);

  // Funções relacionadas a gráficos
  async function handleApiGetBase64(htmlBase64) {
    try {
      const response = await api.post("/interno/relatorio/htmlToImageBase64", {
        htmlBase64,
      });

      if (response.data) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  }

  async function handleGenerateGraphPNG(dataIn, type) {
    let staticHtmlTraffic = renderToStaticMarkup(
      <AtaqueLineChartBotClean data={dataIn ? dataIn : []} type={type} />
    );

    let graphcs = await handleApiGetBase64({
      data: btoa(unescape(encodeURIComponent(staticHtmlTraffic))),
      width: 790,
      height: 200,
    });

    return graphcs;
  }

  async function handleGenerateProtocolGraphPNG(dataIn) {
    let staticHtmlTraffic = renderToStaticMarkup(
      <AtaqueProtocolStackedAreaChart data={dataIn ? dataIn : []} />
    );

    let graphcs = await handleApiGetBase64({
      data: btoa(unescape(encodeURIComponent(staticHtmlTraffic))),
      width: 790,
      height: 210,
    });

    return graphcs;
  }

  //requisição dos blocos
  async function handleApiGetBlocos() {
    try {
      const response = await api.post("/interno/findMany/blocos", {
        clienteId,
      });

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar os blocos",
          severity: "error",
        });
      } else {
        if (response.data) {
          setBlocos(response.data);
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    } finally {
      setBlocosSelecionados([]);
    }
  }

  async function handleGeneratePieCharthPNG(dataIn) {
    let staticHtmlTraffic = renderToStaticMarkup(
      <AtaquePieChart data={dataIn ? dataIn : []} />
    );

    let graphcs = await handleApiGetBase64({
      data: btoa(unescape(encodeURIComponent(staticHtmlTraffic))),
      width: 790,
      height: 490,
    });

    return graphcs;
  }

  // Gerar relatório de ataque
  const handleDados = async () => {
    setOpen(true);
    if (
      !formData?.titulo?.trim() ||
      !formData?.dataInicial ||
      !formData?.dataFinal ||
      invalidDateFinal ||
      invalidDateInicial ||
      diffDate > semana ||
      diffDate == 0
    ) {
      setNull(true);
      setOpen(false);
    } else {
      setLoading(true);

      try {
        //manipulação dos blocos
        let parseBlocos = [];
        let allBlocosSelect = false;
        if (blocosSelecionados.length == 0) {
          parseBlocos = blocos.map((bloco) => bloco?.blocos);
          allBlocosSelect = true;
        } else {
          parseBlocos = blocosSelecionados.map((bloco) => bloco?.blocos);
          allBlocosSelect = false;
        }
        const parseBlocosId = blocosSelecionados.map((bloco) => bloco?.id);

        // Requisições
        let graphClean = null;
        let graphBot = null;
        let graphDistAttack = null;
        let graphProtocol = null;
        let graphPercentProtocol = null;
        const response = await api.post("/interno/relatorio/ataque", {
          clienteId: clienteId,
          blocosId: parseBlocosId, /// Definir variável com array
          allBlocos: allBlocos || allBlocosSelect, // Definir variável com boolean
          dateInicial: dayjs(formData?.dataInicial)?.toDate(),
          dateFinal: dayjs(formData?.dataFinal)?.toDate(),
        });

        // Agrupamento de protocolos dos ataques
        let groupedProtocols = response?.data?.analiseProtocolo.reduce(
          (acc, curr) => {
            Object.keys(curr).forEach((key) => {
              if (key !== "time") {
                acc[key] = (acc[key] || 0) + curr[key];
              }
            });
            return acc;
          },
          {}
        );
        // Recebimento dos gráficos como base64
        [
          graphClean,
          graphBot,
          graphDistAttack,
          graphProtocol,
          graphPercentProtocol,
        ] = await Promise.all([
          // Traffego de ataque
          handleGenerateGraphPNG(response?.data?.traffic?.clean, "limpo"),
          handleGenerateGraphPNG(response?.data?.traffic?.bot, "sujo"),
          // Distribuição do tipo de ataque por contagem de alertas
          handleGeneratePieCharthPNG(
            Object.entries(response?.data?.contTypeAttack || {}).map(
              ([key, value]) => ({
                name: key,
                value: value,
              })
            )
          ),
          // Análise de protocolos
          handleGenerateProtocolGraphPNG(response?.data?.analiseProtocolo),
          handleGeneratePieCharthPNG(
            Object.entries(groupedProtocols || {}).map(([key, value]) => ({
              name: key,
              value: value,
            }))
          ),
        ]);
        // Objetos utilizados no relatório
        const relatorio = {
          titulo: formData?.titulo,
          client: formData?.clienteId,
          blocos: parseBlocos, // Modificar para value de blocos definidos no form
          dataInicial: dayjs(formData?.dataInicial).toDate(),
          dataFinal: dayjs(formData?.dataFinal).toDate(),
          analiseProtocolo: response?.data?.traffic,
        };

        const wanguardTable = response?.data?.wanguardTable;

        const contTypeAttack = response?.data?.contTypeAttack;

        // Criação do arquivo
        const MyDoc = (
          <RelatorioTemplateAtaque
            relatorio={relatorio}
            graphC={graphClean}
            graphB={graphBot}
            wanguardTable={wanguardTable}
            contTypeAttack={contTypeAttack}
            graphDistAttack={graphDistAttack}
            graphProtocol={graphProtocol}
            graphPercentProtocol={graphPercentProtocol}
          />
        );

        // Download do arquivo
        let blob = await pdf(MyDoc).toBlob();
        saveAs(blob, `relatório ${relatorio.titulo}.pdf`);
        setSnackbar({
          children: "Gerado relatório",
          severity: "success",
        });

        setNull(false);
      } catch (error) {
        if (error?.response?.status === 500) {
          setSnackbar({
            children: "Erro ao se conectar com o servidor",
            severity: "error",
          });
        } else {
          setSnackbar({
            children: "Erro ao tentar gerar relatório",
            severity: "error",
          });
        }
      } finally {
        setOpen(false);
        setLoading(false);
      }
    }
  };
  return (
    <>
      <Box sx={styles.container}>
        <Paper
          sx={styles.containerPaper}
          elevation={3}
          variant={theme.palette.mode === "dark" ? "outlined" : "elevation"}
        >
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography variant="mySubtitle">
              Configurações do relatório de ataque
            </Typography>
          </Stack>
          <Paper
            sx={{
              padding: "40px",
              margin: "20px 0",
              backgroundColor: "transparent",
            }}
            variant={"outlined"}
          >
            <Box>
              <Typography variant="subtitle2" sx={{ mb: "5px" }}>
                Título do relatório
              </Typography>
              <TextField
                size="small"
                label="Título"
                variant="filled"
                autoComplete="off"
                value={formData?.titulo}
                helperText={
                  !formData?.titulo?.trim() && Null
                    ? "Necessário informar um título"
                    : ""
                }
                disabled={loading}
                error={!formData?.titulo?.trim() && Null}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    titulo: e.target.value,
                  }))
                }
                sx={styles.containerTitle}
              />
            </Box>
            <Autocomplete
              multiple
              id="blocos-ip"
              options={blocos}
              disableCloseOnSelect
              size="small"
              getOptionLabel={(option) => option?.blocos}
              filterOptions={(options, params) => {
                const filter = createFilterOptions();
                const filtered = filter(options, params);
                return [
                  { blocos: "Selecionar todos os blocos", all: true },
                  ...filtered,
                ];
              }}
              isOptionEqualToValue={(option, value) =>
                option.blocos === value.blocos
              }
              value={blocosSelecionados}
              onChange={(event, newValue) => {
                if (newValue.find((option) => option.all)) {
                  setAllBlocos(true);
                  return setBlocosSelecionados(
                    blocosSelecionados.length === blocos.length ? [] : blocos
                  );
                }
                setAllBlocos(false);
                setBlocosSelecionados(newValue);
              }}
              renderOption={(props, option, { selected }) => {
                const { key, ...optionProps } = props;
                return (
                  <li key={key} {...optionProps}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={
                        option.all
                          ? !!(blocosSelecionados.length === blocos.length)
                          : selected
                      }
                    />
                    {option.blocos}
                  </li>
                );
              }}
              renderTags={(selected) =>
                selected.length === 1
                  ? selected.map((option) => option.blocos)
                  : [`${selected.length} blocos selecionados`]
              }
              sx={{
                width: "70%",
                "@media (max-width: 1200px)": { width: "100%" },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  helperText={
                    "Caso não seja selecionado nenhum bloco, será considerado todos os blocos"
                  }
                  label="Blocos"
                  sx={styles.containerBlocos}
                />
              )}
            />
            <Divider sx={styles.divider} />
            <Box>
              <Typography
                sx={{ ...styles.subtitle, mt: "10px" }}
                variant="subtitle2"
              >
                Intervalo de coleta
              </Typography>
              <Box sx={styles.containerDate}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="pt-br"
                >
                  <DateTimePicker
                    inputFormt={"DD/MM/YYYY"}
                    timezone="America/Sao_paulo"
                    onError={(newErro) => {
                      setErrorDateInicial(newErro);
                    }}
                    sx={styles.datePicker}
                    slotProps={{
                      textField: {
                        error:
                          diffDate > semana || diffDate == 0 || errorDateInicial
                            ? true
                            : false,
                        helperText:
                          errorMensegeDateInicial ||
                          (diffDate > semana
                            ? "O intervalo selecionado excede 1 mês"
                            : "") ||
                          (diffDate == 0
                            ? "As datas não podem ser iguais"
                            : ""),
                      },
                    }}
                    label="Data Inicial"
                    defaultValue={dayjs(defaultDateInicial)}
                    maxDateTime={dayjs(formData?.dataFinal)}
                    minDateTime={dayjs(twoMonths)}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        dataInicial: new Date(e),
                      }))
                    }
                    disabled={loading}
                  />
                  <DateTimePicker
                    timezone="America/Sao_paulo"
                    label="Data Final"
                    sx={styles.datePicker}
                    onError={(newErro) => {
                      setErrorDateFinal(newErro);
                    }}
                    slotProps={{
                      textField: {
                        helperText: errorMensegeDateFinal,
                      },
                    }}
                    defaultValue={dayjs(defaultDateFinal)}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        dataFinal: new Date(e),
                      }))
                    }
                    maxDateTime={dayjs(today)}
                    minDateTime={dayjs(twoMonths)}
                    inputFormt={"DD/MM/YYYY"}
                    disabled={loading}
                  />
                </LocalizationProvider>
              </Box>
            </Box>
            <Divider sx={styles.divider} />
            <Stack sx={styles.containerLoadindButton}>
              <LoadingButton
                loading={loading}
                onClick={handleDados}
                variant="contained"
                size="medium"
              >
                Gerar relatório
              </LoadingButton>
              <Modal open={open}>
                <Box style={styles.containerLoadingMensage}>
                  <Box
                    sx={{
                      backgroundColor:
                        theme.palette.mode === "dark"
                          ? "#ffffff3b"
                          : "primary.main",
                      display: "flex",
                      padding: "8px 8px 0px 8px,8px",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      variant="mySubtitle"
                      sx={styles.constainerTitleModel}
                    >
                      Gerando Relatório
                    </Typography>
                  </Box>

                  <Divider />
                  <Box sx={{ padding: "16px" }}>
                    <Typography variant="body2" textAlign={"center"}>
                      Aguarde enquanto seu relatório está sendo gerado.
                    </Typography>
                    <LinearProgress sx={styles.containerLinearProgress} />
                  </Box>
                </Box>
              </Modal>
            </Stack>
          </Paper>
        </Paper>
        {!!snackbar && (
          <Snackbar
            open
            onClose={() => setSnackbar(null)}
            autoHideDuration={3000}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert {...snackbar} onClose={() => setSnackbar(null)} />
          </Snackbar>
        )}
      </Box>
    </>
  );
}
