import { display, maxHeight, minWidth, width } from "@mui/system";

const styles = (theme) => ({
  pageBox: { display: "flex", flexDirection: "column", gap: 2 },
  headerRow: { display: "flex", flexDirection: "row", gap: 4 },
  filterGroupItem: {
    width: "310px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 1,
  },
  filterTypography: {
    textWrap: "nowrap",
    //fontWeight: 500,
  },
  formControl: {
    display: "flex",
    width: "120px",
    minWidth: "120px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "primary",
      color: "primary",
    },
    "& .MuiInputLabel-root": {
      color: "primary",
    },
    svg: {
      color: "primary",
    },
    input: {
      color: "primary",
    },
  },
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    gap: "40px",
    position: "relative",
  },
  content: { gap: "20px", display: "flex", flexDirection: "column" },
  paper: {
    display: "flex",
    flexDirection: "column",
    padding: "24px 24px 26px",
    bgcolor: theme.palette.mode === "dark" ? "transparent" : "none",
    gap: "16px",
    borderRadius: "10px",
    width: "310px",
    minWidth: "310px",
    position: "sticky",
    top: 130,

    // maxHeight: "72vh",
    // maxHeight: "81.5vh",
  },
  avatar: { width: "64px", height: "64px" },

  Icon: {
    fontSize: "26px",
  },

  configBadge: {
    fontSize: "18px",
    position: "absolute",
    bottom: 4,
    right: -8,
  },
  selectedList: {
    color: theme.palette.secondary.main,
    "&.Mui-selected": {
      backgroundColor: "transparent",
    },
  },
  listIconWanguard: { fontSize: "32px", ml: -0.5 },
  infoContratos: {
    marginLeft: "4px",
    width: "15px",
  },
});

export default styles;
