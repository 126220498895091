import { useTheme } from "@emotion/react";
import "dayjs/locale/pt-br";
import { Box, Tab, Tabs, Snackbar, Alert } from "@mui/material";
import useStyle from "./styles";
import { useState } from "react";

import Configuracoes from "./configuracoes";
import TrafegoTotal from "./trafegoTotal/index";
import RelatorioAtaque from "./relatorioAtaque";
import AgendamentoRelatorioAtaque from "./agendamentoRelatorioAtaque";

export default function Relatorios(params) {
  const [snackbar, setSnackbar] = useState(null);
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const styles = useStyle(theme);

  return (
    <Box sx={styles.container}>
      <Tabs
        scrollButtons={true}
        value={value}
        sx={styles.tabs}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <Tab label={"Relatórios"} value={0} disabled={loading} />
        <Tab label={"Relatório de Ataque"} value={1} disabled={loading} />
        <Tab label={"Tráfego"} value={2} disabled={loading} />
        {/* <Tab label={"Agendamentos"} value={4} disabled={loading} /> */}
      </Tabs>

      {/* {value === 0 && <Relatorio loading={loading} setLoading={setLoading} />} */}
      {value === 0 && (
        <Configuracoes loading={loading} setLoading={setLoading} />
      )}
      {value === 1 && (
        <RelatorioAtaque setValue={setValue} setSnackbar={setSnackbar} />
      )}
      {value === 2 && (
        <TrafegoTotal setValue={setValue} setSnackbar={setSnackbar} />
      )}
      {value === 4 && (
        <AgendamentoRelatorioAtaque
          setLoading={setLoading}
          setSnackbar={setSnackbar}
        />
      )}

      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </Box>
  );
}
