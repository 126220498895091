import { useTheme } from "@mui/system";
import useStyles from "./styles";
import "dayjs/locale/pt-br";
import { Alert, Box, Snackbar } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import apiCliente from "../../../../services/apiCliente";

import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import "dayjs/locale/pt-br"; // Importa a localidade para português
import TrafegoTotalPercentil from "../../../../components/relatorioPercentil/trafegoTotal";

dayjs.extend(localizedFormat);
dayjs.locale("pt-br"); // Define a localidade como português

export default function PercentilCliente({ setValue = () => {} }) {
  const [snackbar, setSnackbar] = useState(null);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState("actual");
  const [decimal, setDecimal] = useState({
    fixo: 2,
    excedente: 2,
  });

  // Informações do cliente
  const cliente =
    JSON.parse(localStorage.getItem("userCliente"))?.usuarioCliente || "";

  // Estilização
  const theme = useTheme();
  const styles = useStyles(theme);

  // Ações ao carregar a página
  useEffect(() => {
    setSelectedMonth("actual");
    getPercentil();
  }, []);

  // Solicita novos dados ao alterar o período
  useEffect(() => {
    getPercentil();
  }, [selectedMonth]);

  // Recebe dados
  const getPercentil = async () => {
    try {
      setLoading(true);
      const response = await apiCliente.post(
        "/cliente/analiseTraficPercentil",
        {
          clienteId: cliente?.clienteId,
          previousMonth: Boolean(selectedMonth !== "actual"),
        }
      );

      setData(response?.data);
      if (response?.data?.tarifacaoExcedente && response?.data?.tarifacaoFixa) {
        let tarifacaoFixaDecimal = response?.data?.tarifacaoFixa
          ?.toFixed(3)
          ?.toString()
          ?.split(".")[1]
          ?.slice(2, 3);
        let tarifacaoExcedenteDecimal = response?.data?.tarifacaoExcedente
          ?.toFixed(3)
          ?.toString()
          ?.split(".")[1]
          ?.slice(2, 3);
        setDecimal((prev) => ({
          ...prev,
          fixo: tarifacaoFixaDecimal === "0" ? 2 : 3,
          excedente: tarifacaoExcedenteDecimal === "0" ? 2 : 3,
        }));
      }
      setSnackbar({
        children: "Valores de tráfego recebidos com sucesso!",
        severity: "success",
      });
    } catch (error) {
      setSnackbar({
        children: `Error:${
          error?.response?.data?.error ||
          "Não foi possível se buscar valores de tráfego"
        } `,
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={styles.container}>
      <TrafegoTotalPercentil
        receivedData={data}
        decimal={decimal}
        setValue={setValue}
        loading={loading}
        //errMessage={errMessage}
        selectedMonth={selectedMonth}
        setSelectedMonth={setSelectedMonth}
      />
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </Box>
  );
}
