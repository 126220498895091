const styles = (theme) => ({
  boxContainer: {
    width: "100%",
    display: "flex",
  },
  paperContainer: {
    backgroundColor: theme.palette.mode === "dark" && "transparent",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    gap: 2,
    borderRadius: 3,
    width: "100%",
    minHeight: "50vh",
  },
  searchBar: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
  },
  searchFields: {
    display: "flex",
    flexDirection: "row",
    gap: 1,
    alignItems: "center",
  },
  searchStatus: {
    display: "flex",
    "& .MuiInputLabel-root": {
      width: "100%",
      mt: "-5px",
    },
  },
  searchStatusSelect: { width: "150px", height: "40px" },
  dataGrid: {
    "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
      outline: "none !important",
    },
  },
  status: {
    //Ativado
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "chip.completed",
      "&:hover": {
        backgroundColor:
          theme.palette.mode === "dark" ? "#00A76F50" : "#2E7D3250",
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "chip.completed",
    },
    //desativado
    "& .MuiSwitch-switchBase:not(.Mui-checked)": {
      color: "chip.pending",
      "&:hover": {
        backgroundColor:
          theme.palette.mode === "dark" ? "#A6132850" : "#E8375050",
      },
    },
    "& .MuiSwitch-switchBase:not(.Mui-checked) + .MuiSwitch-track": {
      backgroundColor: "chip.pending",
    },
  },
});
export default styles;
