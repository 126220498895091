import { View, Text, StyleSheet, Rect, Svg, Image } from "@react-pdf/renderer";
import { TableReport } from "../../generateReport/tableReport";

import { styles } from "../styles";

export const ProtocolAnalysis = ({
  relatorio,
  graphProtocol,
  graphPercentProtocol,
}) => {
  let ipTeste = "45.170.200.0/22";
  const dataInicial = relatorio?.relatorio?.dataInicial;
  const dataFinal = relatorio?.relatorio?.dataFinal;

  const stylesFlowReport = StyleSheet.create({
    columnView: {
      display: "flex",
      flexDirection: "column",
      width: "50%",
    },
    columnView2: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    rowView: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "center",
    },
    image: {
      height: "auto",
      width: "100%",
    },
  });

  return (
    <View>
      <View>
        <Text style={styles.title}>{"ANÁLISE DE PROTOCOLOS"}</Text>
        {/* <Text style={styles.text}>{`Padrão ${ipTeste} (pps)`}</Text>
        <Text style={styles.text}>{`${dataInicial} ~ ${dataFinal}`}</Text> */}
      </View>
      <View>
        {graphProtocol && (
          <View
            key={"boolean-top2"}
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 10,
            }}
            wrap={false}
          >
            <View style={{ ...styles.legend, marginBottom: 5 }}>
              <Text style={{ fontFamily: "Times-Bold" }}>{`Gráfico 3: `}</Text>
              <Text
                style={{ fontFamily: "Times-Roman" }}
              >{`Protocolos utilizados em ataques de negação de serviço ao longo do tempo`}</Text>
            </View>
            <Image
              key={"iamge2"}
              style={{ height: "auto", width: "500px" }}
              src={`data:application/pdf;base64,${graphProtocol}`}
            />

            <View
              style={{
                ...stylesFlowReport.rowView,
                marginTop: -5,
                marginBottom: 0,
                fontFamily: "Times-Roman",
              }}
            >
              <Text
                style={styles.legend}
              >{`Fonte: Dados coletados pelo Wanguard`}</Text>
            </View>
          </View>
        )}
      </View>
      {graphPercentProtocol && (
        <View
          style={{
            display: "flex",
            flexDirection: "column",
          }}
          wrap={false}
        >
          <View style={{ ...styles.legend, marginBottom: 0 }}>
            <Text style={{ fontFamily: "Times-Bold" }}>{"Gráfico 4: "}</Text>
            <Text style={{ fontFamily: "Times-Roman" }}>
              {"Percentual dos protocolos utilizados nos ataques"}
            </Text>
          </View>
          <View
            style={{
              display: "flex",
              width: "100%",
              alignContent: "center",
            }}
          >
            <Image
              key={"image3"}
              style={{ height: "auto", width: "500px", marginTop: 0 }}
              src={`data:application/pdf;base64,${graphPercentProtocol}`}
            />
          </View>
          <Text
            style={{
              ...styles.legend,
              fontFamily: "Times-Roman",
              marginTop: 10,
              marginBottom: 0,
            }}
          >
            {"Fonte: Dados coletados pelo Wanguard"}
          </Text>
        </View>
      )}
    </View>
  );
};
