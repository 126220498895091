import { View, Text, StyleSheet, Rect, Svg } from "@react-pdf/renderer";
import { TableDefault } from "../tables/tableDefault";
import { TableOrigin } from "../tables/tableOriginAttack";
import { Fragment } from "react";
import { styles } from "../styles";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat.js";
import utc from "dayjs/plugin/utc.js";
import timezone from "dayjs/plugin/timezone.js";
dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

export const AnomaliesAnalysis = ({ relatorio, wanguardTable }) => {
  // let ipTeste = "45.170.200.0/22";
  const dataInicial = dayjs(relatorio?.dataInicial).format(
    "YYYY-MM-DD HH:mm:ss"
  );
  const dataFinal = dayjs(relatorio?.dataFinal).format("YYYY-MM-DD HH:mm:ss");

  const formatBytes = (bytes) => {
    if (bytes === 0) return "0 bps";
    const k = 1000;
    const sizes = ["bps", "Kbps", "Mbps", "Gbps", "Tbps", "Pbps", "Ebps"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };
  const formatPackge = (bytes) => {
    if (bytes === 0) return "0 pps";
    const k = 1000;
    const sizes = ["pps", "Kpps", "Mpps", "Gbps", "Tpps", "Ppps", "Epps"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };
  const formatTime = (time) => {
    let days = Math.floor(time / (24 * 3600));
    time -= days * 24 * 3600;
    let hours = Math.floor(time / 3600);
    time -= hours * 3600;
    let minutes = Math.floor(time / 60);
    time -= minutes * 60;
    return `${days > 9 ? days : "0" + days}:${
      hours > 9 ? hours : "0" + hours
    }:${minutes > 9 ? minutes : "0" + minutes}:${time > 9 ? time : "0" + time}`;
  };

  const headerTable = [
    "Prefixo",
    "Grupo de IP",
    "Anomalia",
    "Velocidade",
    "Início",
    "Duração",
    "IP Pkts/s",
    "IP Bits/s",
  ];

  const TableHeader = () => {
    const title = headerTable.map((value, index) => (
      <View
        key={"table-cell" + index}
        style={{ ...styles.tableCell, backgroundColor: "#f5f5f5" }}
      >
        <Text>{value}</Text>
      </View>
    ));
    return <Fragment>{title}</Fragment>;
  };
  const TableRow = () => {
    const rows = wanguardTable.map((res, index) => (
      <View style={styles.row} key={index.toString()}>
        <Text style={styles.tableCell}>{res?.prefix}</Text>
        <Text style={styles.tableCell}>{res?.grupoIp}</Text>
        <Text style={styles.tableCell}>{res?.anomaly}</Text>
        <Text style={styles.tableCell}>{formatPackge(res?.speed)}</Text>
        <Text style={styles.tableCell}>
          {dayjs.unix(res?.from?.unixtime)?.format("YYYY-MM-DD HH:mm:ss")}
        </Text>
        <Text style={styles.tableCell}>{formatTime(res?.duration)}</Text>
        <Text style={styles.tableCell}>{formatPackge(res?.pkts)}</Text>
        <Text style={styles.tableCell}>{formatBytes(res?.bits)}</Text>
      </View>
    ));
    return <Fragment>{rows}</Fragment>;
  };
  return (
    <View>
      <View>
        <Text style={styles.title}>{"ANÁLISE DE ANOMALIAS"}</Text>
        {/* <Text style={styles.text}>{`Padrão ${ipTeste} (pps)`}</Text> */}
        <Text style={styles.text}>{`${dataInicial} ~ ${dataFinal}`}</Text>

        <View style={{ flexDirection: "column", marginTop: "15px" }}>
          <View style={styles.tableContainer}>
            <TableHeader />
          </View>
          <View style={{ flexDirection: "column" }}>
            <TableRow />
          </View>
        </View>
      </View>
    </View>
  );
};
