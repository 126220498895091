import { minHeight } from "@mui/system";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    marginTop: "60px",
    width: "100%",
  },
  boxHeader: {
    display: "flex",
    flexDirection: "column",
    padding: "24px 24px 0px 24px",
    gap: "16px",
    width: "100%",
  },
  boxDataGrid: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "0px 24px 24px 24px",
    gap: "16px",
  },
  paperContent: {
    borderRadius: "16px",
    backgroundColor: theme.palette.mode === "dark" && "transparent",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    width: "100%",
  },
  listContentMobile: {
    width: "100%",
    padding: 0,
  },
  boxList: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "5px",
  },
  chip: {
    padding: "5px",
    fontSize: "10px",
    textAlign: "center",
    width: "100px",
    position: "absolute",
    marginLeft: "50%",
    height: "auto",
    color: "#ffffff",
    "& .MuiChip-label": {
      display: "block",
      whiteSpace: "normal",
    },
  },

  notificacoesBox: {
    display: "flex",
    flexDirection: "column",
    maxHeight: "287.6px",
    overflow: "auto",
    padding: 1,
    gap: 1,
  },
  notificacoesMobileBadge: { zIndex: "0" },
  listItens: {
    display: "flex",
    flexDirection: "row",
    padding: 0,
  },
  containerListItens: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "16px 0 0 16px",
    minHeight: "135px",
  },
  containerMenuMobile: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  menuItensMobile: {
    minWidth: "250px",
    display: "flex",
    alignItems: "center",
  },
  iconButton: {
    bgcolor: "background.paper",
    borderRadius: 0,
    height: "135px",
    ":focus": {
      bgcolor: "background.paper",
    },
  },
  containerNameRazaoSocial: {
    width: "100%",
    maxWidth: "165px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  nomeFantasia: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    display: "block",
  },
  dataGrid: {
    "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
      outline: "none !important",
    },
    "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
      py: "8px",
    },
    "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
      py: "15px",
    },
    "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
      py: "22px",
    },
  },
});

export default styles;
